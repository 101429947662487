import { useAppDispatch, useAppSelector } from 'hooks/redux'
import instance from 'instances/axios'
import {
  Text,
  Heading,
  Wrap,
  HStack,
  Button,
  Icon,
  Card,
  Tag
} from '@chakra-ui/react'
import toast from 'react-hot-toast'
import Content from 'components/misc/Content'
import { type IProxy } from 'types/roblox'
import {
  selectProxies,
  deleteProxies,
  deleteProxy as deleteProxyFromStore,
  toggleProxyActiveStatus
} from 'store/proxies'
import AddProxies from './AddProxies'
import Table, {
  getArrayRearrangedByKeys,
  TableColumnTypes
} from 'components/misc/Table'
import Confirmation from 'components/misc/Confirmation'
import { useState } from 'react'
import ProxiesEditingMode from './EditingMode'
import { selectUser } from 'store/user'
import { UserRoles } from 'types/users'
import getProxyAsText from 'helpers/getProxyAsText'
import { deleteProxyFromAccounts, selectAccounts } from 'store/accounts'
import { CopyIcon, TrashIcon } from 'assets/icons'
import { NotFoundCard } from 'components/misc/NotFoundCard'

export default function MyProxiesPage () {
  const { status, error, data } = useAppSelector(selectProxies)
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const accounts = useAppSelector(selectAccounts)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedProxiesIds, setSelectedProxiesIds] = useState<
    Array<IProxy['_id']>
  >([])
  const toggleProxySelection = (_id: IProxy['_id']) => {
    const clonedData = [...selectedProxiesIds]
    const index = clonedData.findIndex((id) => id === _id)
    if (index === -1) {
      clonedData.push(_id)
    } else {
      clonedData.splice(index, 1)
    }
    setSelectedProxiesIds(clonedData)
  }
  const onSelectionsSubmit = async () => {
    setIsEditing(false)
    try {
      await instance.delete('/accounts/proxies', {
        params: { ids: JSON.stringify(selectedProxiesIds) }
      })
      toast.success('The selected proxies have been deleted.')
      setSelectedProxiesIds([])
      dispatch(deleteProxies(selectedProxiesIds))
    } catch {
      setIsEditing(true)
    }
  }
  const copyAllProxies = () => {
    if (!data) return
    const text = data.map(getProxyAsText).join('\n')
    navigator.clipboard.writeText(text)
    toast.success('All proxies have been copied to clipboard.')
  }
  const toggleIsEnabled = async (_id: IProxy['_id']) => {
    try {
      await instance.put('/accounts/toggle-proxy', { id: _id })
      const proxy = data?.find((proxy) => proxy._id === _id)
      dispatch(toggleProxyActiveStatus(_id))
      dispatch(deleteProxyFromAccounts(_id))
      const isEnabled = !proxy?.isEnabled
      toast.success(`The proxy has been ${isEnabled ? 'enabled' : 'disabled'}`)
    } catch {}
  }
const deleteProxy = async (_id: IProxy['_id']) => {
  try {
    await instance.delete('/accounts/proxy', { params: { id: _id } })

    toast.success('The selected proxy has been deleted.')
    dispatch(deleteProxyFromStore(_id))
  } catch {}
}
const isAdmin = user.data?.role === UserRoles.Admin
  return (
    <>
      <Heading fontSize="3xl" mb={1}>
        {user.data?.role === UserRoles.Admin ? 'Proxies' : 'My Proxies'}
      </Heading>
      <HStack mt="15px">
        <AddProxies />
        <Button
          variant="ghost"
          isDisabled={!data}
          onClick={copyAllProxies}
          leftIcon={<Icon boxSize="17px" as={CopyIcon} />}
        >
          Copy all
        </Button>
      </HStack>
      <Content status={status} error={error}>
        {() => (
          <>
            {(data?.length as number) > 0
? (
              <>

                <Card mt="30px">
                  {isEditing
? (<><Wrap>
  <Tag data-clickable onClick={() => { setIsEditing(false) }}>Cancel</Tag>
  <Confirmation
  variant="negative"
>
  <Tag variant="negative" onClick={onSelectionsSubmit} data-clickable data-disabled={selectedProxiesIds.length === 0}>
      Delete
    </Tag>
</Confirmation>
</Wrap>
                    <Table mt="20px" onRowSelection={(desc) => { if (!desc) { setSelectedProxiesIds((data as NonNullable<typeof data>).map(proxy => proxy._id)); return };toggleProxySelection(desc) }} onRowDeselection={(desc) => { if (!desc) { setSelectedProxiesIds([]); return };toggleProxySelection(desc) }} columns={[{ heading: 'IP', type: TableColumnTypes.ColoredText }, { heading: 'Action', type: TableColumnTypes.Custom }]} data={getArrayRearrangedByKeys((data as NonNullable<typeof data>).map(proxy => ({ ...proxy, hostAndPort: `${proxy.host}:${proxy.port}` })), [{ key: 'hostAndPort', addColumnProperties: { textColor: 'white' } }, { key: '_id', addColumnProperties: (item) => ({ element: <Tag data-clickable onClick={async () => { await deleteProxy(item._id) }} size="sm" variant="negative"><Icon boxSize="14px" as={TrashIcon}/></Tag> }) }], (item) => ({ descriptor: item._id }))} isWithCheckboxes/>

                    </>
                  )
: (
                    <>
                      <Tag
                        data-clickable
                        onClick={() => {
                          setIsEditing(true)
                        }}
                      >
                        Edit
                      </Tag>
                      <Table
                        mt="19px"
                        columns={[
                          'Host',
                          {
                            heading: 'Port',
                            type: TableColumnTypes.ColoredText
                          },
                          'Username',
                          {
                            heading: 'Password',
                            type: TableColumnTypes.ColoredText
                          },
                          ...(isAdmin ? [{ heading: 'Merchant Username' }] : []),
                          { heading: 'Actions', type: TableColumnTypes.Custom }
                        ]}
                        data={getArrayRearrangedByKeys(
                          data as NonNullable<typeof data>,
                          [
                            'host',
                            {
                              key: 'port',
                              addColumnProperties: { textColor: '#CEDBEF' }
                            },
                            'username',
                            {
                              key: 'password',
                              addColumnProperties: { textColor: '#CEDBEF' }
                            },
                            ...(isAdmin ? ['owner' as keyof IProxy] : []),
                            {
                              key: 'isEnabled',
                              addColumnProperties (item) {
                                return {
                                  element: item.isEnabled
? (
                                    <Tag data-clickable onClick={async () => { await toggleIsEnabled(item._id) }} variant="negative">
                                      Disable
                                    </Tag>
                                  )
: (
                                    <Tag data-clickable onClick={async () => { await toggleIsEnabled(item._id) }} variant="positive">
                                      Enable
                                    </Tag>
                                  )
                                }
                              }
                            }
                          ]
                        )}
                      />
                    </>
                  )}
                </Card>
              </>
            )
: (
  <NotFoundCard sub="You don't have any accounts" mt="20px"/>
            )}
          </>
        )}
      </Content>
    </>
  )
}
