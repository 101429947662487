import { Card, type CardProps, Heading, Icon, Text, VStack } from '@chakra-ui/react'
import { WarningIcon } from 'assets/icons'
interface Props {
  heading?: string
  sub: string
}
export function NotFoundCard ({ heading, sub, ...props }: Props & CardProps) {
  return (
    <Card as={VStack} gap="32px" {...props}>
      <Icon as={WarningIcon} boxSize="40px" />
      <VStack gap="20px">
        <Heading fontSize="20px">{heading || 'No data found'}</Heading>
        <Text color="#66707F">{sub}</Text>
      </VStack>
    </Card>
  )
}
