import { Box, Button, Flex, Heading, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { TrashIcon } from 'assets/icons'
import AwaitingButton from 'components/misc/AwaitingButton'
import Content from 'components/misc/Content'
import FormInput from 'components/form/FormInput'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import instance from 'instances/axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { setPaymentMethods, selectCommonData } from 'store/common'
interface WithdrawalProps {
  name: string
  deleteMethod: (value: string) => void
}
export function WithdrawalMethod ({ name, deleteMethod }: WithdrawalProps) {
  return (
    <WrapItem
      display="flex"
      justifyContent="center"
      borderRadius="default"
      px={3}
      py={2}
      gap={1}
      alignItems="center"
      border="1px solid var(--chakra-colors-gray-700)"
    >
      <Text color="#fff" fontWeight="medium" fontSize="14px">{name}</Text>
      <Box
        cursor="pointer"
        boxSize="16px"
        onClick={() => {
          deleteMethod(name)
        }}
      >
        <TrashIcon color="#fff" />
      </Box>
    </WrapItem>
  )
}
export default function WithdrawalMethods () {
  const { data, status, error } = useAppSelector(selectCommonData)
  const [localMethods, setLocalMethods] = useState<string[]>([])
  useEffect(() => {
    if (data?.withdrawalMethods) {
      setLocalMethods(data.withdrawalMethods)
    }
  }, [data])
  const [newMethodValue, setNewMethodValue] = useState<string>('')
  const dispatch = useAppDispatch()
  const addNewMethod = () => {
    if (!localMethods) return
    const clonedData = [...localMethods]
    clonedData.push(newMethodValue)
    setLocalMethods(clonedData)
    setNewMethodValue('')
  }
  const deleteMethod = (value: string) => {
    if (!localMethods) return
    const index = localMethods.findIndex((name) => name === value)
    const clonedData = [...localMethods]
    clonedData.splice(index, 1)
    setLocalMethods(clonedData)
  }
  const saveMethods = async () => {
    try {
      await instance.put('/admin/withdrawal-methods', {
        methods: localMethods
      })
      toast.success('Updated.')
      dispatch(setPaymentMethods(localMethods))
    } catch {}
  }
  return (
    <>
      <Heading mt={8} mb="10px" fontSize="xl">
        Withdrawal methods
      </Heading>
      <FormInput mb="5px"
        value={newMethodValue}
        label="New method"
        placeholder="BTC"
        onCustomChange={(e) => {
          setNewMethodValue(e.target.value)
        }}
      />
      <Button
        mt={2}
        isDisabled={!newMethodValue || localMethods?.includes(newMethodValue)}
        onClick={addNewMethod}
      >
        Add
      </Button>
      <Content status={status} error={error}>
        {() => (
          <>
            <Wrap mt="20px">
              {localMethods
                ? localMethods.map((name) => (
                    <WithdrawalMethod
                      key={name}
                      deleteMethod={deleteMethod}
                      name={name}
                    />
                ))
                : null}
            </Wrap>
            <AwaitingButton mt="15px" isDisabled={[...localMethods].sort().join(',') === [...data?.withdrawalMethods as string[]].sort().join(',')} onClick={saveMethods}>
              Save
            </AwaitingButton>
          </>
        )}
      </Content>
    </>
  )
}
