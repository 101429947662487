export type LoadingStatus = 'idle' | 'loading' | 'success' | 'error'
export interface CommonReduxObject {
  data: object
  status: LoadingStatus
  error: string
}
export enum LoadingStatuses {
  Idle,
  Loading,
  Success,
  Error
}
