import { Spinner as ChakraSpinner, type SpinnerProps } from '@chakra-ui/react'
export default function Spinner (props: SpinnerProps) {
  return (<ChakraSpinner
    thickness="4px"
    my={2}
    speed="0.65s"
    emptyColor="gray.600"
    color="gray.200"
    size="xl"
    {...props}
  />)
}
