import instance from 'instances/axios'
import { LoadingStatuses } from 'types/store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { type IProxy } from 'types/roblox'
import { UserRoles } from 'types/users'
interface State {
  status: LoadingStatuses
  error: string | null
  data: IProxy[] | null
}
const initialState: State = {
  status: LoadingStatuses.Idle,
  error: null,
  data: null
}

export const fetchProxiesData = createAsyncThunk(
  'proxies/fetch',
  async (role: UserRoles) => {
    const response = await instance.get(role === UserRoles.Admin ? '/admin/all-proxies' : '/accounts/my-proxies')
    return response.data
  }
)
const slice = createSlice({
  name: 'proxies',
  initialState,
  reducers: {
    setProxiesData: (state, action) => {
      state.data = action.payload
    },
    addProxies: (state, action) => {
      if (!state.data) return
      state.data.push(...action.payload)
    },
    deleteProxy: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const proxyIndex = state.data.findIndex(
        (proxy) => proxy._id === _id
      )
      if (proxyIndex === -1) return
      state.data.splice(proxyIndex, 1)
    },
    deleteProxies: (state, action) => {
      if (!state.data) return
      const ids = action.payload
      state.data = state.data.filter((proxy) => !ids.includes(proxy._id))
    },
    toggleProxyActiveStatus: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const proxyIndex = state.data.findIndex(
        (proxy) => proxy._id === _id
      )
      if (proxyIndex === -1) return
      state.data[proxyIndex].isEnabled = !state.data[proxyIndex].isEnabled
    }
  },
  extraReducers (builder) {
    builder
      .addCase(fetchProxiesData.pending, (state) => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(fetchProxiesData.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Success
        state.data = action.payload
      })
      .addCase(fetchProxiesData.rejected, (state, action) => {
        state.status = LoadingStatuses.Error
        state.error = action.error.message || null
      })
  }
})
export default slice.reducer
export const {
  addProxies,
  setProxiesData,
  deleteProxies,
  toggleProxyActiveStatus,
  deleteProxy
} = slice.actions
export * from './selectors'
