import { Icon, Tag, Wrap } from '@chakra-ui/react'
import { TrashIcon, UndoIcon } from 'assets/icons'
import { AwaitingTag } from 'components/misc/AwaitingTag'
import Table, { getArrayRearrangedByKeys, TableColumnTypes } from 'components/misc/Table'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import instance from 'instances/axios'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { deleteAccount, deleteAccounts, toggleAccounts } from 'store/accounts'
import { selectCommonData } from 'store/common'

import { type IRobloxAccount, type RobloxAccountWithApiData } from 'types/roblox'
interface Props {
  accounts: RobloxAccountWithApiData[]
  setIsEditing: (newValue: boolean) => void
}
export default function EditingMode ({
  setIsEditing,
  accounts
}: Props) {
  const common = useAppSelector(selectCommonData)
  const minimumRobuxAmountForAccountActivity = common.data?.minimumRobuxAmountForAccountActivity || 200
  const preparedData = accounts.map(account => ({ ...account, balance: account.balance || 'XXXX' }))
  const dispatch = useAppDispatch()
  const [selectedAccountsIds, setSelectedAccountsIds] = useState<
  Array<RobloxAccountWithApiData['_id']>
  >([])
  const toggleAccountSelection = (_id: RobloxAccountWithApiData['_id']) => {
    const clonedData = [...selectedAccountsIds]
    const index = clonedData.findIndex((id) => id === _id)
    if (index === -1) {
      clonedData.push(_id)
    } else {
      clonedData.splice(index, 1)
    }
    setSelectedAccountsIds(clonedData)
  }
  const onDeletion = async () => {
    setIsEditing(false)
    try {
      await instance.delete('/accounts', {
        params: { ids: JSON.stringify(selectedAccountsIds) }
      })
      toast.success('The selected accounts have been deleted.')
      dispatch(deleteAccounts(selectedAccountsIds))
      setSelectedAccountsIds([])
    } catch {
      setIsEditing(true)
    }
  }
  const onSingularDeletion = async (_id: IRobloxAccount['_id']) => {
    try {
      await instance.delete('/accounts/account', {
        params: { id: _id }
      })
      toast.success('The selected account has been deleted.')
      dispatch(deleteAccount(_id))
    } catch {
    }
  }
  const onEnable = async () => {
    setIsEditing(false)
    try {
      await instance.put('/accounts/batch-toggle', {
        ids: selectedAccountsIds, mode: 'enable'
      })
      toast.success('All valid accounts will be enabled.')
      dispatch(toggleAccounts([selectedAccountsIds, 'enable']))
      setSelectedAccountsIds([])
    } catch {
      setIsEditing(true)
    }
  }
  const onDisable = async () => {
    setIsEditing(false)
    try {
      await instance.put('/accounts/batch-toggle', {
        ids: selectedAccountsIds, mode: 'disable'
      })
      toast.success('The selected accounts have been disabled.')
      dispatch(toggleAccounts([selectedAccountsIds, 'disable']))
      setSelectedAccountsIds([])
    } catch {
      setIsEditing(true)
    }
  }
  const selectAllAccounts = () => { setSelectedAccountsIds(accounts.map(acc => acc._id)) }
  const deselectAllAccounts = () => { setSelectedAccountsIds([]) }
  const areAllAccountsSelected = selectedAccountsIds.length === accounts.length
  const lowFundsAccounts = accounts.filter(acc => acc.balance && acc.balance < minimumRobuxAmountForAccountActivity)
  const areAllLowFundsSelected = lowFundsAccounts.length > 0 && lowFundsAccounts.every(acc => selectedAccountsIds.includes(acc._id))
  const disabledAccounts = accounts.filter(acc => !acc.isActive && (acc.balance ? acc.balance >= minimumRobuxAmountForAccountActivity : true))
  const areAllDisabledSelected = disabledAccounts.length > 0 && disabledAccounts.every(acc => selectedAccountsIds.includes(acc._id))
  const areAnyAccountsSelected = selectedAccountsIds.length > 0
  return (
    <>
    <Wrap justify="space-between" gap="20px"><Wrap gap="10px">
      <Tag data-clickable onClick={() => { setIsEditing(false) }} boxSize="28.8px" p="6px"><Icon as={UndoIcon} boxSize="full"/></Tag>
    <Tag data-clickable onClick={areAllAccountsSelected ? deselectAllAccounts : selectAllAccounts}>{areAllAccountsSelected ? 'Deselect' : 'Select'} all</Tag>
    <Tag data-clickable data-disabled={lowFundsAccounts.length === 0} onClick={() => {
      if (areAllLowFundsSelected) {
        const clonedIds = [...selectedAccountsIds]
        const indexesToDelete = []
        for (let i = 0; i < accounts.length; i++) {
          const account = accounts[i]
          if (lowFundsAccounts.some(acc => acc._id === account._id)) {
            indexesToDelete.push(i)
          }
        }
        for (const index of indexesToDelete.toReversed()) {
          clonedIds.splice(index, 1)
        }
        setSelectedAccountsIds(clonedIds)
      } else {
        setSelectedAccountsIds([...selectedAccountsIds, ...lowFundsAccounts.map(acc => acc._id)])
      }
    }} variant="pending">{areAllLowFundsSelected ? 'Deselect' : 'Select'} low funds</Tag>
    <Tag data-disabled={disabledAccounts.length === 0} data-clickable onClick={() => {
      if (areAllDisabledSelected) {
        const clonedIds = [...selectedAccountsIds]
        const indexesToDelete = []
        for (let i = 0; i < accounts.length; i++) {
          const account = accounts[i]
          if (disabledAccounts.some(acc => acc._id === account._id)) {
            indexesToDelete.push(i)
          }
        }
        for (const index of indexesToDelete.toReversed()) {
          clonedIds.splice(index, 1)
        }
        setSelectedAccountsIds(clonedIds)
      } else {
        setSelectedAccountsIds([...selectedAccountsIds, ...disabledAccounts.map(acc => acc._id)])
      }
    }} variant="negative">{areAllDisabledSelected ? 'Deselect' : 'Select'} disabled</Tag>
    </Wrap>
    <Wrap gap="10px">
      <Tag data-clickable onClick={onEnable} data-disabled={!areAnyAccountsSelected} variant="positive">Enable</Tag>
      <Tag data-clickable onClick={onDisable} data-disabled={!areAnyAccountsSelected}>Disable</Tag>
      <Tag data-clickable variant="negative" onClick={onDeletion} data-disabled={!areAnyAccountsSelected}>Delete</Tag>
    </Wrap>
    </Wrap>
    <Table mt="19px" selectedDescriptors={selectedAccountsIds} onRowSelection={(descriptor) => { if (!descriptor) { selectAllAccounts(); return };setSelectedAccountsIds([...selectedAccountsIds, descriptor]) }} onRowDeselection={(descriptor) => {
      if (!descriptor) { deselectAllAccounts(); return }
      const clonedSelectedAccountsIds = [...selectedAccountsIds]
      const index = clonedSelectedAccountsIds.indexOf(descriptor)
      clonedSelectedAccountsIds.splice(index, 1)
      setSelectedAccountsIds(clonedSelectedAccountsIds)
    }} isWithCheckboxes columns={['Username', { heading: 'Balance (R$)', type: TableColumnTypes.ColoredText }, { heading: 'Action', type: TableColumnTypes.Custom }]} data={getArrayRearrangedByKeys(preparedData, ['username', {
 key: 'balance',
addColumnProperties: (item) => {
  let textColor
  if (item.balance === 'XXXX' || !item.isActive) {
    textColor = 'red.600'
  } else if ((item.balance as number) < minimumRobuxAmountForAccountActivity) { textColor = 'yellow.400' }
  return { textColor }
    }
}, { key: '_id', addColumnProperties: (item) => ({ element: <AwaitingTag variant="negative" onClick={async () => { await onSingularDeletion(item._id) }} data-clickable size="sm"><Icon as={TrashIcon}/></AwaitingTag> }) }], (item) => ({ descriptor: item._id }))}/>

  </>)
}
