
import {
  Card,
  HStack,
  Heading,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { CardboardBoxIcon, CrossPadIcon, DollarPaperIcon, RefreshIcon, ShoppingCartIcon, TuningIcon } from 'assets/icons'
import { type ImportedIconType } from 'components/layout/Header'
import { useState } from 'react'
import Config from './Config'
import FailedOrders from './FailedOrders'
import Preorders from './Preorders'
import Verification from './Verification'
import Withdrawals from './Withdrawals'

interface TabWithIconProps {
  icon: ImportedIconType
  children: string
}
function TabWithIcon ({ icon, children }: TabWithIconProps) {
  return (
    <Tab display="flex" alignItems="center" gap="9px"><Icon boxSize="17px" as={icon}/>{children}</Tab>
  )
}
export default function AdminPanel () {
  const [key, setKey] = useState<number>(0)
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <>
      <HStack spacing={3}><Heading>Admin Panel</Heading>
      <IconButton variant="ghost" aria-label={'Reload the panel\'s data'} boxSize="40px!important" onClick={() => { setKey(key + 1) }} icon={<Icon as={RefreshIcon} boxSize="16px"/>}/></HStack>
      <Tabs isLazy onChange={(index) => { setTabIndex(index) }} isFitted mt={5} key={key}>
        <TabList>
          <TabWithIcon icon={CardboardBoxIcon}>Preorders</TabWithIcon>
          <TabWithIcon icon={CrossPadIcon}>Failed Orders</TabWithIcon>
          <TabWithIcon icon={TuningIcon}>Configuration</TabWithIcon>
          <TabWithIcon icon={DollarPaperIcon}>Withdrawal Requests</TabWithIcon>
          <TabWithIcon icon={ShoppingCartIcon}>Merchants</TabWithIcon>
        </TabList>
        <Card mt="30px" maxW={tabIndex === 2 ? '520px' : undefined}><TabPanels>

          <TabPanel>
            <Preorders/>
          </TabPanel>
          <TabPanel>
            <FailedOrders/>
          </TabPanel>
          <TabPanel>
            <Config/>
          </TabPanel>
          <TabPanel>
            <Withdrawals/>
          </TabPanel><TabPanel>
            <Verification />
          </TabPanel>
        </TabPanels></Card>
      </Tabs>
    </>
  )
}
