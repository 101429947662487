import { type AxiosError } from 'axios'
import { type ReactNode } from 'react'
export type TypedAxiosError = AxiosError<{ message: string }>
export interface IReactRoute {
  title: string
  url: string
  component: ReactNode | null
}
export enum AxiosStatuses {
  Pending,
  Success,
  Error,
}
export const isNumberValidationProps: [
  string,
  string,
  (value?: number) => boolean
] = [
  'isNumber',
  'You have to enter a valid number.',
  (value) => !isNaN(Number(value))
]
export enum StatisticsDateGrabbingModes {
  Day,
  Week,
  Month,
  AllTime,
}
export interface IStatisticsRecord {
  measure: number | string
  amount: number
}
export enum StatisticsSortTypes {
  Robuxes,
  Dollars,
  Revenue,
  Admin,
}

export type Must<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
}
export type ItemOf<T> = T[keyof T]
export type NonUndefined<T> = T extends undefined ? never : T