import { type ReactNode } from 'react'
import { Center, Text } from '@chakra-ui/react'
import { LoadingStatuses } from 'types/store'
import Spinner from './Spinner'

interface Props {
  status: LoadingStatuses
  error?: string | null
  children: ()=> (ReactNode | ReactNode[])
}
export default function Content ({ status, children, error }: Props) {
  switch (status) {
    case LoadingStatuses.Loading:
      return <Center><Spinner/></Center>
    case LoadingStatuses.Error:
      return <Text variant="error">The following error has occured: {Array.isArray(error) ? error.join(', ') : error}</Text>
    case LoadingStatuses.Success:
      return children()
    default:
      return <Center><Spinner/></Center>
  }
}
