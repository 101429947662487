import { Box, Button, HStack, Heading, Text, Wrap } from '@chakra-ui/react'
import Preorder from './Preorder'
import instance from 'instances/axios'
import { OrderFailureReasons, type IPreorder } from 'types/admin'
import Table, { TableColumnTypes } from 'components/misc/Table'
import { useEffect, useState } from 'react'
import Spinner from 'components/misc/Spinner'
import { toast } from 'react-hot-toast'
import { failureReasonsTexts, robuxesDeliveryMethodsVisualData } from '../../constants'
import { type IOrder, OrderStatuses } from 'types/economy'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import { getArrayRearrangedByKeys } from '../../components/misc/Table'
import { getDateTimeString } from 'instances/intl'
import AwaitingButton from 'components/misc/AwaitingButton'
export default function Preorders () {
  const [data, setData] = useState<Array<IPreorder & { isDone?: boolean }>>()
  const [requestController, setRequestController] = useState<AbortController>()
  useEffect(() => {
    instance.get<IPreorder[]>('/admin/preorders').then(res => {
      setData(res.data.map(order => ({ ...order, isDone: false })))
    })
  }, [])
  const [workingPreorderIndex, setWorkingPreorderIndex] = useState<number>()
  const performCleanUp = () => {
    if (!data) return
    const clonedData = [...data]
    setData(clonedData.filter(order => !order.isDone))
  }
  const setOrderIsDone = (index: number) => {
    if (!data) return
    const clonedData = [...data]
    clonedData[index].isDone = true
    setData(clonedData)
  }
  const tryToPay = async ({ controller, _id }: { controller?: AbortController, _id?: IPreorder['_id'] }) => {
    if (!data || (typeof workingPreorderIndex !== 'number' && !_id)) return
    const currentOrderIndex = _id ? data.findIndex(order => order._id === _id) : (workingPreorderIndex as number)
    if (currentOrderIndex === -1) { toast.error('The order was not found locally.'); return }
    const response = await instance.post<{ status: IOrder['status'], failureReason?: IOrder['failureReason'] }>('/admin/try-to-pay', { id: data[currentOrderIndex]._id }, { signal: controller?.signal })
    const { failureReason, status } = response.data
    if (status === OrderStatuses.Completed || failureReason !== OrderFailureReasons.NoAccountsUsable) {
      setOrderIsDone(currentOrderIndex)
    }
    if (status === OrderStatuses.Completed) {
      if (_id) {
        toast.success(`Order #${data[currentOrderIndex].id} was completed.`)
      }
    } else if (status === OrderStatuses.Failed) {
      let failureReasonText
      if (typeof failureReason === 'number' && Object.values(OrderFailureReasons).includes(failureReason)) {
        failureReasonText = failureReasonsTexts[failureReason]
      } else { failureReasonText = String(failureReason) }
      toast.error(`Error for order #${data[currentOrderIndex].id}: ${failureReasonText}`)
    }
  }
  const completePreorder = async () => {
    if (!data || typeof workingPreorderIndex !== 'number') return
    if (data[workingPreorderIndex].isDone) { setWorkingPreorderIndex(workingPreorderIndex + 1); return }
    const controller = new AbortController()
    setRequestController(controller)
    try {
      await (new Promise((res) => setTimeout(res, 10000)))
      await tryToPay({ controller })
    } catch {
    }
    setWorkingPreorderIndex(workingPreorderIndex + 1)
  }
  useEffect(() => {
    if (typeof workingPreorderIndex !== 'number' || !data) return
    if (data.length === 0 || workingPreorderIndex === data.length) { setWorkingPreorderIndex(undefined); performCleanUp(); return }
    completePreorder()
  }, [data, workingPreorderIndex])
  if (!data) return <Spinner/>
  const filteredData = data.filter(order => !order.isDone)
  return (
    <>
       <Wrap justify="space-between" spacing="20px"><Box><Heading fontSize="30px">Preorders</Heading>
       <Text mt="15px" color="var(--sub-text-color)" fontSize="13px" fontWeight="medium">R$ <Text as="span" color="white">{data.map(order => order.robuxesToSend).reduce((acc, curr) => acc + curr, 0)}</Text> in total.</Text>
       </Box>

       <Wrap spacing="15px">
          <Button data-widened
            isDisabled={data.length === 0}
            isLoading={typeof workingPreorderIndex === 'number'}
            onClick={() => { setWorkingPreorderIndex(0) }}
          >
            Start
          </Button>
          <Button data-widened variant="negative" onClick={() => { setWorkingPreorderIndex(undefined); performCleanUp(); requestController?.abort() }} isDisabled={typeof workingPreorderIndex !== 'number'}>
            Stop
          </Button>
        </Wrap></Wrap>
        {filteredData.length > 0
          ? (
            <>

          <Table stylesByRow={typeof workingPreorderIndex === 'number' ? { [workingPreorderIndex]: { bg: 'gray.700' } } : undefined} mt="30px"
            columns={['ID', { heading: 'Amount (R$)', type: TableColumnTypes.Tag }, { heading: 'Amount (US$)', type: TableColumnTypes.Tag }, 'Method', 'Username', 'Date & time', { heading: 'Action', type: TableColumnTypes.Custom }]}
            data={getArrayRearrangedByKeys(filteredData.map(preorder => ({ ...preorder, createdAt: getDateTimeString(preorder.createdAt), method: robuxesDeliveryMethodsVisualData[preorder.method].name })), ['id', 'robuxesToSend', 'paidAmountInDollars', 'method', 'username', 'createdAt', {
 key: '_id',
addColumnProperties (item) {
              return { element: <AwaitingButton variant="outline" onClick={async () => { try { await tryToPay({ _id: item._id }) } catch {} }}>Complete</AwaitingButton> }
            }
}])}
          />
          </>
            )
          : (
          <NotFoundCard heading='Data Unavailable' sub="No preorders were found." mt="30px"/>
            )}
    </>
  )
}
