import { type OrderFailureReasons } from './admin'

export enum WithdrawalStatuses {
  Pending,
  Approved,
  Rejected,
}
export interface IWithdrawal {
  id: number
  _id: string
  amount: number
  user: string
  createdAt: Date
  status: WithdrawalStatuses
  address: string
  method: string
}
export enum OrderStatuses {
  WaitingForPayment,
  WaitingForSending,
  Failed,
  Completed,
}
export enum RobuxesDeliveryMethods {
  GamePass,
  Plugin
}
export interface IOrderConditionalData {
  universeId: number
  clientId: number //* roblox id
}
export type OrderConditionalDataWithMethod = ({ method: RobuxesDeliveryMethods.GamePass, conditionalData: Pick<IOrderConditionalData, 'universeId'> } | { method: RobuxesDeliveryMethods.Plugin, conditionalData: Pick<IOrderConditionalData, 'clientId'> })
export enum Stores {
  RBXUP,
  RBXFAIL,
}
export type IOrder = {
  _id: string
  id: number
  account?: string
  merchant?: string
  username: string
  method: RobuxesDeliveryMethods
  paymentId: string
  failureReason?: OrderFailureReasons | string
  isAskedToRetry: boolean
  previousRobuxesPerDollarRate: number
  paidAmountInDollars: number
  paidAmountInRubles: number
  robuxesToSend: number
  status: OrderStatuses
  createdAt: Date
  completedAt?: Date
  merchantRevenue?: number
  isPreorder: boolean

  shop: Stores
  bonusRobuxes?: number
  referralUser?: string
  referralCodeOwner?: string

} & OrderConditionalDataWithMethod
export enum BonusRobuxesWithdrawalStatuses {
  Pending,
  Completed,
  Failed
}
export interface IBonusRobuxesWithdrawal {
  _id: string
  status: BonusRobuxesWithdrawalStatuses
  failureReason?: OrderFailureReasons
  user: string
  universeId: number
  robuxesToSend: number
  id: number
  merchant?: string
  completedAt?: Date
}

export type MinifiedOrder = Pick<IOrder, 'completedAt' | 'merchantRevenue' | 'paidAmountInDollars' | 'robuxesToSend'>
export type MinifiedBonusRobuxesWithdrawal = Pick<IBonusRobuxesWithdrawal, 'completedAt' | 'robuxesToSend'>

export interface ISalesStatisticsResponse {
  orders: MinifiedOrder[]
  bonusRobuxesWithdrawals: MinifiedBonusRobuxesWithdrawal[]
}

export enum PaymentSystems {
  GMPays = 'gmpays',
  Payok = 'payok',
  Palych = 'palych',
  Freekassa='freekassa'
}

export enum StatisticsSections {
  Robuxes,
  Dollars,
  Revenue,
  Admin
}

export enum StatisticsTimeGapModes {
  Day,
  Week,
  Month,
  Year,
  AllTime,
}