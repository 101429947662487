import instance from 'instances/axios'
import { Heading, Button, Box, HStack, useDisclosure } from '@chakra-ui/react'
import FormikInput from 'components/form/FormikInput'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../../../constants'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Link from 'components/misc/Link'
import { toast } from 'react-hot-toast'
import setUpLoginAndSetTokens from 'helpers/setUpLogin'
import ResetPasswordModal from './ResetPasswordModal'

const schema = Yup.object().shape({
  username: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  password: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
})
export default function LoginPage () {
  const resetPasswordModalDisclosure = useDisclosure()
  return (
    <>
      {' '}
      <Box mx="auto" w="full" maxW="500px">
        <Heading mb="10px">Sign In</Heading>
        <Formik
          validationSchema={schema}
          initialValues={{ login: '', password: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            const response = await instance.post('/auth/login', values)
            toast.success('You have been successfully signed in.')
            const tokens = response.data.tokens
            setUpLoginAndSetTokens(tokens)
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <fieldset disabled={isSubmitting}>
                <FormikInput
                  name="username"
                  placeholder="panel_username"
                  label="Username"
                />
                <FormikInput
                  name="password"
                  type="password"
                  placeholder="********"
                  label="Password"
                />
              </fieldset>
              <HStack mt="20px" w="full" justify="space-between" align="center">
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!isValid || !dirty}
                >
                  Submit
                </Button>
                <HStack spacing="20px">
                  <Link href="/register">Register</Link>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      resetPasswordModalDisclosure.onOpen()
                    }}
                  >
                    Forgot Password?
                  </Link>
                </HStack>
              </HStack>
            </Form>
          )}
        </Formik>
      </Box>
      <ResetPasswordModal
        isOpen={resetPasswordModalDisclosure.isOpen}
        onClose={resetPasswordModalDisclosure.onClose}
      />
    </>
  )
}
