const KZ_CITIES = ['Almaty', 'Aqtau', 'Aqtobe', 'Atyrau', 'Oral', 'Qyzylorda']
const KZ_PREFIX = 'Asia'
const citiesWithPrefix = KZ_CITIES.map(city => `${KZ_PREFIX}/${city}`)
export default function getTimeZone () {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  if (citiesWithPrefix.includes(timeZone)) {
    return `${KZ_PREFIX}/Aqtau`
  }
  return timeZone
}
