import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import AwaitingButton from './AwaitingButton'
interface Props {
  [key: string]: any
  children: ReactElement
  actionText?: string
  fullText?: string
  useNoText?: boolean
}
export default function Confirmation ({ fullText, children, actionText, useNoText, ...props }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      {React.cloneElement(children, { onClick: onOpen })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm the action</ModalHeader>
          <ModalBody>
            <Heading fontSize="2xl">Are you sure?</Heading>
            <Text mt={2}>{fullText || actionText ? `You are about to ${actionText || '...'}.` : 'This action is irreversible.'}</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>{useNoText ? 'No, take me back' : 'Cancel'}</Button>
            <AwaitingButton {...children.props} {...props} data-disabled={children.props.isDisabled} onClick={async () => { await children.props.onClick(); onClose() }}>Continue</AwaitingButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
