import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { menuAnatomy } from '@chakra-ui/anatomy'

import { buttonStyles } from 'chakra'
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
)

const Menu = defineMultiStyleConfig({

    baseStyle: {
      button: {
        ...buttonStyles.baseStyle,
        // @ts-expect-error bro
        ...buttonStyles.variants.ghost,
        bg: 'gray.600',
        _hover: {}
      },
      item: {
        bg: 'transparent',
        textTransform: 'unset',
        w: 'full',
        color: 'white',
        p: '10px',
        border: '0.4px solid var(--chakra-colors-gray-800)',
        borderRadius: '7px',
        pr: '30px',
        _notLast: {
          mb: '6px'
        },
        _selected: {
          borderColor: 'primary.400',
          bg: '#303844'
        },
        _hover: {
          borderColor: 'primary.400',
          bg: 'gray.700'
        }
      },
      list: {
        p: '10px',
        w: 'full',
        borderRadius: 'default',
        bg: '#1A2331',
        border: 'none'
      }
    }
})
export default Menu
