
import getTimeZone from 'helpers/getTimeZone'
import { useState, useMemo, useEffect } from 'react'
import Content from 'components/misc/Content'
import useAxios from 'hooks/useAxios'
import { StatisticsDateGrabbingModes } from 'types/utils'
import SelectViewPeriod from './SelectViewPeriod'
import { Heading, Text, Box, Card, Flex, Wrap } from '@chakra-ui/react'
import { TimeGapSelection } from './TimeGapSelection'
import { StatisticsTimeGapModes } from 'types/economy'
import { NotFoundCard } from 'components/misc/NotFoundCard'

export default function SalesByMerchants () {
  const [timeGap, setTimeGap] = useState<StatisticsTimeGapModes>(
    StatisticsTimeGapModes.Day
  )
  const timeZone = useMemo(() => getTimeZone(), [])
  const [particularDate, setParticularDate] = useState<Date | null>(new Date())
  const { data, status, error, reload } = useAxios<Record<string, number>>(
    '/admin/sales-by-merchants',
    { params: { timeGap, timeZone, particularDate } }
  )
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true)
      return
    }
    setParticularDate(new Date())

    reload()
  }, [timeGap])
  useEffect(() => {
    if (!particularDate) return
    reload()
  }, [particularDate])
  return (
    <>
      <Heading mt={8}>
        R$ sent
      </Heading>
      <TimeGapSelection mb="20px" onTimeGapChange={setTimeGap} onDateChange={setParticularDate}/>
      <Content status={status} error={error}>
        {() => <>
        {data &&
          (Object.keys(data).length > 0
            ? (
            <Card as={Wrap} gap="5px" p="20px">

              {Object.entries(data).map(([username, robuxesToSend], i) => (
                <Box borderRadius="10px" py="18px" px="20px" bg="#242D39" key={i}>
                  <Text whiteSpace="nowrap">{username === 'unassigned'
                    ? 'R$ sent by deleted merchants'
                    : `@${username}`}
                  : <Text as="span" fontWeight="medium">R$
                  <Text as="span" color="primary.400">
                    {robuxesToSend}
                  </Text></Text></Text>
                </Box>
              ))}
            </Card>
              )
            : (
            <NotFoundCard sub="No orders were completed during this period."/>
              ))}</>}
      </Content>
    </>
  )
}
