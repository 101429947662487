import toast from 'react-hot-toast'
import {
  Button,
  FormLabel,
  Text,
  Modal,
  useDisclosure,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter
} from '@chakra-ui/react'
import Content from 'components/misc/Content'
import FormikInput from 'components/form/FormikInput'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../../constants'
import { Field, Form, Formik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import instance from 'instances/axios'
import { selectCommonData } from 'store/common'
import * as Yup from 'yup'
import { type ICommonData } from 'types/config'
import { addAccounts } from 'store/accounts'
import { selectProxies } from 'store/proxies'
import { Select } from 'components/form/Select'
import FormikSelect from 'components/form/FormikSelect'
import ServerContent from 'components/misc/ServerContent'
export default function AddAccounts () {
  const commonData = useAppSelector(selectCommonData)
  const proxies = useAppSelector(selectProxies)
  const dispatch = useAppDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const enabledProxies = proxies.data?.filter(proxy => proxy.isEnabled)
  return (
    <ServerContent {...commonData}>
      {(data) => {
        const ValidationSchema = Yup.object().shape({
          cookies: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).test(
            'is10AtMost',
            'You can add at most 10 cookies.',
            (value) => value.split('\n').filter(Boolean).length <= 10
          ),
          price: Yup.number()
            .test(
              'isNumber',
              'You have to enter a valid number.',
              (value) => !isNaN(Number(value))
            )
            .min(
              data.minMerchantPrice,
              `Your price has to be at least $${data.minMerchantPrice}.`
            )
            .max(
              data.maxMerchantPrice,
              `Your price has to be at most $${data.maxMerchantPrice}.`
            ),
          proxy: Yup.string()
        })
        return (
          <>
            <Button data-widened mt="20px" onClick={onOpen}>Add new accounts</Button>
            <Modal onClose={onClose} isOpen={isOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Add cookies</ModalHeader>

                <Formik
                  initialValues={{ cookies: '', price: '' }}
                  validationSchema={ValidationSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    try {
                      const response = await instance.post(
                        '/accounts/add',
                        Object.fromEntries(Object.entries(values).map(entry => [entry[0], entry[1] || undefined]))
                      )
                      toast.success('The cookies have been added.')
                      resetForm()
                      onClose()
                      const createdAccounts = response.data
                      dispatch(addAccounts(createdAccounts))
                    } catch {}
                    setSubmitting(false)
                  }}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <ModalBody>
                      <fieldset disabled={isSubmitting}>
                        <FormikInput
                          type="textarea"
                          placeholder="Start pasting..."
                          label="Cookies, one per line"
                          name="cookies"
                        />
                        <FormikInput
                          type="number"
                          placeholder="5"
                          label="Price per R$1000, in US$"
                          name="price"
                        />
                        <FormLabel>
                          Proxy (optional)
                        </FormLabel>
                        <Content status={proxies.status} error={proxies.error}>
                          {() =>
                            (enabledProxies && enabledProxies.length > 0)
                              ? (
                              <FormikSelect
                                placeholder="None"
                                name="proxy"
                              >
                                {enabledProxies.map(({ _id, host, port }) => (
                                  <option value={_id} key={_id}>
                                    {host}:{port}
                                  </option>
                                ))}
                              </FormikSelect>
                                )
                              : (
                              <Text fontSize="sm" variant="ghost">
                                You don&apos;t have any enabled proxies.
                              </Text>
                                )
                          }
                        </Content></fieldset>
                      </ModalBody>
                      <ModalFooter>
                        <Button data-widened variant="ghost" onClick={onClose}>
                          Cancel
                        </Button>
                        <Button data-widened
                          type="submit"
                          isLoading={isSubmitting}
                          isDisabled={!isValid || !dirty}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </ModalContent>
            </Modal>
          </>
        )
      }}
    </ServerContent>
  )
}
