import {
  Button,
  Icon,
  Text,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  type MenuButtonProps,
  type As,
  Box,
  type MenuProps,
  type ThemingProps
} from '@chakra-ui/react'
import { ChevronIcon } from 'assets/icons'
import { type ReactNode } from 'react'

export function MenuButton ({
  leftIcon,
  rightIcon,
  children,
  ...props
}: { leftIcon?: As, rightIcon?: As, children: any } & MenuButtonProps & ThemingProps) {
  return (
    <ChakraMenuButton
      fontSize="15px!important"
      _active={{}}
      as={Button}
      whiteSpace="unset"
      cursor="pointer"
      fontWeight="normal"
      bg="gray.600"
      textTransform="unset"
      leftIcon={
        leftIcon
          ? (
          <Icon boxSize="18px" mr="calc(7px - 0.5rem)" as={leftIcon} />
            )
          : undefined
      }
      rightIcon={
        <Box>
          <Icon boxSize="17px" as={rightIcon || ChevronIcon} />
        </Box>
      }
      {...props}
      variant={props.variant || 'ghost'}
    >
      <Text w="fit-content" textAlign="left">
        {children}
      </Text>
    </ChakraMenuButton>
  )
}
export function MenuItem ({
  leftIcon,
  children,
  onClick,
  ...props
}: {
  leftIcon?: As
  onClick?: (...args: any) => void
  children: any
} & MenuButtonProps) {
  return (
    <ChakraMenuItem
      layerStyle="clickalike"
      fontSize="15px"
      as={Button}
      leftIcon={
        leftIcon
          ? (
          <Icon mr="calc(7px - 0.5rem)" boxSize="18px" as={leftIcon} />
            )
          : undefined
      }
      onClick={onClick}
      {...props}
    >
      <Text w="full" textAlign="left">
        {children}
      </Text>
    </ChakraMenuItem>
  )
}
interface Props {
  children: ReactNode[]
}
export default function Menu ({ children, ...props }: Props & MenuProps) {
  return <ChakraMenu {...props}>{children}</ChakraMenu>
}
