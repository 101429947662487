import { type defineStyleConfig } from '@chakra-ui/react'

export const buttonStyles: Parameters<typeof defineStyleConfig>['0'] = {
  baseStyle: {
    fontWeight: 500,
    minW: 'unset',
    borderRadius: '12px',
    textTransform: 'uppercase',
    _disabled: {
      pointerEvents: 'none'
    },

    color: '#fff',
    p: '16px 20px!important',
    _active: {
      transform: 'scale(0.97)'
    },
    _loading: {
      _active: {},
      _hover: {},
      pointerEvents: 'none'
    }
  },
  variants: {
    brand: {
      backgroundColor: 'primary.200',
      color: '#fff',
      _hover: {
        backgroundColor: '#287eff'
      },
      _active: {
        backgroundColor: '#0059de'
      },
      _loading: {
        bgColor: 'primary.200!important'
      },
      height: 'auto!important',
      fontSize: '14px'
    },
    neutral: {
      bg: '#354152',

      color: '#fff',
      height: 'auto!important',
      fontSize: '14px',
      _loading: {
        bgColor: '#354152!important'
      }
    },
    negative: {
      bg: '#B04A44',
      color: '#fff',
      _hover: { bg: '#bd554e' },
      _active: { bg: '#a1433e' },
      height: 'auto!important',
      fontSize: '14px',
      _loading: {
        bgColor: '#B04A44!important'
      }
    },
    outlined: {
      bg: 'transparent',
      border: '2px solid',

      borderRadius: 'default',
      borderColor: 'primary.200',
      _hover: {
        bg: 'rgba(0, 102, 255, 0.11)'
      },
      height: 'auto!important',
      fontSize: '14px',
      _loading: {
        bgColor: 'transparent!important'
      }
    },
    positive: {
      bg: '#3F8F56',
      color: '#fff',
      _hover: { bg: '#489f61' },
      _active: { bg: '#3d8652' },
      height: 'auto!important',
      fontSize: '14px',
      _loading: {
        bgColor: '#3F8F56!important'
      }
    },
    ghost: {
      bg: 'primary.400',
      color: 'white',
      minW: 'unset',
      _hover: {
        bg: 'gray.700',
        color: 'white'
      },
      _selected: {
        bg: 'gray.700'
      },
      _active: {
        bg: 'primary.400'
      },
      height: 'auto!important',
      fontSize: '14px',
      _loading: {
        bgColor: 'primary.400!important'
      }
    },
    icon: {}
  },
  defaultProps: {
    variant: 'brand'
  }
}
