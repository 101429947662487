import { Heading, Text } from '@chakra-ui/react'
import Content from 'components/misc/Content'
import { useAppSelector } from 'hooks/redux'
import { selectUser } from 'store/user'
import Withdrawals from './Withdrawals'
export default function FundingPage () {
  const user = useAppSelector(selectUser)
  return (
    <>
      <Heading>Funding</Heading>
      <Content status={user.status} error={user.error}>
        {() => <><Text mt="10px" fontSize="18px" fontWeight="medium">
          You have{' '}
          <Text as="span" color="#fff">
            ${user.data?.balance}
          </Text>{' '}
          on your balance.
        </Text></>}
      </Content>
      <Withdrawals />
    </>
  )
}
