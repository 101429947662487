export type enPluralsVariations = Record<Extract<'one' | 'few' | 'many' | 'zero' | 'other', Intl.LDMLPluralRule>, string>
const PluralEnglishRules = new Intl.PluralRules('en-US')
const timesWords: Partial<Record<Intl.LDMLPluralRule, string>> = {
  one: 'time',
  other: 'times'
}
export function getTimesPluralWord (num: number): string {
  return timesWords[PluralEnglishRules.select(num)] || ''
}

export const relativeTimeDisplay = new Intl.RelativeTimeFormat('en')
export const salesPlurals = {
  one: 'sale',
  few: 'sales',
  many: 'sales',
  zero: 'sales',
  other: 'sales'
}
export const robuxesPlurals = {
  one: 'robux',
  few: 'robuxes',
  many: 'robuxes',
  zero: 'robuxes',
  other: 'robuxes'
}
export const dollarsPlurals = {
  one: 'dollar',
  few: 'dollars',
  many: 'dollars',
  zero: 'dollars',
  other: 'dollars'
}
export const usersPlurals = {
  one: 'пользователь',
  few: 'пользователя',
  many: 'пользователей',
  zero: 'пользователей',
  other: 'пользователей'
}
export const withdrawalsPlurals = {
  one: 'вывод',
  few: 'вывода',
  many: 'выводов',
  zero: 'выводов',
  other: 'выводов'
}

export const DateTimeFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' })
export const TimeFormat = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })
export const DateFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' })

export const declinatedMonthsNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
]
const PluralRules = new Intl.PluralRules('en-US')
export const selectPlural = PluralRules.select.bind(PluralRules) as (...params: Parameters<Intl.PluralRules['select']>) => Extract<Intl.LDMLPluralRule, 'one' | 'few' | 'many' | 'zero' | 'other'>

export const daysNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const monthsNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const getDateTimeString = (date: Date) => `${DateFormat.format(new Date(date))}, ${TimeFormat.format(new Date(date))}`