import {
  Box,
  Center,
  Card as ChakraCard,
  Grid,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react'
import { ReceiptIcon, TagIcon } from 'assets/icons'
import roundCurrencyRelatedValue from 'helpers/roundCurrencyRelatedValue'
import { useAppSelector } from 'hooks/redux'
import { useMemo, type FunctionComponent } from 'react'
import { selectUser } from 'store/user'
import { UserRoles } from 'types/users'
import { type StatisticsFetchingResponse } from './Statistics/StatisticsGraph'
import { BalanceSolidIcon } from 'assets/icons/navbar'
interface Props {
  data: StatisticsFetchingResponse
}
interface CardProps {
  icon: FunctionComponent
  children: any
  heading: string
  addon?: string
  iconColor: string
}
const Card = ({ icon, children, addon, iconColor, heading }: CardProps) => (
  <ChakraCard
    as={Center}
    overflow="hidden"
    gap="30px"
    p="34px"
    pos="relative"
    borderRadius="15px"
  >
    <Box boxSize="44px">
      <Icon color={iconColor} boxSize="full" as={icon} />
    </Box>
    <VStack gap="10px"><Text color="gray.50" textTransform="uppercase" fontSize="0.75rem" fontWeight="medium">{heading}</Text>
    <Text fontSize="37px" fontWeight="medium">
      {children}&nbsp;
      <Text as="span" color="primary.400">
        {addon}
      </Text>
    </Text>
    </VStack>
  </ChakraCard>
)
export default function GeneralData ({ data }: Props) {
  const user = useAppSelector(selectUser)
  const soldAmount = useMemo(
    () => data.reduce((prev, order) => prev + order.integer, 0),
    [data]
  )
  const earnedAmount = useMemo(
    () =>
      user.data?.role === UserRoles.Admin
        ? data.reduce(
          (prev, order) => prev + (order.paidAmountInDollars - ((order.merchantRevenue as number) || 0)),
          0
        )
        : data.reduce(
          (prev, order) => prev + (order.merchantRevenue as number),
          0
        ),
    [data]
  )
  return (
    <Grid my="18px" gap="20px" templateColumns="repeat(auto-fit, minmax(320px, 1fr))">
      <Card icon={TagIcon} iconColor="purple.500" heading="Sold" addon="R$">
        {soldAmount}
      </Card>
      <Card icon={BalanceSolidIcon} iconColor="green.500" heading="Earned" addon="$">
        {roundCurrencyRelatedValue(earnedAmount)}
      </Card>
      <Card icon={ReceiptIcon} iconColor="blue.500" heading="Orders">
        {data.length}
      </Card>
    </Grid>
  )
}
