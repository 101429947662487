import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { selectCommonData } from 'store/common'
import * as Yup from 'yup'
import {
  Button,
  Divider,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import FormikInput from 'components/form/FormikInput'
import { Field, Formik, Form } from 'formik'
import instance from 'instances/axios'
import { useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { type IWithdrawal } from 'types/economy'
import { isNumberValidationProps } from 'types/utils'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../../../constants'
import { reduceBalance } from 'store/user'
import { Select } from 'components/form/Select'
import FormikSelect from 'components/form/FormikSelect'

interface Props {
  addWithdrawal: (withdrawal: IWithdrawal) => void
}
export default function RequestWithdrawal ({ addWithdrawal }: Props) {
  const dispatch = useAppDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: commonData } = useAppSelector(selectCommonData)
  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .test(...isNumberValidationProps)
          .min(
            commonData?.minWithdrawalAmount || 0,
            `You have to withdraw at least $${
              String(commonData?.minWithdrawalAmount) || '...'
            }.`
          )
          .max(
            commonData?.maxWithdrawalAmount || Number.MAX_SAFE_INTEGER,
            `The biggest amount you can withdraw for now is $${
              String(commonData?.maxWithdrawalAmount) || '...'
            }.`
          )
          .required(REQUIRED_FIELD_ERROR_MESSAGE),
        methodAddress: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
        method: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
      }),
    [commonData]
  )
  return (
    <>
    <Button mt="20px" onClick={onOpen}>Request a withdrawal</Button>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton/>
        <ModalHeader>Request a withdrawal</ModalHeader>

        <Formik
          validationSchema={ValidationSchema}
          initialValues={{ amount: '', methodAddress: '', method: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            try {
              const response = await instance.post<IWithdrawal>(
                '/users/request-withdrawal',
                values
              )
              toast.success('The withdrawal has been requested.')
              addWithdrawal(response.data)
              dispatch(reduceBalance(Number(values.amount)))
              resetForm()
              onClose()
            } catch {}
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form>
              <ModalBody>
              <fieldset disabled={isSubmitting}>
                <FormikInput
                  name="amount"
                  placeholder="300"
                  label="Amount (US$)"
                />
                <FormLabel htmlFor="method">
                  Method
                </FormLabel>
                <FormikSelect placeholder="Choose the payment method..." style={{ marginBottom: '25px' }} name="method">

                  {commonData?.withdrawalMethods.map((method, i) => (
                    <option style={{ background: 'var(--root-bg)', color: '#fff' }} key={i} value={String(i)}>
                      {method}
                    </option>
                  ))}
                </FormikSelect>
                <FormikInput
                  name="methodAddress"
                  placeholder="****************"
                  label="Address"
                />
                </fieldset>
              </ModalBody>
              <ModalFooter>
                <Button data-widened variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button data-widened
                  type="submit"
                  isLoading={isSubmitting}
                  isDisabled={!dirty || !isValid}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>

    </>
  )
}
