import FormInput from './FormInput'
import { useField } from 'formik'

export default function FormikInput ({ children, ...props }: any) {
  const [field, meta] = useField(props)
  const isInvalid = !!(meta.touched && meta.error)
  return (
    <FormInput
      {...field}
      {...props}
      errorMessage={meta.error}
      isInvalid={isInvalid}
      field={field}
    >{children}</FormInput>
  )
}
