import { type IOrder, type IWithdrawal, type OrderStatuses } from './economy'
import { type IUser } from './users'

export interface IPendingVerificationRequest {
  discordUsername: IUser['discordUsername']
  username: IUser['username']
  userCreatedAt: IUser['createdAt']
}

export interface IVerificationToken extends IPendingVerificationRequest {
  tokenCreatedAt: Date
  value: string
}
export enum OrderFailureReasons {
  NoAccountsUsable,
  GamePassNotFound,
  AccountNotFound,
  InsufficientFunds,
  GamePassNotForSale,
  InvalidUniverseId,
  CSRFTokenGenerationFailed,
  AccountHas2FA,
  PluginNotFound,
  PluginNotForSale,
  GamePassNotFoundWhileDeleting,
  PluginNotFoundWhileDeleting,
  AccountImproperlyConfigured
}
export interface IPreorder extends Omit<IOrder, 'account'> {
  account: undefined
}
export interface IFailedOrPendingOrder extends Omit<IOrder, 'status' | 'failureReason'> {
  status: OrderStatuses.Failed | OrderStatuses.WaitingForSending
  failureReason: OrderFailureReasons | string
}
export interface AdminViewWithdrawal extends IWithdrawal {
  username: IUser['username']
}

export interface ICustomPromocode {
  value: string
  bonusAmount: number
  timesUsed: number
}
