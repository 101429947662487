import * as Yup from 'yup'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type UseDisclosureReturn
} from '@chakra-ui/react'
import FormikInput from 'components/form/FormikInput'
import { Form, Formik } from 'formik'
import { isNumberValidationProps } from 'types/utils'
import { type ICustomPromocode } from 'types/admin'
import instance from 'instances/axios'
import { toast } from 'react-hot-toast'

interface Props {
  isOpen: UseDisclosureReturn['isOpen']
  onClose: UseDisclosureReturn['onClose']
  addPromocode: (promocode: ICustomPromocode) => void
}

const ValidationSchema = Yup.object({}).shape({
  promo_name: Yup.string().required('Required.'),
  promo_amount: Yup.number()
    .test(...isNumberValidationProps)
    .required('Required.')
})

export default function NewPromocodeModal ({
  isOpen,
  onClose,
  addPromocode
}: Props) {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Add a new promocode</ModalHeader>

          <Formik
            initialValues={{ promo_name: '', promo_amount: '' }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              try {
                const promocode: Omit<ICustomPromocode, 'timesUsed'> = {
                  value: values.promo_name.toLowerCase(),
                  bonusAmount: +values.promo_amount
                }
                await instance.post('/admin/promocode', promocode)
                toast.success('The promocode has been added.')
                addPromocode({ ...promocode, timesUsed: 0 })
                onClose()
              } catch {}
              setSubmitting(false)
              resetForm()
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form><ModalBody>
                <FormikInput
                  placeholder="promo2020"
                  name="promo_name"
                  label="Name"
                />
                <FormikInput
                  placeholder="200"
                  type="number"
                  name="promo_amount"
                  label="Bonus R$ amount"
                />

              </ModalBody>
        <ModalFooter>
        <Button
                  type="submit"
                  isDisabled={!isValid || !dirty}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
        </ModalFooter></Form>
            )}
          </Formik>

      </ModalContent>
    </Modal>
  )
}
