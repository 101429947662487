import { UserVerificationStatuses, type IUser } from 'types/users'
import instance from 'instances/axios'
import { LoadingStatuses } from 'types/store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import roundCurrencyRelatedValue from 'helpers/roundCurrencyRelatedValue'
interface State {
  status: LoadingStatuses
  error: string | null
  isLogged: boolean
  data: IUser | null
}
const initialState: State = {
  status: LoadingStatuses.Idle,
  isLogged: !!localStorage.getItem('access_token'),
  error: null,
  data: null
}

export const fetchUserData = createAsyncThunk(
  'user/fetch',
  async () => {
    const response = await instance.get('/users/me')
    return response.data
  }
)

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload
    },
    setLoggedStatus: (state, action) => {
      state.isLogged = action.payload
    },
    setUserVerified: (state) => {
      if (!state.data) return
      state.data.verificationStatus = UserVerificationStatuses.Verified
    },

    reduceBalance: (state, action) => {
      if (!state.data) return
      state.data.balance = roundCurrencyRelatedValue(state.data.balance - action.payload)
    },
    topUpBalance: (state, action) => {
      if (!state.data) return
      state.data.balance = roundCurrencyRelatedValue(state.data.balance + (action.payload as number))
    },
    logOutForRedux: (state) => {
      state.isLogged = false
    }
  },
  extraReducers (builder) {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Success
        state.data = action.payload
        if (action.payload.balance < 0 && !localStorage.getItem('seenWarningBanner')) {
          alert('⚠️⚠️⚠️⚠️⚠️ Accidental balance top-ups caused by a bug in our system have been revoked. You happen to have withdrawn these funds. For now, your balance is below zero. We apologize for inconviniences.')
          localStorage.setItem('seenWarningBanner', 'true')
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = LoadingStatuses.Error
        state.error = action.error.message || null
      })
  }
})
export default slice.reducer
export const { setUserData, topUpBalance, reduceBalance, setLoggedStatus, setUserVerified, logOutForRedux } = slice.actions
export * from './selectors'
