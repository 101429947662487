import AccountRow from 'pages/MyAccounts/AccountRow'
import Table, { getArrayRearrangedByKeys, TableColumnTypes, transformArrayForTable } from './Table'
import { AccountStatuses, type IRobloxAccountDataForAdmin, type RobloxAccountWithApiData } from 'types/roblox'
import { UserRoles } from 'types/users'
import { DateFormat, DateTimeFormat, getDateTimeString, TimeFormat } from 'instances/intl'
import getAccountStatusRegardingAdditionalData from 'helpers/getAccountStatusRegardingAdditionalData'
import { useAppSelector } from 'hooks/redux'
import { selectCommonData } from 'store/common'
import { ACCOUNT_STATUSES_TAG_VARIANTS, ACCOUNT_STATUSES_MESSAGES } from '../../constants'
import { type TableProps } from '@chakra-ui/react'
import { useMemo } from 'react'

interface Props {
  role?: UserRoles
  data: Array<RobloxAccountWithApiData & Partial<IRobloxAccountDataForAdmin>>
  isSingleItem?: boolean
}

export default function AccountsTable ({ data, role, isSingleItem = false, ...props }: Props & Omit<TableProps, 'role'>) {
  const isAdmin = role === UserRoles.Admin
  const common = useAppSelector(selectCommonData)
  const minimumRobuxAmountForAccountActivity = common.data?.minimumRobuxAmountForAccountActivity || 200

  const preparedData = data.map(account => {
    const accountStatus = getAccountStatusRegardingAdditionalData({ ...account, minimumRobuxAmountForAccountActivity })
    return { ...account, createdAt: getDateTimeString(account.createdAt), balance: account.balance || 'XXXX', status: accountStatus, statusMessage: ACCOUNT_STATUSES_MESSAGES[accountStatus] }
})
const tableData = useMemo(() => getArrayRearrangedByKeys(preparedData, ['username', { key: 'price', addColumnProperties: { tagVariant: 'neutral' } }, ...(isAdmin ? ['merchantUsername' as keyof (typeof preparedData)[0]] : []), { key: 'balance', addColumnProperties: { tagVariant: 'neutral' } }, { key: 'spentAmount', addColumnProperties: { tagVariant: 'neutral' } }, 'createdAt', { key: 'statusMessage', addColumnProperties: (item) => { return { tagVariant: ACCOUNT_STATUSES_TAG_VARIANTS[item.status] } } }], (account) => (isSingleItem ? {} : { descriptor: account._id, href: `/accounts/${account._id}` })), [data])
  return (
    <>

    <Table
      columns={[{
        heading: 'Username'
      }, { heading: 'Price for R$1000', type: TableColumnTypes.Tag }, ...(isAdmin ? [{ heading: 'Merchant Username' }] : []), { heading: 'Balance (R$)', type: TableColumnTypes.Tag }, { heading: 'Transferred (R$)', type: TableColumnTypes.Tag }, { heading: 'Added' }, { heading: 'Status', type: TableColumnTypes.Tag }]}
      data={tableData}
      {...props}
    >
      {/* {data.map((account) => (
        <AccountRow isAdminView={isAdmin} key={account._id} {...account} />
      ))} */}
    </Table></>
  )
}
