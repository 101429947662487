import { Box, HStack, Text } from '@chakra-ui/react'
import ServerContent from 'components/misc/ServerContent'
import Spinner from 'components/misc/Spinner'
import { useAppSelector } from 'hooks/redux'
import { selectCommonData } from 'store/common'
import { ICommonData } from 'types/config'
const positiveColor = '1ab86c'
const negativeColor = 'f54a4a'
const PulsatingIcon = ({ isPositive }: { isPositive: boolean }) => {
  const color = isPositive ? positiveColor : negativeColor
  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      {isPositive && (
        <circle
          cx="20"
          cy="20"
          fill="none"
          r="10"
          stroke={`#${color}70`}
          strokeWidth="5"
        >
          <animate
            attributeName="r"
            from="8"
            to="20"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      )}
      <circle cx="20" cy="20" fill={`#${color}`} r="10" />
    </svg>
  )
}
interface Props {
  areSalesEnabled:ICommonData['areSalesEnabled']
}
export function HeaderStatus ({areSalesEnabled}:Props) {
  
  return (
    
        <HStack gap="7px" mb="15px">
          <Box boxSize="20px">
            <PulsatingIcon isPositive={areSalesEnabled} />
          </Box>
          <Text fontWeight="medium" display={{ sm: 'block', md: 'none', '2xl': 'block' }} color={`#${areSalesEnabled ? positiveColor : negativeColor}`}>Sales {areSalesEnabled ? 'active' : 'disabled'}</Text>
        </HStack>
      
  )
}
