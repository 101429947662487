import instance from 'instances/axios'
import { LoadingStatuses } from 'types/store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { type ICommonData } from 'types/config'
interface State {
  status: LoadingStatuses
  error: string | null
  data: ICommonData | null
}
const initialState: State = {
  status: LoadingStatuses.Idle,
  error: null,
  data: null
}

export const fetchCommonData = createAsyncThunk(
  'common/fetch',
  async () => {
    const response = await instance.get('/accounts/stats')
    return response.data
  }
)

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonData: (state, action) => {
      state.data = action.payload
    },
    addPaymentMethod: (state, action) => {
      if (!state.data) return
      state.data.withdrawalMethods.push(action.payload)
    },
    deletePaymentMethod: (state, action) => {
      if (!state.data) return
      const index = state.data.withdrawalMethods.findIndex(name => name === action.payload)
      if (index === -1) return
      state.data.withdrawalMethods.splice(index, 1)
    },
    setPaymentMethods: (state, action) => {
      if (!state.data) return
      state.data.withdrawalMethods = action.payload
    }
  },
  extraReducers (builder) {
    builder
      .addCase(fetchCommonData.pending, (state) => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(fetchCommonData.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Success
        state.data = action.payload
      })
      .addCase(fetchCommonData.rejected, (state, action) => {
        state.status = LoadingStatuses.Error
        state.error = action.error.message || null
      })
  }
})
export default slice.reducer
export const { setCommonData, setPaymentMethods, addPaymentMethod, deletePaymentMethod } = slice.actions
export * from './selectors'
