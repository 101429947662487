export enum UserRoles {
  Merchant,
  Admin,
}
export enum UserVerificationStatuses {
  Pending,
  Verified,
  Rejected
}
export interface IUser {
  _id: string
  email: string
  username: string
  verificationStatus: UserVerificationStatuses
  role: UserRoles
  balance: number
  discordUsername: string
  createdAt: Date
}
