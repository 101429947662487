import React, { useEffect } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import ReactDOM from 'react-dom/client'
import './index.css'
import './react-datepicker.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

function ScrollToTop () {
  const { pathname, state } = useLocation()

  useEffect(() => {
    if (state?.from) document.getElementsByTagName('main')[0]?.scrollTo(0, 0)
  }, [pathname])

  return null
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
