import AccountPage from 'pages/Account'
import AccountVerification from 'pages/AccountVerification'
import AdminPanel from 'pages/AdminPanel'
import FundingPage from 'pages/Funding'
import MyAccounts from 'pages/MyAccounts'
import MyProxiesPage from 'pages/MyProxies'
import DashboardPage from 'pages/Dashboard'
import LoginPage from 'pages/auth/Login'
import RegisterPage from 'pages/auth/Register'
import { UserRoles, type IUser, UserVerificationStatuses } from 'types/users'
import { type IReactRoute } from 'types/utils'
import { LoadingStatuses } from 'types/store'

export const commonRoutes: IReactRoute[] = [
  {
    title: 'Dashboard',
    url: '/',
    component: <DashboardPage />
  },
  {
    title: 'My Accounts',
    url: '/accounts',
    component: <MyAccounts />
  },
  {
    title: 'Account',
    url: '/accounts/:id',
    component: <AccountPage />
  },
  {
    title: 'My Proxies',
    url: '/proxies',
    component: <MyProxiesPage />
  },
  { title: 'Funding', url: '/funding', component: <FundingPage /> }
]
export const adminRoutes: IReactRoute[] = [
  { title: 'Admin Panel', url: '/admin', component: <AdminPanel /> }
]
export const guestRoutes: IReactRoute[] = [
  {
    title: 'Sign In',
    url: '/login',
    component: <LoginPage />
  },
  { title: 'Sign Up', url: '/register', component: <RegisterPage /> }
]
export const notVerifiedUserRoutes = [
  {
    title: 'Account Verification',
    url: '/',
    component: <AccountVerification />
  }
]
export const getRoutes = ({
  isLogged,
  verificationStatus,
  role,
  status
}: Partial<Pick<IUser, 'verificationStatus' | 'role'>> & {
  isLogged: boolean,
  status:LoadingStatuses
}) => {
  let routes: IReactRoute[] = []
  if (isLogged && status === LoadingStatuses.Success) {
    if (verificationStatus === UserVerificationStatuses.Verified) {
      routes = routes.concat(commonRoutes)
      if (role === UserRoles.Admin) {
        routes = routes.concat(adminRoutes)
      }
    } else {
      routes = routes.concat(notVerifiedUserRoutes)
    }
  } else {
    routes = routes.concat(guestRoutes)
  }
  return routes
}
