import DatePicker, { type ReactDatePickerProps } from 'react-datepicker'
import {
  Box,
  Button,
  type ButtonProps,
  Card,
  HStack,
  Icon,
  Stack,
  Text
} from '@chakra-ui/react'
import { Select } from 'components/form/Select'
import ServerContent from 'components/misc/ServerContent'
import useAxios from 'hooks/useAxios'
import { type Dayjs } from 'dayjs'
import { dayjs } from 'instances/dayjs'
import { forwardRef, type LegacyRef, useEffect, useState } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  //   Rectangle,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import {
  type IOrder,
  StatisticsSections,
  StatisticsTimeGapModes
} from 'types/economy'
import {
  dollarsPlurals,
  type enPluralsVariations,
  robuxesPlurals,
  selectPlural
} from 'instances/intl'
import roundCurrencyRelatedValue from 'helpers/roundCurrencyRelatedValue'
import { type DurationUnitType } from 'dayjs/plugin/duration'
import { type IStatisticsRecord } from 'types/utils'
import { CalendarIcon } from 'assets/icons'
import { TimeGapSelection } from '../TimeGapSelection'
import CommonData from '../CommonData'
import GeneralData from '../GeneralData'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import getTimeZone from 'helpers/getTimeZone'

const statisticsSectionsVisualData: Record<
  StatisticsSections,
  { url: string, heading: string }
> = {
  [StatisticsSections.Admin]: { url: 'admin/sales', heading: 'Продажи' },
  [StatisticsSections.Dollars]: { url: 'dollars', heading: 'Пользователи' },
  [StatisticsSections.Revenue]: {
    url: 'users/sales',
    heading: 'Выводы'
  },
  [StatisticsSections.Robuxes]: {
    url: 'robuxes',
    heading: 'Выводы'
  }
}

type AllowedTimeUnits = Extract<
  keyof Dayjs,
  'month' | 'date' | 'year' | 'hour' | 'day'
>
const timeGapModesToTimeUnits: Record<
  StatisticsTimeGapModes,
  DurationUnitType | 'date' | 'alltime'
> = {
  [StatisticsTimeGapModes.Day]: 'date',
  [StatisticsTimeGapModes.Week]: 'week',
  [StatisticsTimeGapModes.AllTime]: 'alltime',
  [StatisticsTimeGapModes.Month]: 'month',
  [StatisticsTimeGapModes.Year]: 'year'
}

//! ! poor typings
const descendantTimeUnits: Record<string, AllowedTimeUnits> = {
  date: 'hour',
  month: 'date',
  week: 'day',
  year: 'month',
  alltime: 'year'
}

enum TooltipLabels {
  Robuxes,
  Dollars,
}
const tooltipLabelTextsVariations: Record<
  TooltipLabels,
  enPluralsVariations /* ! */
> = {
  //!
  [TooltipLabels.Robuxes]: robuxesPlurals,
  [TooltipLabels.Dollars]: dollarsPlurals
}
const statisticsSectionsToTooltipLabelTextVariations: Record<
  StatisticsSections,
  TooltipLabels
> = {
  [StatisticsSections.Admin]: TooltipLabels.Robuxes,
  [StatisticsSections.Dollars]: TooltipLabels.Dollars,
  [StatisticsSections.Revenue]: TooltipLabels.Dollars,
  [StatisticsSections.Robuxes]: TooltipLabels.Robuxes
}
const getTooltipLabelTextVariation = (section: StatisticsSections) => {
  const variation = statisticsSectionsToTooltipLabelTextVariations[section]

  //   if (variation === TooltipLabels.Dollars && groupingMode === StatisticsSalesGroupingModes.) {
  //     variation = TooltipLabels.SalesQuantity
  //   } else if (variation === TooltipLabels.WithdrawalsAmounts && groupingMode === StatisticsSalesGroupingModes.Quantity) {
  //     variation = TooltipLabels.WithdrawalsQuantity
  //   }

  return variation
}

function CustomTooltip ({
  active,
  payload,
  label,
  withdrawals,
  mode,
  labels
}: Pick<
  React.ComponentProps<typeof Tooltip>,
  'payload' | 'active' | 'label'
> & {
  labelTextVariation: TooltipLabels
  withdrawals: StatisticsFetchingResponse
  labels: string[] | number[]
  mode: StatisticsTimeGapModes
}) {
  if (!active || !payload) return null

  const { value: rawValue } = payload[0]
  const value = Array.isArray(rawValue) ? rawValue[0] : rawValue
  //   const withdrawalsWithCurrentMeasureRobuxesAmount = withdrawals.filter(w => String(w.measure) === String(label)).map(w => w.amount).reduce((prev, cur) => prev + cur, 0)
  //   const areRobuxesShown = (sortType === StatisticsSortTypes.Robuxes || sortType === StatisticsSortTypes.Admin)
  const timeUnit = timeGapModesToTimeUnits[mode]
  const descendantTimeUnit = descendantTimeUnits[timeUnit]
  const withdrawalsWithCurrentMeasureRobuxesAmount = withdrawals.filter(w => {
    return labels[dayjs(w.timestamp)[descendantTimeUnit]()] === String(label)
}).map(w => w.integer).reduce((prev, cur) => prev + cur, 0)
  return (
    <Box
      bg="#10151D"
      borderRadius="default"
      py="5px" px="10px"
    >
      <Text fontSize="10px" fontWeight="medium" color="gray.400">
        {label}
      </Text>
      <Text color="white" fontWeight="medium">
        {typeof value !== 'undefined'
          ? `R$ ${value}`
          : '...'}
      </Text>
      {withdrawalsWithCurrentMeasureRobuxesAmount ? <Text fontSize="sm" color="gray.300">Bonus withdrawals: R$ {withdrawalsWithCurrentMeasureRobuxesAmount}</Text> : null}
    </Box>
  )
}
type DayjsDurationMethods = Extract<
  keyof ReturnType<(typeof dayjs)['duration']>,
  'asDays' | 'asWeeks' | 'asMonths' | 'asYears'
>

const ALL_TIME_MODE_STARTING_YEAR = 2022
const weekdays = (() => {
  const { format } = new Intl.DateTimeFormat('en-US', { weekday: 'short' })
  return [...Array(7)].map((_, i) => format(new Date(Date.UTC(2021, 5, i))))
})()
const months = (() => {
  const { format } = new Intl.DateTimeFormat('en-US', { month: 'short' })
  return [...Array(12)].map((_, i) => format(new Date(Date.UTC(2021, i + 1, 1))))
})()
const currentYear = dayjs().year()
const years = [...Array(currentYear - ALL_TIME_MODE_STARTING_YEAR + 1)].map(
  (_, i) => ALL_TIME_MODE_STARTING_YEAR + i
)
const getArrayWithIncrementedNumbers = (count: number) =>
  [...Array(count)].map((_, i) => i + 1)
const getLabelsRegardingTimeGapMode = (
  mode: StatisticsTimeGapModes,
  currentUnitCount: number
) => {
  switch (mode) {
    case StatisticsTimeGapModes.Year:
      return months
    case StatisticsTimeGapModes.Week:
      return weekdays
    case StatisticsTimeGapModes.Day:
      return getArrayWithIncrementedNumbers(currentUnitCount).map(
        (num) => `${num - 1}:00`
      )
    case StatisticsTimeGapModes.AllTime:
      return years
    default:
      return getArrayWithIncrementedNumbers(currentUnitCount)
  }
}

export type StatisticsFetchingResponse = Array<
  { timestamp: string, integer: number, isWithdrawal?: boolean } & Pick<
    IOrder,
    'paidAmountInDollars' | 'merchantRevenue'
  >
>

function prepareDataForGraph ({
  timeGapMode,
  data
}: {
  data: StatisticsFetchingResponse
  timeGapMode: StatisticsTimeGapModes
}) {
  const timeUnit = timeGapModesToTimeUnits[timeGapMode]
  const descendantTimeUnit = descendantTimeUnits[timeUnit]

  const durationMethodName = `as${
    descendantTimeUnit === 'date'
      ? 'Day'
      : descendantTimeUnit[0].toUpperCase() + descendantTimeUnit.slice(1)
  }s` as DayjsDurationMethods

  const maxDescendantUnitsCount =
    timeUnit === 'alltime'
      ? currentYear - ALL_TIME_MODE_STARTING_YEAR + 1
      : Math.round(
          dayjs
            .duration(1, timeUnit === 'date' ? 'day' : timeUnit)[durationMethodName]()
        )

  const labels = getLabelsRegardingTimeGapMode(
    timeGapMode,
    maxDescendantUnitsCount
  )
  const dataForGraph: Array<{ label: string | number, count: number }> = [
    ...Array(maxDescendantUnitsCount)
  ].map((_, i) => ({ label: labels[i], count: 0 }))

  for (const dataObject of data) {
    const currentUnit = dayjs(dataObject.timestamp)[descendantTimeUnit]()

    const itemForCurrentUnit =
      timeUnit === 'alltime'
        ? dataForGraph.find((item) => item.label === currentUnit)
        : dataForGraph[currentUnit]
    if (itemForCurrentUnit) itemForCurrentUnit.count += dataObject.integer || 1
  }
  for (const item of dataForGraph) {
    item.count = roundCurrencyRelatedValue(item.count)
  }
  return dataForGraph
}
// const CustomizedCursor = (props: any) => {
//   const { x, y, width, height } = props
//   return (
//     <Rectangle
//       fill="red"
//       stroke="red"
//       x={x}
//       y={y}
//       width={width}
//       height={height}
//     />
//   )
// }

export enum StatisticsGraphThemes {
  Gray,
  Green,
}
// const graphThemes: Record<
// StatisticsGraphThemes,
// { fill: [string, string], stroke: string }
// > = {
//   [StatisticsGraphThemes.Gray]: {
//     fill: ['rgb(91, 114, 146)', 'rgb(22, 29, 38)'],
//     stroke: '#5B7292'
//   },
//   [StatisticsGraphThemes.Green]: {
//     fill: ['rgb(12, 175, 96)', 'rgb(12, 175, 96)'],
//     stroke: '#0CAF60'
//   }
// }
interface Props {
  section: StatisticsSections
  theme?: StatisticsGraphThemes
}

export function StatisticsGraph ({
  section
}: //   theme = StatisticsGraphThemes.Gray
Props) {
  const visualData = statisticsSectionsVisualData[section]
  const [selectedTimeGapMode, setSelectedTimeGapMode] =
    useState<StatisticsTimeGapModes>(StatisticsTimeGapModes.Day)
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  const request = useAxios<StatisticsFetchingResponse>(`/${visualData.url}`, {
    params: {
      timeZone: getTimeZone(),
      //   groupingMode: isGroupingModeSelectionPermitted
      //     ? selectedGroupingMode
      //     : undefined,
      mode: selectedTimeGapMode,
      particularDate: selectedDate
    }
  })
  //   const graphTheme = graphThemes[theme]
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true)
      return
    }
    setSelectedDate(new Date())

    request.reload()
  }, [selectedTimeGapMode])
  useEffect(() => {
    if (!selectedDate) return
    request.reload()
  }, [selectedDate])
  const timeUnit = timeGapModesToTimeUnits[selectedTimeGapMode]
  const descendantTimeUnit = descendantTimeUnits[timeUnit]
  const durationMethodName = `as${
    descendantTimeUnit === 'date'
      ? 'Day'
      : descendantTimeUnit[0].toUpperCase() + descendantTimeUnit.slice(1)
  }s` as DayjsDurationMethods

  const maxDescendantUnitsCount =
    timeUnit === 'alltime'
      ? currentYear - ALL_TIME_MODE_STARTING_YEAR + 1
      : Math.round(
          dayjs
            .duration(1, timeUnit === 'date' ? 'day' : timeUnit)[durationMethodName]()
        )

  const labels = getLabelsRegardingTimeGapMode(
    selectedTimeGapMode,
    maxDescendantUnitsCount
  )
  return (
    <>
      <TimeGapSelection
        onTimeGapChange={setSelectedTimeGapMode}
        onDateChange={setSelectedDate}
      />

      <ServerContent centerSkeleton {...request}>
        {(data) => {
          if (data.length === 0) return <NotFoundCard mt="20px" sub="No orders were made during this period."/>
          const withdrawals = data.filter(order => order.isWithdrawal)

          return (
            <>
              <GeneralData data={data} />
              <Card py="38px" px="35px">
                <Box
                  sx={{
                    '& tspan': {
                      fontWeight: 'medium',
fill: '#CEDBEF',
fontSize: '15px'
                    }
                  }}
                  mt="30px"
                  w="full"
                >
                  <ResponsiveContainer width="100%" height={370}>
                    <AreaChart
                      // margin={{ left: 25, right: 25, bottom: 30 }}
// margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                      data={prepareDataForGraph({
                        timeGapMode: selectedTimeGapMode,
                        data
                      })}
                    >
                      <defs>
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="var(--chakra-colors-primary-400)"
                              stopOpacity={1}
                            />
                            <stop
                              offset="95%"
                              stopColor="var(--chakra-colors-primary-400)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                      </defs>

                      <CartesianGrid stroke="#323B48" strokeDasharray="6" />

                      <XAxis
                        tickMargin={7}
                        minTickGap={10}
                        stroke="var(--border)"
                        dataKey="label"
                      />
                      <YAxis
                        allowDecimals={false}
                        tickMargin={7}
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) =>
                          value > 0
                            ? Intl.NumberFormat('en-US', {
                                notation: 'compact',
                                maximumFractionDigits: 1
                              }).format(value)
                            : ''
                        }
                      />

                      <Tooltip
                        cursor={{ stroke: 'var(--chakra-colors-primary-400)', strokeDasharray: '6' }}
                        content={
                          <CustomTooltip
                          mode={selectedTimeGapMode}
                          labels={labels}
                          withdrawals={withdrawals}
                            labelTextVariation={getTooltipLabelTextVariation(
                              section
                            )}
                          />
                        }
                      />
                      <Area
                        connectNulls
                        dot={false}
                        animationEasing="ease-in-out"
                        type="monotone"
                        dataKey="count"
                        stroke="#4285eb"
                        fillOpacity={0.3}
                        fill="url(#colorUv)"
                        strokeWidth={2}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
                <Text mt="20px">
                  Total: R${data.reduce((acc, cur) => acc + cur.integer, 0)}
                </Text>
              </Card>
            </>
          )
        }}
      </ServerContent>
    </>
  )
}
