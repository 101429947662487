
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import commonSlice from './common'
import userSlice from './user'
import accountsSlice from './accounts'
import proxiesSlice from './proxies'
const appReducer = combineReducers({
  common: commonSlice,
  user: userSlice,
  accounts: accountsSlice,
  proxies: proxiesSlice
})
// @ts-expect-error redux
const rootReducer = (state, action) => {
  if (action.type === 'user/logOutForRedux') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
const store = configureStore({
  reducer: rootReducer
})
export default store
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
