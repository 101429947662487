
import { useAppSelector } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import { selectAccounts } from 'store/accounts'
import { Button, Card, Center, Heading, Text } from '@chakra-ui/react'
import AccountActions from './AccountActions'
import getAccountStatusRegardingAdditionalData from 'helpers/getAccountStatusRegardingAdditionalData'
import Spinner from 'components/misc/Spinner'
import { useState } from 'react'
import LoadingContext from './context'
import { selectUser } from 'store/user'
import { selectCommonData } from 'store/common'
import { LoadingStatuses } from 'types/store'
import ChangeProxy from './ChangeProxy'
import AccountsTable from 'components/misc/AccountsTable'
export default function AccountPage () {
  const { id: accountId } = useParams()
  const user = useAppSelector(selectUser)
  const accounts = useAppSelector(selectAccounts)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const common = useAppSelector(selectCommonData)
  const minimumRobuxAmountForAccountActivity = common.data?.minimumRobuxAmountForAccountActivity || 200

  const account = accounts.data?.find((acc) => acc._id === accountId)
  if (!account) {
    if (accounts.status === LoadingStatuses.Success) {
      return (
            <Text variant="error">The account with this ID is not found.</Text>
      )
    } else {
      return <Center><Spinner/></Center>
    }
  }
  if (isLoading) return <Center><Spinner/></Center>

  const { isActive, balance, isBroken, isUsing2FA, isImproperlyConfigured } = account
  return (<>

          <LoadingContext.Provider value={{ setIsLoading }}>
            <Heading>
              <Text as="span" color="gray.400">
                Account
              </Text>{' '}
              @{account.username || account.lastUsername}
            </Heading>
            <Card mt="20px">
     <AccountsTable data={[account]} role={user.data?.role}/>
     </Card>
     <ChangeProxy currentProxy={account.proxy} accountId={account._id}/>
            <AccountActions balance={account.balance} cookie={account.cookie} _id={account._id} isActive={account.isActive} status={getAccountStatusRegardingAdditionalData({ isActive, isImproperlyConfigured, balance, isBroken, isUsing2FA, minimumRobuxAmountForAccountActivity })}/>
          </LoadingContext.Provider></>
  )
}
