import { OrderFailureReasons } from 'types/admin'
import { RobuxesDeliveryMethods } from 'types/economy'
import { AccountStatuses } from 'types/roblox'
export const baseDomain =
  process.env.NODE_ENV === 'production'
    ? 'https://api.rbx.cash'
    : 'http://localhost:3001'
export const baseURL =
  baseDomain + (process.env.NODE_ENV === 'production' ? '' : '/api')

export const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required.'

export const ADMIN_DISCORD_LINK =
  'https://discordapp.com/users/733642887748780043'

export const failureReasonsTexts = {
  [OrderFailureReasons.AccountNotFound]: 'The paying account was not found.',
  [OrderFailureReasons.CSRFTokenGenerationFailed]:
    'CSRF-token generation error has occured.',
  [OrderFailureReasons.GamePassNotForSale]: 'The Game Pass is not for sale.',
  [OrderFailureReasons.GamePassNotFound]: 'A Game Pass with such price was not found.',
  [OrderFailureReasons.InsufficientFunds]:
    'The paying account has got insufficient funds.',
  [OrderFailureReasons.InvalidUniverseId]: 'Invalid UniverseID.',
  [OrderFailureReasons.NoAccountsUsable]:
    'No accounts with sufficient balance were found to complete the order.',
  [OrderFailureReasons.AccountHas2FA]: 'The paying account has 2FA enabled.',
  [OrderFailureReasons.PluginNotForSale]: 'The plugin is not for sale.',
  [OrderFailureReasons.PluginNotFound]: 'A plugin with such price was not found.',
  [OrderFailureReasons.PluginNotFoundWhileDeleting]: 'The plugin was not found while deleting.',
  [OrderFailureReasons.GamePassNotFoundWhileDeleting]: 'The GamePass was not found while deleting.',
  [OrderFailureReasons.AccountImproperlyConfigured]: 'Account bad configuration'
}

export const robuxesDeliveryMethodsVisualData: Record<RobuxesDeliveryMethods, { name: string }> = {
  [RobuxesDeliveryMethods.GamePass]: {
    name: 'GamePass'
  },
  [RobuxesDeliveryMethods.Plugin]: {
    name: 'Fast'
  }
}

export const ACCOUNT_STATUSES_MESSAGES = {
  [AccountStatuses.Active]: 'Active',
  [AccountStatuses.Broken]: 'Error',
  [AccountStatuses.Disabled]: 'Disabled',
  [AccountStatuses.LowFunds]: 'Low funds',
  [AccountStatuses.Using2FA]: 'Account is using 2FA',
  [AccountStatuses.InvalidProxy]: 'Invalid or too slow proxy',
  [AccountStatuses.ImproperlyConfigured]: 'Improperly configured, check Roblox settings'
}

export const ACCOUNT_STATUSES_TAG_VARIANTS: Record<AccountStatuses, 'neutral' | 'positive' | 'negative' | 'pending'> = {
  [AccountStatuses.Active]: 'positive',
  [AccountStatuses.Broken]: 'negative',
  [AccountStatuses.Disabled]: 'negative',
  [AccountStatuses.LowFunds]: 'pending',
  [AccountStatuses.Using2FA]: 'negative',
  [AccountStatuses.InvalidProxy]: 'negative',
  [AccountStatuses.ImproperlyConfigured]: 'negative'

}