import { type TypedAxiosError } from 'types/utils'
import toast from 'react-hot-toast'
export default function displayServerError (e: any) {
  const error = e as TypedAxiosError
  const response = error.response
  if (response?.data.message) {
    toast.error(response.data.message)
  } else {
    toast.error(error.message)
  }
}
