import RequestWithdrawal from './RequestWithdrawal'
import Content from 'components/misc/Content'
import useAxios from 'hooks/useAxios'
import WithdrawalsHistory from './WithdrawalsHistory'
import { type IWithdrawal } from 'types/economy'

export default function Withdrawals () {
  const withdrawals = useAxios<IWithdrawal[]>('/users/withdrawals')
  const addWithdrawal = (withdrawal: IWithdrawal) => {
    if (!withdrawals.data) return
    const clonedData = [...withdrawals.data]
    withdrawals.setData([...clonedData, withdrawal])
  }
  const deleteWithdrawal = (_id: IWithdrawal['_id']) => {
    if (!withdrawals.data) return
    const clonedData = [...withdrawals.data]
    const withdrawalIndex = clonedData.findIndex(
      (withdrawal) => withdrawal._id === _id
    )
    if (withdrawalIndex === -1) return
    clonedData.splice(withdrawalIndex, 1)
    withdrawals.setData(clonedData)
  }
  return (
    <Content status={withdrawals.status} error={withdrawals.error}>
      {() => (
        <>
          <RequestWithdrawal addWithdrawal={addWithdrawal} />
          <WithdrawalsHistory
            deleteWithdrawal={deleteWithdrawal}
            data={[...(withdrawals.data || [])].reverse()}
          />
        </>
      )}
    </Content>
  )
}
