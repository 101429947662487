import { type ChakraProps, type MenuButtonProps, MenuList, type ThemingProps } from '@chakra-ui/react'
import Menu, { MenuItem, MenuButton } from 'components/misc/Menu'
import { useState } from 'react'

interface Props {
  placeholder?: string
  value?: string
  height?: 'normal' | 'sm'
  onChange: (newValue: string) => void
  children?: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >
}
const getOptions = function iter (options: any[]): string[] {
  const values: string[] = []

  const f = (option: any) => {
    if (Array.isArray(option)) {
      option.forEach(f)
    } else {
      values.push(option)
    }
  }
  options.forEach(f)
  return values
}
export function Select ({
  placeholder,
  value: controlledValue,
  onChange,
  height,
  children: rawOptions,
  variant,
  ...props
}: Props & Omit<MenuButtonProps, 'onChange'> & ThemingProps) {
  const options = getOptions(
    Array.isArray(rawOptions) ? rawOptions : [rawOptions]
  )
  /* @ts-expect-error what */
  const values = options.map((option) => option?.props.value)

  const [value, setValue] = useState<(typeof values)[number]>(
    !placeholder ? values[0] : ''
  )

  if (!rawOptions) {
    return placeholder
? (
      <Menu>
        <></>
        <MenuButton disabled w="full">
          {placeholder}
        </MenuButton>
      </Menu>
    )
: null
  }

  const mixedValue = controlledValue || value
  const selectedOption = options.find(
    /* @ts-expect-error what */
    (option) => option.props.value === mixedValue
  )
  return (
    <>
      <Menu variant={variant}>
        <MenuButton w="full" h={height === 'sm' ? '39px!important' : undefined} variant={variant} {...props}>
          {/* @ts-expect-error what */}
          {selectedOption?.props.children || placeholder}
        </MenuButton>
        <MenuList minW="unset" overflowY="auto" maxH="400px">
          {options.map((option, i) => {
            /* @ts-expect-error what */
            const isSelected = option.props.value === mixedValue
            return (
              <MenuItem
                key={i}
                lineHeight="normal"
                whiteSpace="unset"
                data-selected={isSelected ? true : undefined}
                disabled={isSelected}
                onClick={
                  !isSelected
                    ? () => {
                        /* @ts-expect-error what */
                        setValue(option.props.value)
                        /* @ts-expect-error what */
                        onChange(option.props.value)
                      }
                    : undefined
                }
              >
                {/* @ts-expect-error what */}
                {option.props.children}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}
