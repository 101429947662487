import Content from 'components/misc/Content'
import { FormLabel, Text, Spinner, HStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { selectProxies } from 'store/proxies'
import instance from 'instances/axios'
import { type IRobloxAccount, type IProxy } from 'types/roblox'
import { useState } from 'react'
import { setAccountProxy } from 'store/accounts'
import { toast } from 'react-hot-toast'
import { Select } from 'components/form/Select'
interface Props {
  currentProxy: IRobloxAccount['proxy']
  accountId: IRobloxAccount['_id']
}
export default function ChangeProxy ({ currentProxy, accountId }: Props) {
  const [currentValue, setCurrentValue] = useState<
  IRobloxAccount['proxy']
  >(currentProxy)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const changeProxy = async (_id: IProxy['_id']) => {
    setIsLoading(true)
    try {
      await instance.put('/accounts/proxy', { id: _id, accountId })
      toast.success('The proxy has been changed.')
      dispatch(setAccountProxy([accountId, _id]))
    } catch {
      setCurrentValue(currentProxy)
    }
    setIsLoading(false)
  }
  const { data, status, error } = useAppSelector(selectProxies)
  return (
    <Content status={status} error={error}>
      {() => (
        <>
          <Text mt="20px" mb="5px">
            Set the proxy
          </Text>
          {(data?.length as number) > 0
            ? (
            <HStack>
              <Select
              w="fit-content"
                name="account_proxy"
                disabled={isLoading}
                height="sm"
                onChange={(newValue) => {
                  setCurrentValue(newValue)
                  changeProxy(newValue)
                }}
                value={currentValue}
                key={currentValue}
              >
                <option style={{ background: 'var(--root-bg)', color: '#fff' }} value="">None</option>
                {data?.filter(proxy => proxy.isEnabled).map((proxy) => (
                  <option style={{ background: 'var(--root-bg)', color: '#fff' }} key={proxy._id} value={proxy._id}>
                    {proxy.host}:{proxy.port}
                  </option>
                ))}
              </Select>
              {isLoading && <Spinner size="sm" />}
            </HStack>
              )
            : (
            <Text variant="ghost">You haven&apos;t added proxies yet.</Text>
              )}
        </>
      )}
    </Content>
  )
}
