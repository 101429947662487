import React, { useState, type ReactNode } from 'react'
import { Button, IconButton } from '@chakra-ui/react'

type IconButtonProps = {
  isIcon: true
  'aria-label': string
} | { isIcon?: false }

type Props = IconButtonProps & {
  onClick: (event?: React.MouseEvent) => Promise<void>
  children?: ReactNode | ReactNode[]
  [key: string]: any
}
export default function AwaitingButton ({ onClick, isIcon = false, children, 'aria-label': ariaLabel, ...props }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const Component = isIcon ? IconButton : Button
  return (
    <Component
      isLoading={isLoading}
      aria-label={ariaLabel}
      onClick={async (event) => {
        setIsLoading(true)
        await onClick(event)
        setIsLoading(false)
      }}
      {...props}
    >{children}</Component>
  )
}
