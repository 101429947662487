import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import instance from 'instances/axios'

import { type IRobloxAccountDataForAdmin, type RobloxAccountWithApiData } from 'types/roblox'
import { LoadingStatuses } from 'types/store'
import { UserRoles } from 'types/users'

export interface RobloxAccountsState {
  status: LoadingStatuses
  error: string | null
  data: Array<RobloxAccountWithApiData & Partial<IRobloxAccountDataForAdmin>>
}
const initialState: RobloxAccountsState = {
  status: LoadingStatuses.Idle,
  error: null,
  data: []
}

export const fetchAccountsData = createAsyncThunk(
  'accounts/fetch',
  async (role: UserRoles) => {
    const response = await instance.get(role === UserRoles.Admin ? '/admin/all-accounts' : '/accounts/my')

    return response.data
  }
)
const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsData: (state, action) => {
      state.data = action.payload
    },
    addAccount: (state, action) => {
      if (!state.data) return
      state.data.push(action.payload)
    },
    addAccounts: (state, action) => {
      if (!state.data) return
      state.data.push(...action.payload)
    },
    // startAccountsDataReception: (state) => {
    //   state.status = LoadingStatuses.Loading
    // },
    // finishAccountsDataReception: (state) => {
    //   state.status = LoadingStatuses.Success
    // },
    toggleAccountStatus: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === _id
      )
      if (accountIndex === -1) return
      state.data[accountIndex].isActive = !state.data[accountIndex].isActive
    },
    deleteAccount: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === _id
      )
      if (accountIndex === -1) return
      state.data.splice(accountIndex, 1)
    },
    deleteAccounts: (state, action) => {
      if (!state.data) return
      const ids = action.payload
      state.data = state.data.filter((account) => !ids.includes(account._id))
    },
    toggleAccounts: (state, action: { payload: [string[], 'enable' | 'disable'] }) => {
      if (!state.data) return
      const [ids, type] = action.payload
      // @ts-expect-error what
      state.data = state.data.map(account => ids.includes(account._id) ? { ...account, isActive: type === 'enable', ...(type === 'enable' ? { isBroken: false, isImproperlyConfigured: false, isUsing2FA: false, isInvalidProxy: undefined } : {}) } : account)
    },
    changeAccountCookie: (state, action) => {
      if (!state.data) return
      const [_id, newCookie] = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === _id
      )
      if (accountIndex === -1) return
      state.data[accountIndex].cookie = newCookie
    },
    setAccountData: (state, action) => {
      if (!state.data) return
      const [_id, newData] = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === _id
      )
      if (accountIndex === -1) return
      state.data[accountIndex] = newData
    },
    disableUsing2FAStatus: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === _id
      )
      if (accountIndex === -1) return
      state.data[accountIndex].isUsing2FA = false
    },
    setAccountProxy: (state, action) => {
      if (!state.data) return
      const [accountId, proxyId] = action.payload
      const accountIndex = state.data.findIndex(
        (account) => account._id === accountId
      )
      if (accountIndex === -1) return
      state.data[accountIndex].proxy = proxyId
    },
    deleteProxyFromAccounts: (state, action) => {
      if (!state.data) return
      const _id = action.payload
      const accountsWithProxyIndexes = state.data.map((account, i) => account.proxy === _id ? i : -1).filter(i => i > -1)
      for (const i of accountsWithProxyIndexes) {
        delete state.data[i].proxy
      }
    }
  },
  extraReducers (builder) {
    builder
      .addCase(fetchAccountsData.pending, (state) => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(fetchAccountsData.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Success
        state.data = action.payload
      })
      .addCase(fetchAccountsData.rejected, (state, action) => {
        state.status = LoadingStatuses.Error
        state.error = action.error.message || null
      })
  }
})
export default slice.reducer
export const {
  setAccountsData,
  addAccounts,
  toggleAccounts,
  deleteAccount,
  addAccount,
  toggleAccountStatus,
  changeAccountCookie,
  setAccountData,
  deleteProxyFromAccounts,
  disableUsing2FAStatus,
  deleteAccounts,
  setAccountProxy
} = slice.actions
export * from './selectors'
