import PasswordInput from './PasswordInput'
import { type FieldInputProps } from 'formik'
import {
  Input as ChakraInput,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Textarea,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react'
import { type LegacyRef } from 'react'
interface FieldConfig {
  label?: string
  placeholder?: string
  type?: string
  accept?: string
  inputRef?: LegacyRef<HTMLInputElement>
  errorMessage?: string
  isInvalid?: boolean
  field?: FieldInputProps<string>
  name?: string
  icon?: JSX.Element
  onCustomChange?: (arg0?: any) => any
  [key: string]: any
}
export default function FormInput ({
  label,
  placeholder,
  type,
  accept,
  inputRef,
  onCustomChange = () => {},
  errorMessage,
  isInvalid,
  field,
  name,
  ...props
}: FieldConfig) {
  return (
    <FormControl mb="25px" _last={{ mb: 0 }} isInvalid={isInvalid} {...props}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {type !== 'password'
        ? (
            type === 'textarea'
              ? (
          <Textarea
            {...field}
            name={name}
            placeholder={placeholder}
            onChange={(event: any) => {
              field?.onChange(event)
              onCustomChange(event)
            }}
            {...props}
          />
                )
              : (
          <InputGroup>
            {props.icon && <InputLeftElement>{props.icon}</InputLeftElement>}
            <ChakraInput
              {...field}
              name={name}
              placeholder={placeholder}
              type={type}
              accept={accept}
              onChange={(event: any) => {
                field?.onChange(event)
                onCustomChange(event)
              }}
              ref={inputRef}
              {...props}
            />
          </InputGroup>
                )
          )
        : (
        <PasswordInput
          {...props}
          {...field}
          name={name}
          placeholder={placeholder}
          type={type}
          accept={accept}
          onChange={(event: any) => {
            field?.onChange(event)
            onCustomChange(event)
          }}
          ref={inputRef}
        />
          )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
