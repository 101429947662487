import SalesByMerchants from './SalesByMerchants'
import { StatisticsSections, type ISalesStatisticsResponse } from 'types/economy'
// import SelectViewPeriod from './SelectViewPeriod'
// import moment, { type Moment } from 'moment'
import { useAppSelector } from 'hooks/redux'
// import useAxios from 'hooks/useAxios'
import { useMemo } from 'react'
import { selectUser } from 'store/user'
import { UserRoles } from 'types/users'
// import StatisticsGraph from './StatisticsGraph'
import {
  // Box,
  Text,
  // Card,
  Heading
  // useMediaQuery
} from '@chakra-ui/react'
import getTimeZone from 'helpers/getTimeZone'
// import GeneralData from './GeneralData'
import CommonData from './CommonData'
import { StatisticsGraph } from './Statistics/StatisticsGraph'
import ServerContent from 'components/misc/ServerContent'

export const adjustBonusWithdrawals = (data: ISalesStatisticsResponse['bonusRobuxesWithdrawals']) => data.map(w => ({ ...w, paidAmountInDollars: 0, merchantRevenue: 0 }))

// function processData ({
//   data,
//   mode,
//   particularDate,
//   sortType
// }: {
//   data: ISalesStatisticsResponse | null
//   mode: StatisticsDateGrabbingModes
//   particularDate?: Date
//   sortType: StatisticsSortTypes
// }) {
//   if (!data || (data.bonusRobuxesWithdrawals.length === 0 && data.orders.length === 0)) return
//   let timeMeasure: Extract<keyof Moment, 'month' | 'year' | 'hour' | 'day' | 'date'>
//   switch (mode) {
//     case StatisticsDateGrabbingModes.Day:
//       timeMeasure = 'hour'
//       break
//     case StatisticsDateGrabbingModes.Week:
//       timeMeasure = 'day'
//       break
//     case StatisticsDateGrabbingModes.Month:
//       timeMeasure = 'date'
//       break
//     case StatisticsDateGrabbingModes.AllTime:
//       if ([moment().diff(moment(data.orders[0].completedAt), 'months'), moment().diff(moment(data.bonusRobuxesWithdrawals[0].completedAt), 'months')].some(val => val < 11)) {
//         timeMeasure = 'month'
//       } else {
//         timeMeasure = 'year'
//       }
//       break
//     default:
//       timeMeasure = 'hour'
//   }
//   const getAmountsByMeasures = (data: ISalesStatisticsResponse['orders'] | Array<ISalesStatisticsResponse['bonusRobuxesWithdrawals'][number] & { merchantRevenue: 0, paidAmountInDollars: 0 }>) => {
//     const amountsByMeasures: IStatisticsRecord[] = []

//     const currentMeasure = moment()[timeMeasure]()

//     switch (mode) {
//       case StatisticsDateGrabbingModes.Day:
//         for (let i = 0; i <= (particularDate ? 23 : currentMeasure); i++) {
//           amountsByMeasures.push({ amount: 0, measure: i })
//         }
//         break
//       case StatisticsDateGrabbingModes.Week:

//         let j = 0
//         for (let i = moment().day(); i >= 0; i--) {
//           amountsByMeasures.unshift({ amount: 0, measure: currentMeasure - j })
//           j += 1
//         }
//         break
//       case StatisticsDateGrabbingModes.AllTime:
//         const clonedOrders = [...data]
//         const appearedMeasures: Array<(typeof amountsByMeasures)[number]['measure']> = []
//         for (const item of data) {
//           let currentMeasure: (typeof appearedMeasures)[number] = moment(item.completedAt)[timeMeasure]()
//           if (timeMeasure === 'month') {
//             currentMeasure = moment().month(currentMeasure).format('MMMM')
//           }
//           if (!appearedMeasures.includes(currentMeasure)) {
//             appearedMeasures.push(currentMeasure)
//           }
//           clonedOrders.shift()
//         }
//         amountsByMeasures.push(...appearedMeasures.map(monthOrYear => ({ amount: 0, measure: monthOrYear })))
//         break
//       default:
//         for (let i = 1; i <= currentMeasure; i++) {
//           amountsByMeasures.push({ amount: 0, measure: i })
//         }
//     }
//     let relevantFieldToSum: keyof (typeof data)[number]
//     switch (sortType) {
//       case StatisticsSortTypes.Dollars:
//         relevantFieldToSum = 'paidAmountInDollars'
//         break
//       case StatisticsSortTypes.Revenue:
//         relevantFieldToSum = 'merchantRevenue'
//         break
//       case StatisticsSortTypes.Robuxes:
//       case StatisticsSortTypes.Admin:
//         relevantFieldToSum = 'robuxesToSend'
//         break
//     }
//     for (const item of data) {
//       // @ts-expect-error Moment js poor typings
//       let measure = moment(item.completedAt)[timeMeasure as keyof Moment]()
//       if (timeMeasure === 'month') {
//         measure = moment().month(measure).format('MMMM')
//       }
//       const index = amountsByMeasures.findIndex(
//         (amountObj) => amountObj.measure === measure
//       )
//       const calculatedAmount = item[relevantFieldToSum] || 0
//       if (index > -1) {
//         amountsByMeasures[index].amount += calculatedAmount
//       }
//     }

//     return amountsByMeasures
//   }
//   return { orders: getAmountsByMeasures(data.orders), bonusRobuxesWithdrawals: getAmountsByMeasures(adjustBonusWithdrawals(data.bonusRobuxesWithdrawals)) }
// }

export default function DashboardPage () {
  const user = useAppSelector(selectUser)
  return (
    <>
    <Heading>Dashboard</Heading>
      <ServerContent {...user}>
        {(data) => (
          <>
            {data?.role === UserRoles.Merchant
              ? (
              <>
                <StatisticsGraph section={StatisticsSections.Revenue}/>

              </>
                )
              : (
              <>

                <StatisticsGraph
                  section={StatisticsSections.Admin}
                />
                <SalesByMerchants />
              </>
                )}
          </>
        )}
      </ServerContent>
      <CommonData/>
      {/* <Text color="gray.700" mt="40px">{new Date().toLocaleString('en-US', { timeZone })}</Text> */}
    </>
  )
}
