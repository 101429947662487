import { type IUser } from './users'

export interface IRobloxAccount {
  _id: string
  owner: string
  cookie: string
  lastUsername: string
  price: number
  spentAmount: number
  isActive: boolean
  isPreferred: boolean
  isUsing2FA: boolean
  isImproperlyConfigured: boolean
  proxy?: string
  createdAt: Date
  robloxId: number
}

export type IRobloxApiData = {
  isBroken: false
  balance: number
  username: string
  isInvalidProxy?: never
} | { isBroken: true, balance?: number, username?: string, isUsing2FA?: boolean, isImproperlyConfigured?: boolean, isInvalidProxy: boolean }
export interface IRobloxAccountDataForAdmin {
  merchantUsername: IUser['username']
}
export type RobloxAccountWithApiData = IRobloxAccount &
IRobloxApiData

export enum AccountStatuses {
  Active,
  Disabled,
  Broken,
  LowFunds,
  Using2FA,
  InvalidProxy,
  ImproperlyConfigured
}
export interface IProxy {
  _id: string
  isEnabled: boolean
  host: string
  port: number
  username?: string
  password?: string
  owner: string
}
