import { Box, Button, type ButtonProps, HStack, Icon, type StackProps } from '@chakra-ui/react'
import { Select } from 'components/form/Select'

import { type LegacyRef, useState, forwardRef } from 'react'
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker'
import { dayjs } from 'instances/dayjs'
import { StatisticsTimeGapModes } from 'types/economy'
import { CalendarIcon } from 'assets/icons'

interface Props {
  onTimeGapChange: (newValue: StatisticsTimeGapModes) => void
  onDateChange: (newValue: Date | null) => void
}
const statisticsTimeGapsTranscriptsInAccusativeCases: Record<
StatisticsTimeGapModes,
string
> = {
  [StatisticsTimeGapModes.Day]: 'Day',
  [StatisticsTimeGapModes.Month]: 'Month',
  [StatisticsTimeGapModes.Week]: 'Week',
  [StatisticsTimeGapModes.Year]: 'Year',
  [StatisticsTimeGapModes.AllTime]: 'All time'
}
const CustomDateInput = forwardRef(function CustomDateInput ({
  value,
  onClick,
  ref
}: ButtonProps & { ref: LegacyRef<HTMLButtonElement> }) {
  return (
<Button py="11px" fontSize="15px!important" fontWeight="normal" px="16px" color="#8792A2" leftIcon={<Icon color="#96A0AF" as={CalendarIcon} boxSize="17px"/>} variant="ghost" bg="#1F2834" onClick={onClick} ref={ref}>
{value}
</Button>
  )
})
export function TimeGapSelection ({ onTimeGapChange, onDateChange, ...props }: Props & StackProps) {
  const [timeGap, setTimeGap] = useState(StatisticsTimeGapModes.Day)
  const [date, setDate] = useState<Date | null>(new Date())
  let datePickerProps: Partial<ReactDatePickerProps> = {}
  const startD = dayjs(date || Date.now())
    .startOf('week')
    .toDate()
  const endD = dayjs(date || Date.now())
    .endOf('week')
    .toDate()

  switch (timeGap) {
    case StatisticsTimeGapModes.Year:
      datePickerProps = { showYearPicker: true, dateFormat: 'yyyy' }
      break
    case StatisticsTimeGapModes.Month:
      datePickerProps = { showMonthYearPicker: true, dateFormat: 'MM/yyyy' }
      break
    case StatisticsTimeGapModes.Week:
      datePickerProps = {
        selectsStart: true,
        startDate: startD,
        endDate: endD,
        selected: startD
      }
      break
  }
  return (
<HStack gap="10px" mt="10px" {...props}>

          <Select
            w="fit-content"
            variant="blue"
            onChange={(newValue) => {
              setTimeGap(+newValue)
              onTimeGapChange((+newValue) as StatisticsTimeGapModes)
            }}
          >
            {Object.values(StatisticsTimeGapModes)
              .filter((value) => !isNaN(+value))
              .map((timeGap, i) => (
                <option key={i} value={timeGap}>
                  {' '}
                  {
                    statisticsTimeGapsTranscriptsInAccusativeCases[
                      timeGap as keyof typeof statisticsTimeGapsTranscriptsInAccusativeCases
                    ]
                  }
                </option>
              ))}
          </Select>
          {timeGap !== StatisticsTimeGapModes.AllTime && (
            <Box>
              <ReactDatePicker
                customInput={<CustomDateInput />}
                disabledKeyboardNavigation
                selected={date}
                locale="en"
                onChange={(date) => {
                  setDate(date)
                  onDateChange(date)
                }}
                {...datePickerProps}
              />

              <Box display="none">
                <ReactDatePicker
                  selectsEnd
                  onChange={() => {}}
                  selected={endD}
                  startDate={startD}
                  endDate={endD}
                />
              </Box>
            </Box>
          )}
        </HStack>
  )
}
