import validator from 'validator'
import { Heading, Button, Box, HStack } from '@chakra-ui/react'
import FormikInput from 'components/form/FormikInput'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../../../constants'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Link from 'components/misc/Link'
import instance from 'instances/axios'
import { toast } from 'react-hot-toast'
import setUpLoginAndSetTokens from 'helpers/setUpLogin'
const schema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'You need to enter at least 3 characters.')
    .max(18, 'You need to enter at most 18 characters.')
    .required(REQUIRED_FIELD_ERROR_MESSAGE),
  discordUsername: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  password: Yup.string().test(
    'password-strong',
    'Your password must be at least 8 symbols, include a lowercase and an uppercase letters, at least 1 digit and 1 special symbol.',
    (value) => validator.isStrongPassword(value || '')
  ).required(REQUIRED_FIELD_ERROR_MESSAGE)
})
export default function RegisterPage () {
  return (
    <Box mx="auto" w="full" maxW="500px">
      <Heading mb="10px">Sign Up</Heading>
      <Formik
        validationSchema={schema}
        initialValues={{ username: '', email: '', password: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const response = await instance.post('/auth/register', values)
          toast.success('You have been successfully logged in.')
          const tokens = response.data.tokens
          setUpLoginAndSetTokens(tokens)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <fieldset disabled={isSubmitting}>
            <FormikInput
              name="username"
              placeholder="panel_username"
              label="Username"
            />
            <FormikInput name="discordUsername" placeholder="my_discord" label="Discord username"/>
            <FormikInput
              name="password"
              type="password"
              placeholder="********"
              label="Password"
            />
            </fieldset>
            <HStack mt="20px" w="full" justify="space-between" align="center">
              <Button type="submit" isLoading={isSubmitting} isDisabled={!isValid || !dirty}>
                Submit
              </Button>
              <Link href="/login">Sign In</Link>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
