import instance from 'instances/axios'
import { useContext, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Button,
  Icon,
  ModalCloseButton
} from '@chakra-ui/react'
import FormInput from 'components/form/FormInput'
import { type IRobloxAccount } from 'types/roblox'
import { toast } from 'react-hot-toast'
import { useAppDispatch } from 'hooks/redux'
import getAccountData from 'helpers/getAccountData'
import { changeAccountCookie, setAccountData } from 'store/accounts'
import LoadingContext from './context'
import AwaitingButton from 'components/misc/AwaitingButton'
import { PenIcon } from 'assets/icons'
interface Props {
  cookie: IRobloxAccount['cookie']
  _id: IRobloxAccount['_id']
}
export default function EditCookie ({ cookie, _id }: Props) {
  const { setIsLoading } = useContext<{
    setIsLoading: (value: boolean) => void
  }>(LoadingContext)
  const dispatch = useAppDispatch()
  const onSubmit = async () => {
    try {
      await instance.put('/accounts/cookie', { newCookie: cookieValue, id: _id })
      toast.success('The cookie has been successfully edited.')
      dispatch(changeAccountCookie([_id, cookieValue]))
      setIsLoading(true)
      const refreshedAccountData = await getAccountData(_id)
      setIsLoading(false)
      dispatch(setAccountData([_id, refreshedAccountData]))
    } catch {}
  }
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [cookieValue, setCookieValue] = useState<string>(cookie)
  return (
    <>
      <Button w="fit-content" onClick={onOpen} leftIcon={<Icon boxSize="17px" as={PenIcon}/>}>Edit cookie</Button>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Edit Cookie</ModalHeader>
          <ModalBody>
            <FormInput
              type="textarea"
              defaultValue={cookieValue}
              placeholder="XXXXXXXXXXXXXX"
              label="New cookie"
              onCustomChange={(event) => {
                setCookieValue(event.target.value)
              }}
              mb={0}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <AwaitingButton onClick={onSubmit}>Continue</AwaitingButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
