import EditCookie from './EditCookie'
import instance from 'instances/axios'
import { Button, Card, Wrap, WrapItem } from '@chakra-ui/react'
import { useAppDispatch } from 'hooks/redux'
import { AccountStatuses, type RobloxAccountWithApiData } from 'types/roblox'
import {
  toggleAccountStatus,
  deleteAccount as deleteAccountFromState,
  disableUsing2FAStatus,
  setAccountData
} from 'store/accounts'
import Confirmation from 'components/misc/Confirmation'
import LoadingContext from './context'
import { useContext } from 'react'
import getAccountData from 'helpers/getAccountData'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import Link from 'components/misc/Link'
import AwaitingButton from 'components/misc/AwaitingButton'

interface Props {
  _id: RobloxAccountWithApiData['_id']
  status: AccountStatuses
  balance: RobloxAccountWithApiData['balance']
  cookie: RobloxAccountWithApiData['cookie']
  isActive: RobloxAccountWithApiData['isActive']
}
export default function AccountActions ({ _id, isActive, status, cookie }: Props) {
  const dispatch = useAppDispatch()
  const { setIsLoading } = useContext<{
    setIsLoading: (value: boolean) => void
  }>(LoadingContext)
  const navigate = useNavigate()
  const toggleAccountActiveStatus = async () => {
    try {
      await instance.put('/accounts/toggle', { id: _id }); dispatch(toggleAccountStatus(_id))
      if (status === AccountStatuses.Using2FA) {
        dispatch(disableUsing2FAStatus(_id))
      }
      setIsLoading(true)
      const refreshedAccountData = await getAccountData(_id)
      setIsLoading(false)
      toast.success(`The account has been ${refreshedAccountData.isActive ? 'enabled' : 'disabled'}.`)
      dispatch(setAccountData([_id, refreshedAccountData]))
    } catch {}
  }
  const deleteAccount = async () => {
    try {
      await instance.delete('/accounts/account', { params: { id: _id } })
      dispatch(deleteAccountFromState(_id))
      toast.success('The account has been deleted.')
      navigate('/accounts')
    } catch {}
  }

  return (
    <>
        <Wrap gap="10px" mt="15px"><WrapItem><EditCookie cookie={cookie} _id={_id}/></WrapItem>

        <WrapItem><AwaitingButton
  w="fit-content"
  onClick={toggleAccountActiveStatus}
  variant={isActive ? 'negative' : 'outline'}
  isDisabled={![AccountStatuses.Active, AccountStatuses.Disabled].includes(status)}
>
  {isActive ? 'Disable' : 'Enable'}
</AwaitingButton></WrapItem>
{status === AccountStatuses.Using2FA && <WrapItem><Link isExternal href="https://www.roblox.com/my/account#!/security"><Button>Fix 2FA error</Button></Link></WrapItem>}
        <WrapItem><Confirmation>
          <Button variant="negative" onClick={deleteAccount}>Delete account</Button>
        </Confirmation></WrapItem></Wrap>

    </>
  )
}
