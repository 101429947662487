import { selectUser, setUserVerified } from 'store/user'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { Button, Icon, Heading, Text, Code, HStack } from '@chakra-ui/react'
import { DiscordIcon } from 'assets/icons'
import FormInput from 'components/form/FormInput'
import instance from 'instances/axios'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import Link from 'components/misc/Link'
import { ADMIN_DISCORD_LINK } from '../../constants'
import Content from 'components/misc/Content'
import { UserVerificationStatuses } from 'types/users'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import ServerContent from 'components/misc/ServerContent'
export default function AccountVerification () {
  const [token, setToken] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const onSubmit = async () => {
    try {
      await instance.post('/auth/verify-account', { token })
      toast.success("You've successfully verified your account.")
      dispatch(setUserVerified())
    } catch {}
  }
  const verificationTokenRequest = useAxios('/auth/verification-token')
  return (
    <Content status={user.status} error={user.error}>
      {() => <>{user.data?.verificationStatus === UserVerificationStatuses.Rejected
        ? (
        <Text color="red.400" fontSize="lg">Your verification request has been rejected.</Text>
          )
        : (
        <>
          <Heading>Account Verification</Heading>
          <Text my={2}>
            In order to start trading, you need to verify your Discord account. Join our server and send this command to the <Text as="span" color="gray.200">#bot-commands</Text> channel:
          </Text>
          <ServerContent {...verificationTokenRequest}>{(data)=><Code my={1} onClick={()=>{navigator.clipboard.writeText(`/verify given-code:${data.token}`);toast.success('Copied!')}}>/verify given-code:{data.token}</Code>}</ServerContent>
          <Text my={2}>Then, paste the code you received on the server to the <Text as="span" color="gray.200">text field below</Text>.</Text>
          <FormInput mb="10px"
            placeholder="123XYZ"
            label="Your token"
            onCustomChange={(e) => {
              setToken(e.target.value)
            }}
          />
          <HStack><Button isDisabled={!token} onClick={onSubmit}>Submit</Button>
          <Link isExternal href="https://discord.gg/gF58hY3NrV">
            <Button variant="ghost"
              leftIcon={
                <Icon mr="2px" as={DiscordIcon} h="1.2rem" w="1.3rem" color="#fff" />
              }
            >
              Join the server
            </Button>
          </Link></HStack>
        </>
          )}</>}
    </Content>
  )
}
