import displayServerError from '../helpers/displayServerError'
import logOut from '../helpers/logOut'
import refreshAuthTokens from '../helpers/tokens/refresh'
import { baseURL } from '../constants'
import axios from 'axios'
const instance = axios.create({ baseURL })
export function setAuthToken (token: string) {
  instance.defaults.headers.common.Authorization = 'Bearer ' + token
}
instance.interceptors.request.use((config) => {
  config.headers['request-startTime'] = Date.now()
  return config
})
instance.interceptors.response.use(
  (response) => {
    const start = response.config.headers['request-startTime']
    const end = Date.now()
    const milliseconds = end - start
    response.headers['request-duration'] = milliseconds
    return response
  },
  async (error) => {
    if (error.response.status === 401 && error.response.data.message === 'INVALID_ACCESS_TOKEN') {
      try {
        const { access } = await refreshAuthTokens()
        setAuthToken(access)
        if (window.socket) window.socket.disconnect()
        // setupSocket(access)
        error.config.headers.Authorization = 'Bearer ' + access

        return await instance.request(error.config).catch(() => { logOut() })
      } catch {
        logOut()
      }
    } else {
      displayServerError(error)
      throw error.response
    }
  }
)

export default instance
