import validator from "validator";
import {
  Button,
  Code,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseDisclosureReturn,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import AwaitingButton from "components/misc/AwaitingButton";
import setUpLoginAndSetTokens from "helpers/setUpLogin";
import instance from "instances/axios";
import { dayjs } from "instances/dayjs";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { type AuthTokens } from "types/auth";
enum Sections {
  Username,
  BotCommand,
  Token,
  NewPassword,
}
export default function ResetPasswordModal({
  isOpen,
  onClose,
}: Pick<UseDisclosureReturn, "isOpen" | "onClose">) {
  const [username, setUsername] = useState("");
  const [secondToken, setSecondToken] = useState("");
  const [password, setPassword] = useState("");
  const [section, setSection] = useState(Sections.Username);
  const [firstToken, setFirstToken] = useState<string | null>(null);
  const [nextResendTimestamp, setNextResendTimestamp] = useState<Date | null>(
    null
  );
  const sendCode = async () => {
    try {
      const result = await instance.post<{
        lastSentAt?: string;
        token: string;
      }>("/auth/send-reset-password-token", { username });
      const { lastSentAt, token } = result.data;
      setFirstToken(token);
      if (lastSentAt) {
        setNextResendTimestamp(dayjs(lastSentAt).add(5, "minutes").toDate());
      } else {
        setNextResendTimestamp(dayjs().add(5, "minutes").toDate());

        toast.success("A verification code has been sent to your Discord DMs.");
      }

      setSection(Sections.BotCommand);
    } catch {}
  };
  const checkToken = async () => {
    try {
      const result = await instance.post<{ isValid: boolean }>(
        "/auth/check-reset-password-token",
        { token: secondToken }
      );
      if (!result.data.isValid) {
        toast.error("Invalid token.");
        throw new Error("Invalid token");
      }

      setSection(Sections.NewPassword);
    } catch {}
  };
  const updatePassword = async () => {
    try {
      const result = await instance.post<{ tokens: AuthTokens }>(
        "/auth/update-password",
        { token: secondToken, newPassword: password }
      );
      toast.success("Your password was successfully changed.");
      setUpLoginAndSetTokens(result.data.tokens);
    } catch (e: any) {
      if (e.response?.data.code === "VERIFICATION_TOKEN_EXPIRED") {
        setSection(Sections.NewPassword);
        setPassword("");
        setFirstToken(null);
        setSecondToken("");
      }
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Reset password</ModalHeader>
        <ModalBody>
          {
            {
              [Sections.Username]: (
                <>
                  <FormInput
                    value={username}
                    placeholder="roblox_merchant"
                    label="Your RBX.CASH username"
                    onCustomChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />

                  <HStack>
                    <Button variant="ghost" onClick={onClose}>
                      Cancel
                    </Button>
                    <AwaitingButton
                      isDisabled={!username}
                      onClick={async () => {
                        await sendCode();
                      }}
                    >
                      Continue
                    </AwaitingButton>
                  </HStack>
                </>
              ),
              [Sections.BotCommand]: (
                <>
                  <Text my={2}>
                    Send this command to the{" "}
                    <Text as="span" color="gray.200">
                      #bot
                    </Text>{" "}
                    channel:
                  </Text>
                  <Code my={1} onClick={()=>{navigator.clipboard.writeText(`/reset-pass given-code:${firstToken}`);toast.success('Copied!')}}>/reset-pass given-code:{firstToken}</Code>
                  <Text mt={2} mb={3}>
                    Then, paste the code you received on the server on  <Text as="span" color="gray.200">the next
                    page</Text>.
                  </Text>
                  <HStack>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setSection(Sections.Username);
                      }}
                    >
                      Back
                    </Button>
                    <Button onClick={() => setSection(Sections.Token)}>
                      Continue
                    </Button>
                  </HStack>
                </>
              ),
              [Sections.Token]: (
                <>
                  <FormInput
                    value={secondToken}
                    placeholder="******"
                    label="Verification code"
                    onCustomChange={(e) => {
                      setSecondToken(e.target.value);
                    }}
                    mb="5px"
                  />
                  {nextResendTimestamp && (
                    <Countdown
                      daysInHours
                      key={nextResendTimestamp?.getTime()}
                      date={nextResendTimestamp}
                      renderer={({ minutes, seconds, completed }) =>
                        !completed ? (
                          <Text
                            mb="15px"
                            color="gray.400"
                            fontSize="14px"
                            fontWeight="medium"
                          >
                            You will be able to resend the code in{" "}
                            {("0" + String(minutes)).slice(-2)}:
                            {("0" + String(seconds)).slice(-2)}.
                          </Text>
                        ) : (
                          <Text
                            onClick={sendCode}
                            color="white"
                            fontWeight="medium"
                            cursor="pointer"
                            mb="15px"
                          >
                            Resend
                          </Text>
                        )
                      }
                    ></Countdown>
                  )}
                  <HStack>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setSection(Sections.BotCommand);
                      }}
                    >
                      Back
                    </Button>
                    <AwaitingButton
                      isDisabled={!secondToken}
                      onClick={async () => {
                        await checkToken();
                      }}
                    >
                      Continue
                    </AwaitingButton>
                  </HStack>
                </>
              ),
              [Sections.NewPassword]: (
                <>
                  <FormInput
                    isInvalid={!validator.isStrongPassword(password)}
                    errorMessage={
                      (!!password && !validator.isStrongPassword(password))
                        ? "Your password must contain at least 8 symbols, including a lowercase and an uppercase letters, at least 1 digit and 1 special symbol."
                        : undefined
                    }
                    placeholder="******"
                    label="New password"
                    value={password}
                    onCustomChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <HStack>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setSection(Sections.Token);
                      }}
                    >
                      Cancel
                    </Button>
                    <AwaitingButton
                      isDisabled={
                        !password || !validator.isStrongPassword(password)
                      }
                      onClick={async () => {
                        await updatePassword();
                        onClose();
                      }}
                    >
                      Save
                    </AwaitingButton>
                  </HStack>
                </>
              ),
            }[section]
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
