import {
  Button,
  Heading,
  Tr,
  Td,
  Text,
  Box
} from '@chakra-ui/react'
import AwaitingButton from 'components/misc/AwaitingButton'
import Confirmation from 'components/misc/Confirmation'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import Table, { getArrayRearrangedByKeys, TableColumnTypes } from 'components/misc/Table'
import instance from 'instances/axios'
import { getDateTimeString } from 'instances/intl'
import { createContext, useContext, useMemo, useState } from 'react'
import { FormattedDate } from 'react-intl'
import {
  type IVerificationToken,
  type IPendingVerificationRequest
} from 'types/admin'

interface VerificationRequestContext {
  onBanRequest: (username: string) => Promise<void>

  banningUsername: IPendingVerificationRequest['username'] | null
  onTokenDeletionRequest: (username: string) => Promise<void>

  deletingTokenUsername: IPendingVerificationRequest['username'] | null
}
const Context = createContext<VerificationRequestContext>({
  onBanRequest: async () => {},
  banningUsername: null,
  onTokenDeletionRequest: async () => {},
  deletingTokenUsername: null
})
function DataCard ({ username, value, discordUsername, tokenCreatedAt }: IVerificationToken) {
  const { onBanRequest, banningUsername, onTokenDeletionRequest, deletingTokenUsername } = useContext(Context)
  return (
    <Tr>
      <Td data-label="Username">{username}</Td>
      <Td data-label="Discord">@{discordUsername}</Td>
      <Td data-label="Token">{value}</Td>
      <Td data-label="Generation date & time">
        <FormattedDate value={tokenCreatedAt} />
      </Td>

      <Td data-label="Revoke the token">
        <Button
          variant="negative"
          onClick={async () => {
            await onTokenDeletionRequest(username)
          }}
          isDisabled={banningUsername === username}
          isLoading={deletingTokenUsername === username}
        >
          Revoke
        </Button>
      </Td>
      <Td data-label="Ban the user"><Confirmation actionText="ban the user"><Button variant="negative" onClick={async () => {
        await onBanRequest(username)
      }}
          isDisabled={deletingTokenUsername === username}
          isLoading={banningUsername === username}>Ban</Button></Confirmation></Td>
    </Tr>
  )
}
interface Props {
  tokens: IVerificationToken[]
  moveTokenBack: (
    username: IPendingVerificationRequest['username']
  ) => void
  deleteTokenFromState: (
    username: IPendingVerificationRequest['username']
  ) => void
}
export default function VerificationTokens ({
  tokens,
  moveTokenBack,
  deleteTokenFromState
}: Props) {
  const [deletingTokenUsername, setDeletingTokenUsername] = useState<string | null>(
    null
  )
  const [banningUsername, setBanningUsername] = useState<string | null>(
    null
  )
  const onTokenDeletionRequest = async (username: string) => {
    setDeletingTokenUsername(username)
    try {
      await instance.delete('/admin/verification-token', { params: { username } })
      moveTokenBack(username)
    } catch {}
    setDeletingTokenUsername(null)
  }
  const onBanRequest = async (username: string) => {
    setBanningUsername(username)
    try {
      await instance.post('/admin/ban-user-by-username', { username })
      deleteTokenFromState(username)
    } catch {}
    setBanningUsername(null)
  }
  const tableData = useMemo(() => getArrayRearrangedByKeys(tokens.map(token => ({ ...token, tokenCreatedAt: getDateTimeString(token.tokenCreatedAt) })), ['username', 'discordUsername', { key: 'value', addColumnProperties: { textColor: 'white' } }, 'tokenCreatedAt', {
 addColumnProperties (item) {
    return { element: <AwaitingButton onClick={async () => { await onTokenDeletionRequest(item.username) }} variant="negative">Revoke</AwaitingButton> }
  }
}, {
 addColumnProperties (item) {
    return { element: <AwaitingButton onClick={async () => { await onBanRequest(item.username) }} variant="negativeGhost">Ban</AwaitingButton> }
  }
}]), [tokens])
  return (
    <Box mt="30px">
    <Heading fontSize="30px">Active tokens</Heading>
      {tokens.length > 0
        ? <Table mt="20px" columns={['Username',
          'Discord',
          { heading: 'Token', type: TableColumnTypes.ColoredText },
          'Registration\'s date & time',
          { heading: 'Revoke token', type: TableColumnTypes.Custom },
          { heading: 'Ban user', type: TableColumnTypes.Custom }]} data={tableData}/>

        : <NotFoundCard heading='No data found' sub='No tokens have been generated yet.'/>}
    </Box>
  )
}
