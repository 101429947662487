import FormikInput from 'components/form/FormikInput'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import instance from 'instances/axios'
import toast from 'react-hot-toast'
import { useAppDispatch } from 'hooks/redux'
import { addProxies } from 'store/proxies'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '../../constants'
const ValidationSchema = Yup.object().shape({
  proxies: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE).test('areValidProxies', "You're using an incorrect format of proxies' creation strings. Please review the hint below.", (value) => {
    const lines = value.split('\n')
    for (const line of lines) {
      const [host, port, username, password] = line.split(':')
      // @ts-expect-error XOR needed
      if (!host || !port || Number(port) > 65535 || Number(port) < 1 || Boolean(username) ^ Boolean(password)) return false
    }
    return true
  })
})
export default function AddProxies () {
  const dispatch = useAppDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen}>Add proxies</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Add Proxies</ModalHeader>
          <Formik
            initialValues={{ proxies: '' }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              try {
                const response = await instance.post('/accounts/proxies', values)
                const proxies = response.data
                toast.success(`The prox${proxies.length === 1 ? 'y' : 'ies'} have been successfully added.`)
                dispatch(addProxies(proxies))
                resetForm()
                onClose()
              } catch {}
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form>
                <ModalBody>
                <fieldset disabled={isSubmitting}>
                  <FormikInput
                    type="textarea"
                    name="proxies"
                    placeholder={'2.2.2.2:81:username:password\n1.1.1.1:80:username:password'}
                    label="Proxies to add, one per line (only HTTPS)"
                  />
                  <Text mt="14px" fontWeight="medium" color="var(--sub-text-color)" fontSize="13px">The format for proxies&apos; strings:</Text><Text color="#fff" fontSize="13px" fontWeight="medium">HOST:PORT:USERNAME:PASSWORD</Text>
                </fieldset></ModalBody>
                <ModalFooter>
                  <Button data-widened variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button data-widened
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={!dirty || !isValid}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}
