import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import FormikInput from "components/form/FormikInput";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Switch,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectCommonData, setCommonData } from "store/common";
import Content from "components/misc/Content";
import instance from "instances/axios";
import { toast } from "react-hot-toast";
import { type ICommonData } from "types/config";
import { isNumberValidationProps } from "types/utils";
import WithdrawalMethods from "./WithdrawalMethods";
import isObjectEqualTo from "helpers/isObjectEqualTo";
import CustomPromocodes from "./CustomPromocodes";
import { type PaymentSystems, RobuxesDeliveryMethods } from "types/economy";
import Checkbox from "components/form/Checkbox";
const ValidationSchema = Yup.object().shape({
  robuxesPerDollarRate: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
    fixedRubleToRobuxRate:Yup.number().when("isFixedRate", {
      is: (
        isFixedRate: boolean
      ) => isFixedRate,
      then: () =>
        Yup.number().required("Required.")
    }),
  maxMerchantPrice: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
  minMerchantPrice: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
  minWithdrawalAmount: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
  maxWithdrawalAmount: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
  minimumRobuxesAmountToOrder: Yup.number()
    .test(...isNumberValidationProps)
    .required("Required."),
  areSalesEnabled: Yup.boolean().when("availableRobuxesDeliveryMethods", {
    is: (
      availableRobuxesDeliveryMethods: ICommonData["availableRobuxesDeliveryMethods"]
    ) => availableRobuxesDeliveryMethods.length === 0,
    then: () =>
      Yup.boolean().oneOf(
        [false],
        "You have to either disable sales or select at least one delivery method."
      ),
  }),
});

const robuxesDeliveryMethodsVisualDataForConfig: Record<
  RobuxesDeliveryMethods,
  { name: string }
> = {
  [RobuxesDeliveryMethods.GamePass]: {
    name: "GamePass",
  },
  [RobuxesDeliveryMethods.Plugin]: {
    name: "Plugin",
  },
};
const paymentSystemsVisualData: Record<PaymentSystems, { name: string }> = {
  gmpays: {
    name: "GMPays",
  },
  payok: {
    name: "Payok.IO",
  },
  palych: {
    name: "Paypalych",
  },
  freekassa: {
    name: "Freekassa",
  },
};

export default function Config() {
  const commonData = useAppSelector(selectCommonData);
  const dispatch = useAppDispatch();
  return (
    <>
      <Content status={commonData.status} error={commonData.error}>
        {() => (
          <>
            <Heading fontSize="25px">Configuration</Heading>
            <Formik
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                try {
                  const newConfigPart = {
                    ...values,
                    fixedRubleToRobuxRate:values.fixedRubleToRobuxRate? Number(
                      (values.fixedRubleToRobuxRate / 0.7).toFixed(2)
                    ):undefined,
                  };
                  await instance.put("/admin/stats", newConfigPart);
                  toast.success("The configuration has been updated.");
                  dispatch(
                    setCommonData({ ...commonData.data, ...newConfigPart })
                  );
                } catch {}
                setSubmitting(false);
              }}
              validationSchema={ValidationSchema}
              initialValues={{
                ...(commonData.data as ICommonData),
                isFixedRate: !!commonData.data?.fixedRubleToRobuxRate,
                fixedRubleToRobuxRate: commonData.data?.fixedRubleToRobuxRate
                  ? Number(
                      (commonData.data.fixedRubleToRobuxRate * 0.7).toFixed(2)
                    )
                  : undefined,
              }}
              enableReinitialize
            >
              {({
                isSubmitting,
                isValid,
                dirty,
                errors,
                values,
                initialValues,
                setFieldValue,
              }) => (
                <Form>
                  <fieldset disabled={isSubmitting}>
                    <FormControl mt={3} isInvalid={!!errors.areSalesEnabled}>
                      <HStack spacing="14px">
                        <FormLabel fontSize="md" color="#fff" mb={0} mr={0}>
                          Enable sales
                        </FormLabel>
                        <Field
                          as={Switch}
                          name="areSalesEnabled"
                          isChecked={values.areSalesEnabled}
                        />
                      </HStack>

                      <ErrorMessage name="areSalesEnabled">
                        {(msg) => <FormErrorMessage>{msg}</FormErrorMessage>}
                      </ErrorMessage>
                    </FormControl>
                    <Wrap mb="30px" mt="28px" spacing="30px">
                      <FormControl
                        isDisabled={!values.areSalesEnabled}
                        w="fit-content"
                      >
                        <FormLabel display="block">
                          Allowed R$ delivery methods
                        </FormLabel>
                        <FieldArray
                          name="availableRobuxesDeliveryMethods"
                          render={(arrayHelpers) => (
                            <VStack
                              mt="20px"
                              align="flex-start"
                              spacing="20px"
                              w="fit-content"
                            >
                              {Object.entries(
                                robuxesDeliveryMethodsVisualDataForConfig
                              ).map(([method, visualData], i) => (
                                <Checkbox
                                  onChange={(e) => {
                                    const clonedCurrentMethods = [
                                      ...values.availableRobuxesDeliveryMethods,
                                    ];
                                    if (e.target.checked) {
                                      arrayHelpers.push(+method);
                                      clonedCurrentMethods.push(+method);
                                    } else {
                                      const index =
                                        values.availableRobuxesDeliveryMethods.indexOf(
                                          +method
                                        );
                                      arrayHelpers.remove(index);
                                      clonedCurrentMethods.splice(index, 1);
                                    }
                                    if (clonedCurrentMethods.length === 0) {
                                      setFieldValue("areSalesEnabled", false);
                                    }
                                  }}
                                  key={i}
                                  name="availableRobuxesDeliveryMethods"
                                  isChecked={values.availableRobuxesDeliveryMethods.includes(
                                    +method
                                  )}
                                >
                                  <Text fontSize="sm" color="white">
                                    {visualData.name}
                                  </Text>
                                </Checkbox>
                              ))}
                            </VStack>
                          )}
                        />
                      </FormControl>
                      <FormControl
                        w="fit-content"
                        isDisabled={!values.areSalesEnabled}
                      >
                        <FormLabel display="block">
                          Allowed payment systems
                        </FormLabel>
                        <FieldArray
                          name="paymentSystems"
                          render={(arrayHelpers) => (
                            <VStack
                              mt="20px"
                              spacing="20px"
                              align="flex-start"
                              w="fit-content"
                            >
                              {Object.entries(paymentSystemsVisualData).map(
                                ([paymentSystem, visualData], i) => (
                                  <Checkbox
                                    onChange={(e) => {
                                      const clonedCurrentPaymentSystems = [
                                        ...values.paymentSystems,
                                      ];
                                      if (e.target.checked) {
                                        arrayHelpers.push(paymentSystem);
                                        clonedCurrentPaymentSystems.push(
                                          paymentSystem as PaymentSystems
                                        );
                                      } else {
                                        const index =
                                          values.paymentSystems.indexOf(
                                            paymentSystem as PaymentSystems
                                          );
                                        arrayHelpers.remove(index);
                                        clonedCurrentPaymentSystems.splice(
                                          index,
                                          1
                                        );
                                      }
                                      if (
                                        clonedCurrentPaymentSystems.length === 0
                                      ) {
                                        setFieldValue("areSalesEnabled", false);
                                      }
                                    }}
                                    key={i}
                                    name="paymentSystems"
                                    isChecked={values.paymentSystems.includes(
                                      paymentSystem as PaymentSystems
                                    )}
                                  >
                                    <Text fontSize="sm" color="white">
                                      {visualData.name}
                                    </Text>
                                  </Checkbox>
                                )
                              )}
                            </VStack>
                          )}
                        />
                      </FormControl>
                    </Wrap>
                    <HStack spacing={2} mb="10px">
                      <FormLabel
                        fontSize="md"
                        color={!values.isFixedRate ? "#fff" : undefined}
                        mb={0}
                        mr={0}
                      >
                        Dynamic
                      </FormLabel>
                      <Field
                        as={Switch}
                        name="isFixedRate"
                        variant="alwaysBlue"
                        isChecked={values.isFixedRate}
                      />
                      <FormLabel
                        fontSize="md"
                        color={values.isFixedRate ? "#fff" : undefined}
                        mb={0}
                        mr={0}
                      >
                        Fixed
                      </FormLabel>
                    </HStack>
                    {values.isFixedRate ? (
                      <FormikInput
                        key={values.isFixedRate}
                        name="fixedRubleToRobuxRate"
                        label="Fixed R$ amount per ruble"
                        placeholder="1.3"
                      />
                    ) : (
                      <FormikInput
                        key={values.isFixedRate}
                        name="robuxesPerDollarRate"
                        label="R$ amount per dollar"
                        placeholder="10"
                      />
                    )}
                    <FormikInput
                      name="minMerchantPrice"
                      label="Min price for account"
                      placeholder="1"
                    />
                    <FormikInput
                      name="maxMerchantPrice"
                      label="Max price for account"
                      placeholder="10"
                    />
                    <FormikInput
                      name="minWithdrawalAmount"
                      label="Min withdrawal amount"
                      placeholder="300"
                    />
                    <FormikInput
                      name="maxWithdrawalAmount"
                      label="Max withdrawal amount"
                      placeholder="300"
                    />
                    <FormikInput
                      name="minimumRobuxesAmountToOrder"
                      label="Min R$ amount to order"
                      placeholder="200"
                    />
                    <FormikInput
                      name="minimumRobuxAmountForAccountActivity"
                      label="Min R$ amount for account activity"
                      placeholder="200"
                    />
                    <FormControl
                      mt={3}
                      isInvalid={!!errors.isHollyJollyEnabled}
                    >
                      <HStack spacing={2}>
                        <FormLabel fontSize="md" color="#fff" mb={0} mr={0}>
                          Enable holly-jolly 🎄
                        </FormLabel>
                        <Field
                          as={Switch}
                          name="isHollyJollyEnabled"
                          isChecked={values.isHollyJollyEnabled}
                        />
                      </HStack>

                      <ErrorMessage name="isHollyJollyEnabled">
                        {(msg) => <FormErrorMessage>{msg}</FormErrorMessage>}
                      </ErrorMessage>
                    </FormControl>
                  </fieldset>

                  <Button
                    mt={3}
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={
                      !dirty ||
                      !isValid ||
                      isObjectEqualTo(values, initialValues)
                    }
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Content>
      <WithdrawalMethods />
      <CustomPromocodes />
    </>
  );
}
