import toast from 'react-hot-toast'
import { fetchUserData, setLoggedStatus } from 'store/user'
import store from '../store'
import setAuthTokens from './tokens/set'
import { setAuthToken } from 'instances/axios'
import { type AuthTokens } from 'types/auth'
import { fetchCommonData } from 'store/common'
import { fetchProxiesData } from 'store/proxies'
import { type UserRoles } from 'types/users'
import { fetchAccountsData } from 'store/accounts'
export default function setUpLoginAndSetTokens (tokens: AuthTokens) {
  setAuthTokens(tokens)
  setUpLogin(tokens.access)
}
export function setUpLogin (
  access: string | null = localStorage.getItem('access_token')
) {
  if (!access) return toast.error('Failed to set up the access token.')
  setAuthToken(access)

  store.dispatch(setLoggedStatus(true))
  store.dispatch(fetchUserData())
  if (window.socket) {
    window.socket.disconnect()
  }
  // setupSocket(access)
}
export function setUpLoggedData (role: UserRoles) {
  store.dispatch(fetchCommonData())
  store.dispatch(fetchAccountsData(role))
  store.dispatch(fetchProxiesData(role))
}
