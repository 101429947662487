import setAuthTokens from './set'
import axios from 'axios'
import { baseURL } from '../../constants'
import { type AuthTokens } from 'types/auth'
export default async function refreshAuthTokens (): Promise<AuthTokens> {
  const refreshToken = localStorage.getItem('refresh_token')
  const result = await axios.post(baseURL + '/auth/refresh', { refreshToken })
  const tokens = result.data.tokens
  setAuthTokens(tokens)
  return tokens
}
