import instance from 'instances/axios'
import { type AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { LoadingStatuses } from 'types/store'
export default function useAxios<T = any> (path: string, config?: AxiosRequestConfig) {
  const [key, setKey] = useState<number>(0)
  const reload = () => { setKey(key + 1); setData(null); setError(null) }
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    instance.get(path, config)
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => {
        setError(e.message)
      })
  }, [key])
  let status
  if (data) {
    status = LoadingStatuses.Success
  } else {
    if (error) {
      status = LoadingStatuses.Error
    } else {
      status = LoadingStatuses.Loading
    }
  }
  return { status, data, error, setData, reload }
}
