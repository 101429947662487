import Table, { getArrayRearrangedByKeys, TableColumnTypes } from 'components/misc/Table'
import { WithdrawalStatuses, type IWithdrawal } from 'types/economy'
import Withdrawal from './Withdrawal'
import { Card, Heading, Icon, Tag, Text } from '@chakra-ui/react'
import instance from 'instances/axios'
import { toast } from 'react-hot-toast'
import { useAppDispatch } from 'hooks/redux'
import { topUpBalance } from 'store/user'
import { DateTimeFormat, getDateTimeString } from 'instances/intl'
import { CloseIcon } from 'assets/icons'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import { useMemo } from 'react'

interface Props {
  data: IWithdrawal[]
  deleteWithdrawal: (_id: IWithdrawal['_id']) => void
}

const statusToVariant: Record<WithdrawalStatuses, 'positive' | 'negative' | 'pending'> = {
  [WithdrawalStatuses.Approved]: 'positive',
  [WithdrawalStatuses.Pending]: 'pending',
  [WithdrawalStatuses.Rejected]: 'negative'
}
const statusToLabel: Record<WithdrawalStatuses, string> = {
  [WithdrawalStatuses.Approved]: 'Approved',
  [WithdrawalStatuses.Pending]: 'Pending',
  [WithdrawalStatuses.Rejected]: 'Rejected'
}
export default function WithdrawalsHistory ({ data, deleteWithdrawal }: Props) {
  const dispatch = useAppDispatch()
  const onCancellation = async (withdrawal: Pick<IWithdrawal, '_id' | 'amount'>) => {
    try {
      await instance.post('/users/cancel-withdrawal', { id: withdrawal._id })
      toast.success('The withdrawal has been cancelled.')
      deleteWithdrawal(withdrawal._id)
      dispatch(topUpBalance(withdrawal.amount))
    } catch {}
  }
  const tableData = useMemo(() => getArrayRearrangedByKeys(data.map(withdrawal => ({ ...withdrawal, createdAt: getDateTimeString(withdrawal.createdAt), statusText: statusToLabel[withdrawal.status] })), ['id', {
    key: 'amount',
   addColumnProperties: { tagVariant: 'neutral' }

   }, 'method', 'createdAt', {
    key: 'statusText',
   addColumnProperties (item) {
               return {
                 tagVariant: statusToVariant[item.status]
               }
             }
   }, {
    key: '_id',
   addColumnProperties (item) {
     return { element: item.status === WithdrawalStatuses.Pending ? <Tag onClick={async () => { await onCancellation({ _id: item._id, amount: item.amount }) }} variant="negative" data-clickable p="7px"><Icon as={CloseIcon} boxSize="10px"/></Tag> : null }
   }
   }]), [data])
  return (
    <>

      {data.length > 0
        ? (<>  <Heading mt="50px">
        Withdrawals History
      </Heading>
        <Card
        mt="25px"><Table
          columns={['ID', { heading: 'Amount (US$)', type: TableColumnTypes.Tag }, 'Method', 'Date', { heading: 'Status', type: TableColumnTypes.Tag }, { heading: 'Action', type: TableColumnTypes.Custom }]}
          data={tableData}
        />
         </Card></>
          )
        : (
        <NotFoundCard mt="50px" heading='Withdrawals history' sub='No withdrawals found.'/>
          )}
    </>
  )
}
