import Table, { getArrayRearrangedByKeys, TableColumnTypes } from 'components/misc/Table'
import useAxios from 'hooks/useAxios'
import instance from 'instances/axios'
import Withdrawal from './Withdrawal'
import { type AdminViewWithdrawal } from 'types/admin'
import Content from 'components/misc/Content'
import AwaitingButton from 'components/misc/AwaitingButton'
import { memo } from 'react'
import ServerContent from 'components/misc/ServerContent'
import { WithdrawalStatuses } from 'types/economy'
import { Button, Icon } from '@chakra-ui/react'
import { CopyIcon } from 'assets/icons'
import toast from 'react-hot-toast'
import { getDateTimeString } from '../../instances/intl'
const withdrawalStatusesColors: Record<WithdrawalStatuses, 'positive' | 'negative' | 'pending' | 'neutral'> = {
  [WithdrawalStatuses.Approved]: 'positive',
  [WithdrawalStatuses.Rejected]: 'negative',
  [WithdrawalStatuses.Pending]: 'pending'

}
const withdrawalStatusesTexts: Record<WithdrawalStatuses, string> = {
  [WithdrawalStatuses.Approved]: 'Approved',
  [WithdrawalStatuses.Rejected]: 'Rejected',
  [WithdrawalStatuses.Pending]: 'Pending'
}
function WithdrawalsHistory () {
  const { setData, ...history } = useAxios<AdminViewWithdrawal[]>(
    '/admin/withdrawals-history'
  )
  const loadMore = async () => {
    if (!history.data) return
    try {
      const response = await instance.get('/admin/withdrawals-history', {
        params: { cursor: history.data.length }
      })
      setData([...history.data, ...response.data])
    } catch {}
  }
  const copyAddress = (address: string) => {
    window.navigator.clipboard.writeText(address)
    toast.success('Скопировано.')
  }
  return (
    <ServerContent {...history}>
      {(data) => (
        <>
          <Table mt="20px" columns={['ID', { heading: 'Status', type: TableColumnTypes.Tag }, 'Username', { heading: 'Amount', type: TableColumnTypes.Tag }, 'Method', { heading: 'Address', type: TableColumnTypes.Custom }, 'Requested at']} data={getArrayRearrangedByKeys(data.map(withdrawal => ({ ...withdrawal, createdAt: getDateTimeString(withdrawal.createdAt), statusText: withdrawalStatusesTexts[withdrawal.status] })), ['id', {
 key: 'statusText',
addColumnProperties (item) {
            return { tagVariant: withdrawalStatusesColors[item.status] }
          }
}, 'username', 'amount', 'method', {
 key: 'address',
addColumnProperties (item) {
  return { element: <Button variant="outline" onClick={() => { copyAddress(item.address) }} leftIcon={<Icon boxSize="17px" as={CopyIcon}/>}>Copy</Button> }
}
}, 'createdAt'])}/>
          {data.length % 10 === 0 && <AwaitingButton mt="20px" onClick={loadMore}>
            Load more
          </AwaitingButton>}
        </>
      )}
    </ServerContent>
  )
}
export default memo(WithdrawalsHistory)
