import {
  Box,
  Center,
  Spinner,
  Table as ChakraTable,
  type TableProps as ChakraTableProps,
  Tbody,
  Thead,
  Tr,
  Th,
  Tag,
  HStack,
  Avatar,
  Td,
  Text,
  type ChakraProps
} from '@chakra-ui/react'
import Checkbox from 'components/form/Checkbox'
import React, { isValidElement, type ReactNode, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BidirectionalArrowsIcon } from 'assets/icons'

export enum TableColumnTypes {
  Common,
  WithAvatar,
  Tag,
  ColoredText,
  Custom,
  WithLeftElement,
}
interface TagStyles {
  bg: string
  color: string
}
interface ITableColumnData {
  heading: string | ReactNode
  type?: TableColumnTypes
  isSortable?: boolean
}
export type RowColumnAdditionalProperties = Partial<{
  avatarSrc: string
  tagStyles: TagStyles
  tagVariant: string
  element: ReactNode | ReactNode[]
  textColor: string
  leftElement: ReactNode | ReactNode[]
}>
type TableRowDataArray = Array<
  string | ({ value: any } & RowColumnAdditionalProperties)
>
interface RowProperties {
  descriptor?: string
  href?: string
}
interface Props {
  columns: Array<string | ITableColumnData>
  isWithCheckboxes?: boolean
  data?: Array<TableRowDataArray & RowProperties>
  selectedDescriptors?: Array<Exclude<RowProperties['descriptor'], undefined>>
  onRowSelection?: (descriptor?: RowProperties['descriptor']) => void
  onRowDeselection?: (descriptor?: RowProperties['descriptor']) => void
  stylesByRow?: Record<number, ChakraProps>
}

const isScrollbarVisible = (element: Element) =>
  element.scrollWidth > element.clientWidth
const isOverlappingParent = (element: Element) =>
  !!(
    element.parentElement &&
    element.clientWidth > element.parentElement?.clientWidth
  )

const tableMutators: Record<
  Exclude<TableColumnTypes, TableColumnTypes.Common>,
  (columnData: Exclude<TableRowDataArray[0], string>) => React.ReactNode
> = {
  [TableColumnTypes.Tag]: ({ tagStyles, tagVariant, value }) => {
    return (
      <Tag
        variant={tagVariant}
        {...(tagStyles ? { bg: tagStyles.bg, color: tagStyles.color } : {})}
      >
        <>{value}</>
      </Tag>
    )
  },
  [TableColumnTypes.WithAvatar]: ({ avatarSrc, value }) => {
    return (
      <HStack gap="15px" color="white">
        <Avatar boxSize="36px" src={avatarSrc} />
        <>{value}</>
      </HStack>
    )
  },
  [TableColumnTypes.ColoredText]: ({ textColor, value }) => {
    return (
      <Text fontSize="inherit" color={textColor}>
        {value}
      </Text>
    )
  },
  [TableColumnTypes.Custom]: ({ element }) => {
    return element
  },
  [TableColumnTypes.WithLeftElement]: ({ leftElement, value }) => {
    if (!leftElement) return value
    return (
      <HStack>
        {leftElement}
        <>{value}</>
      </HStack>
    )
  }
}
interface ExtendedRowOrder<T> {
  key?: keyof T
  addColumnProperties:
    | RowColumnAdditionalProperties
    | ((item: T) => RowColumnAdditionalProperties)
}
export function getArrayRearrangedByKeys<T extends Record<string, any>> (
  array: T[],
  rowsOrder: Array<keyof T | ExtendedRowOrder<T>>,
  addRowProperties?: (item: T) => Record<string, any>
) {
  const rowsOrderAsObject = Object.fromEntries(
    rowsOrder.map((row, i) => [typeof row === 'object' ? row.key : row, i])
  )
  const unassignedColumns = rowsOrder
    .map((item, i) => ({ index: i, order: item }))
    .filter(({ order }) => typeof order === 'object' && !order.key) as Array<{
    index: number
    order: ExtendedRowOrder<T>
  }>
  return array.map((object) => {
    const rowData = [...Array(rowsOrder.length)].map(() => ({ value: null }))

    for (const [key, value] of Object.entries(object)) {
      const orderForKey = rowsOrderAsObject[key]
      if (orderForKey !== undefined) {
        const row = rowsOrder[orderForKey]
        let additionalProperties = {}
        if (typeof row === 'object') {
          const { addColumnProperties } = row
          additionalProperties =
            addColumnProperties instanceof Function
              ? addColumnProperties(object)
              : addColumnProperties
        }
        rowData[orderForKey] = { value, ...additionalProperties }
      }
    }
    for (const { index, order } of unassignedColumns) {
      const { addColumnProperties } = order
      const additionalProperties =
        addColumnProperties instanceof Function
          ? addColumnProperties(object)
          : addColumnProperties
      rowData[index] = { value: null, ...additionalProperties }
    }
    for (const [key, value] of Object.entries(
      addRowProperties?.(object) || []
    )) {
      // @ts-expect-error what
      rowData[key] = value
    }
    return rowData
  })
}
export function transformArrayForTable<T> (
  array: T[],
  addOtherProperties?: (item: T) => Record<string, any>
) {
  return array.map((item, i) => ({
    value: item,
    ...(addOtherProperties ? addOtherProperties(item) : {})
  }))
}
const areArraysEqual = (a: any[], b: any[]) =>
  a.length === b.length && a.every((element, index) => element === b[index])

export function StyledSortIcon ({ isAscending }: { isAscending: boolean }) {
  return (
    <Box
      w="7px"
      h="15px"
      verticalAlign="-0.17rem"
      transform={isAscending ? 'rotate(180deg)' : undefined}
      style={{ marginLeft: 5, display: 'inline-block' }}
    >
      <BidirectionalArrowsIcon />
    </Box>
  )
}
const MINI_TABLE_CLASSNAME = 'mini-table'
const getNodeText = function (reactNode: React.ReactNode): string {
  let string = ''
  if (typeof reactNode === 'string') {
    string = reactNode
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString()
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += getNodeText(child)
    })
  } else if (isValidElement(reactNode)) {
    string += getNodeText(reactNode.props.children)
  }
  return string
}
function Table ({
  children: rawChildren,
  columns: columnsAsUnknown,
  isWithCheckboxes = false,
  data,
  stylesByRow,
  selectedDescriptors: outDescriptors,
  onRowSelection,
  onRowDeselection,
  ...props
}: ChakraTableProps & Props) {
  const columns = columnsAsUnknown.map((columns) =>
    typeof columns === 'string'
      ? { heading: columns, type: TableColumnTypes.Common }
      : columns
  )
  const navigate = useNavigate()
  const ref = useRef<HTMLTableElement | null>(null)
  const [isMini, setIsMini] = useState<boolean>(true)
  useEffect(() => {
    const node = ref.current
    if (!node) return
    const callback = () => {
      const newIsMini = isScrollbarVisible(node) || isOverlappingParent(node)
      if (isMini && !newIsMini) {
        node.classList.remove(MINI_TABLE_CLASSNAME)
        const thirdIsMini =
          isScrollbarVisible(node) || isOverlappingParent(node)
        const bodyHasScrollbar = isScrollbarVisible(document.body)
        if (!thirdIsMini && !bodyHasScrollbar) {
          setIsMini(false)
        } else {
          node.classList.add(MINI_TABLE_CLASSNAME)
        }
      } else {
        setIsMini(newIsMini)
      }
    }
    window.addEventListener('resize', callback)
    setTimeout(callback, 1)
    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [ref])
  const [selectedRowsDescriptors, setSelectedRowsDescriptors] = useState<
    string[]
  >([])
  useEffect(() => {
    if (
      !outDescriptors ||
      areArraysEqual(selectedRowsDescriptors, outDescriptors)
    ) {
      return
    }
    setSelectedRowsDescriptors(outDescriptors)
  }, [outDescriptors])
  type Descriptor = (typeof selectedRowsDescriptors)[0]
  const addRowDescriptor = (descriptor: Descriptor) => {
    setSelectedRowsDescriptors([...selectedRowsDescriptors, descriptor])
  }
  const removeRowDecriptor = (descriptor: Descriptor) => {
    const index = selectedRowsDescriptors.indexOf(descriptor)
    if (index === -1) return
    const clonedSelectedRowsDescriptors = [...selectedRowsDescriptors]
    clonedSelectedRowsDescriptors.splice(index, 1)
    setSelectedRowsDescriptors(clonedSelectedRowsDescriptors)
  }

  const [sortFieldIndex, setSortFieldIndex] = useState<number | null>(null)
  const [isAscending, setAscending] = useState<boolean>(false)

  const getSortingHandler = (newSortFieldIndex: number) => () => {
    if (sortFieldIndex !== newSortFieldIndex) {
      setSortFieldIndex(newSortFieldIndex)
      if (!isAscending) setAscending(true)
    } else {
      setAscending(!isAscending)
    }
  }
  let preparedData = data
  if (preparedData && typeof sortFieldIndex === 'number') {
    preparedData = preparedData.toSorted((x, y) => {
      const a = x[sortFieldIndex]
      const b = y[sortFieldIndex]
      return (
        (+(typeof a === 'string'
          ? a
          : a.value) -
          +(typeof b === 'string'
            ? b
            : b.value)) *
        (isAscending ? 1 : -1)
      )
    })
  }

  const children = preparedData
    ? preparedData.map((rowData, i) => {
        const rowChildren = rowData.map((columnData, j) => {
          if (typeof columnData === 'string') {
            return columnData
          }

          const { type: columnType, heading } = columns[j]
          let finalValue: ReactNode | string = columnData.value
          if (
            typeof columnType !== 'undefined' &&
            columnType !== TableColumnTypes.Common
          ) {
            finalValue = tableMutators[columnType](columnData)
          }
          return (
            <Td key={j} data-label={getNodeText(heading)}>
              {finalValue}
            </Td>
          )
        })
        if (isWithCheckboxes) {
          const { descriptor } = rowData
          if (!descriptor) throw new Error(`no descriptor for ${i}`)
          rowChildren.unshift(
            <Td>
              <Checkbox
                isChecked={selectedRowsDescriptors.includes(descriptor)}
                onChange={({ target: { checked } }) => {
                  if (!descriptor) return
                  if (checked) {
                    addRowDescriptor(descriptor)
                    onRowSelection?.(descriptor)
                  } else {
                    removeRowDecriptor(descriptor)
                    onRowDeselection?.(descriptor)
                  }
                }}
              />
            </Td>
          )
        }
        let onClick
        const { href } = rowData
        if (href) {
          onClick = () => {
            navigate(href)
          }
        }
        return (
          <Tr
            cursor={onClick ? 'pointer' : undefined}
            key={i}
            onClick={onClick}
            {...(stylesByRow?.[i] || {})}
          >
            {rowChildren}
          </Tr>
        )
      })
    : rawChildren
  let marginTop = '-11px'
  let marginBottom = '-11px'
  if (props.mt) {
    marginTop = `calc(${props.mt.toString()} + ${marginTop})`
  }
  if (props.mb) {
    marginBottom = `calc(${props.mb.toString()} + ${marginBottom})`
  }
  return (
    <Box>
      <ChakraTable
        pos="relative"
        {...props}
        mt={marginTop}
        mb={marginBottom}
        ref={ref}
        className={isMini ? MINI_TABLE_CLASSNAME : undefined}
      >
        <Thead>
          <Tr>
            {isWithCheckboxes && (
              <Th>
                <Checkbox
                  checked={selectedRowsDescriptors.length === data?.length}
                  isChecked={selectedRowsDescriptors.length === data?.length}
                  onChange={() => {
                    if (!data) return
                    if (selectedRowsDescriptors.length === data.length) {
                      setSelectedRowsDescriptors([])
                      onRowDeselection?.()
                    } else {
                      setSelectedRowsDescriptors(
                        data.map((d) => d.descriptor as string)
                      )
                      onRowSelection?.()
                    }
                  }}
                />
              </Th>
            )}
            {columns.map((column, i) => {
              return (
                <Th
                  key={i}
                  cursor={column.isSortable ? 'pointer' : undefined}
                  onClick={column.isSortable ? getSortingHandler(i) : undefined}
                >
                  {column.heading}
                  {column.isSortable && (
                    <StyledSortIcon
                      isAscending={isAscending && sortFieldIndex === i}
                    />
                  )}
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </ChakraTable>
    </Box>
  )
}
export default React.memo(Table)
