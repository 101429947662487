import { Text } from '@chakra-ui/react'
import instance from 'instances/axios'
import { useState, useEffect } from 'react'
import {
  type IPendingVerificationRequest,
  type IVerificationToken
} from 'types/admin'
import PendingVerificationRequests from './PendingVerificationRequests'
import Spinner from 'components/misc/Spinner'
import { toast } from 'react-hot-toast'
import VerificationTokens from './VerificationTokens'
export default function Verification () {
  const [pendingVerificationRequests, setPendingVerificationRequests] =
    useState<IPendingVerificationRequest[] | null>(null)
  const [verificationTokens, setVerificationTokens] = useState<
  IVerificationToken[] | null
  >(null)
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    instance
      .get<{
      requests: IPendingVerificationRequest[]
      tokens: IVerificationToken[]
    }>('/admin/verification-data')
      .then((res) => {
        const { requests, tokens } = res.data
        setPendingVerificationRequests(requests)
        setVerificationTokens(tokens)
      }).catch(e => { setError(e.message) })
  }, [])
  const moveUserToGenerated = (username: IPendingVerificationRequest['username'], token: string) => {
    if (!pendingVerificationRequests || !verificationTokens) return toast.error('Wait for the data loading.')
    const clonedPastState = [...pendingVerificationRequests]
    const previousIndex = clonedPastState.findIndex(request => request.username === username)
    if (previousIndex === -1) return toast.error(`Verification request for user @${username} was not found.`)
    const { userCreatedAt, discordUsername } = clonedPastState[previousIndex]
    clonedPastState.splice(previousIndex, 1)
    setPendingVerificationRequests(clonedPastState)
    const clonedFutureState = [...verificationTokens]
    clonedFutureState.push({ tokenCreatedAt: new Date(), discordUsername, username, value: token, userCreatedAt })
    setVerificationTokens(clonedFutureState)
  }
  const deleteUserFromState = (username: IPendingVerificationRequest['username']) => {
    if (!pendingVerificationRequests) return toast.error('Wait for the data loading.')
    const clonedPastState = [...pendingVerificationRequests]
    const previousIndex = clonedPastState.findIndex(request => request.username === username)
    if (previousIndex === -1) return toast.error(`A verification request for user @${username} was not found.`)
    clonedPastState.splice(previousIndex, 1)
    setPendingVerificationRequests(clonedPastState)
  }
  const moveTokenBack = (username: IPendingVerificationRequest['username']) => {
    if (!verificationTokens || !pendingVerificationRequests) return toast.error('Wait for the data loading.')
    const clonedPastState = [...verificationTokens]
    const previousIndex = clonedPastState.findIndex(request => request.username === username)
    const { userCreatedAt, discordUsername } = clonedPastState[previousIndex]
    // ! delete unused properties
    if (previousIndex === -1) return toast.error(`A token for user @${username} was not found.`)
    clonedPastState.splice(previousIndex, 1)
    setVerificationTokens(clonedPastState)
    const clonedFutureState = [...pendingVerificationRequests]
    clonedFutureState.unshift({ username, userCreatedAt, discordUsername })
    setPendingVerificationRequests(clonedFutureState)
  }
  const deleteTokenFromState = (username: IPendingVerificationRequest['username']) => {
    if (!verificationTokens) return toast.error('Wait for the data loading.')
    const clonedPastState = [...verificationTokens]
    const previousIndex = clonedPastState.findIndex(request => request.username === username)
    if (previousIndex === -1) return toast.error(`A token for user @${username} was not found.`)
    clonedPastState.splice(previousIndex, 1)
    setVerificationTokens(clonedPastState)
  }
  if (error) return <Text variant="error">{error}</Text>
  if (!pendingVerificationRequests || !verificationTokens) return <Spinner/>

  return (
    <>
      <PendingVerificationRequests deleteUserFromState={deleteUserFromState} moveUserToGenerated={moveUserToGenerated} requests={pendingVerificationRequests} />
      <VerificationTokens moveTokenBack={moveTokenBack} deleteTokenFromState={deleteTokenFromState} tokens={verificationTokens}/>
    </>
  )
}
