import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { switchAnatomy } from '@chakra-ui/anatomy'
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
)
export const Switch = defineMultiStyleConfig({
  baseStyle: {
    track: {
      bg: 'gray.600',
      _checked: {
        bg: 'primary.500'
      }
    }
  },
  variants:{
    "alwaysBlue":{
      track:{
        bg:"primary.500"
      }
    }
  }
})
