import React, { useState, type ReactNode } from 'react'
import { Button, type ButtonProps, IconButton, Spinner, Tag } from '@chakra-ui/react'
// import Spinner from './Spinner'

interface Props {
  onClick: (event?: React.MouseEvent) => Promise<void>
  children?: ReactNode | ReactNode[]
}
export function AwaitingTag ({ onClick, children, 'aria-label': ariaLabel, ...props }: Props & ButtonProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <Tag
      aria-label={ariaLabel}
      data-disabled={isLoading}
      onClick={async (event) => {
        setIsLoading(true)
        await onClick(event)
        setIsLoading(false)
      }}
      {...props}
      p={isLoading ? '5px' : props.p}
    >{isLoading ? <Spinner boxSize="14px"/> : children}</Tag>
  )
}
