import { type SVGProps, type FunctionComponent } from 'react'
import { Box, Text, Icon as ChakraIcon, HStack } from '@chakra-ui/react'
import Link from 'components/misc/Link'
import { type ImportedIconType } from '.'

interface Props {
  icon: [ImportedIconType, ImportedIconType]
  text: string
  isCurrentLink?: boolean
  href: string
  onClose?: () => void
  target?: string
}
export default function HeaderLink ({
  icon: Icon,
  text,
  href,
  isCurrentLink,
  onClose,
  target
}: Props) {
  return (
    <Link
      w="full"
      cursor={isCurrentLink ? 'default' : 'pointer'}
      onClick={onClose}
      href={href}
      target={target}
    >
      <HStack
        _active={{ transform: 'scale(0.95)' }}
        transition=".2s ease-in-out"
        justify="flex-start"
        w="full"
        borderRadius="default"
        gap="15px"
        p={3.5}
        bg={isCurrentLink ? 'gray.800' : 'transparent'}
        color={isCurrentLink ? 'white' : 'gray.400'}
        _hover={!isCurrentLink ? { bg: 'blue.900', color: '#a7d3ff' } : {}}
      >
        <Box boxSize="24px">
          <ChakraIcon as={Icon[isCurrentLink ? 1 : 0]} boxSize="full" />
        </Box>
        <Text fontWeight="medium" display={{ sm: 'block', md: 'none', '2xl': 'block' }} fontSize="1rem">
          {text}
        </Text>
      </HStack>
    </Link>
  )
}
