import { Helmet } from 'react-helmet'
import { useEffect, useId } from 'react'

import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import theme from './theme'
import { getRoutes } from 'constants/routes'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'hooks/redux'
import { selectUser } from 'store/user'
import Banner from 'components/layout/Banner'
import { Toaster, toast, useToasterStore } from 'react-hot-toast'
import { setUpLoggedData, setUpLogin } from 'helpers/setUpLogin'
import { IntlProvider } from 'react-intl'
import { UserVerificationStatuses } from 'types/users'

declare global {
  interface Window {
    socket: any
    socketId: string
  }
}
function App () {
  const { isLogged, data,status } = useAppSelector(selectUser)

  const currentRoutes = getRoutes({
    isLogged,
    status,
    role: data?.role,
    verificationStatus: data?.verificationStatus
  })

  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (isLogged) {
      setUpLogin()
    } else {
      navigate('/login')
    }
  }, [isLogged])
  useEffect(() => {
    if (data?.verificationStatus === UserVerificationStatuses.Verified) {
      setUpLoggedData(data.role)
    }
  }, [isLogged, data?.verificationStatus])
  useEffect(() => {
    if (
      data &&
      !currentRoutes
        .map((route) => route.url)
        .some((url) =>
          pathname === '/' ? url === '/' : (pathname + '/').includes(url + '/')
        )
    ) {
      navigate(currentRoutes[0].url)
    }
  }, [pathname, isLogged, data])
  const { toasts } = useToasterStore()

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= 3) // Is toast index over limit?
      .forEach((t) => { toast.dismiss(t.id) }) // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts])
  return (
    <ChakraProvider theme={theme}>
      <IntlProvider locale="en">
        {!isLogged && <Banner />}
        <Flex alignItems="stretch" minH="full" direction={{ sm: 'column', md: 'row' }}>
          {isLogged && <Header />}
          <Box as="main" mx="auto" w="full" maxW="1700px" p={{ sm: 4, md: 8, '3xl': '58px 150px' }} flex={1}>
            <Routes>
              {currentRoutes.map(({ url, component, title }) => (
                <Route
                  path={url}
                  key={url}
                  element={
                    <>
                      <Helmet>
                        <title>{title} | RBX.CASH</title>
                      </Helmet>
                      {component}
                    </>
                  }
                />
              ))}
            </Routes>
          </Box>
        </Flex>
        <Footer />
        <Toaster position="top-right" toastOptions={{
 success: {
  iconTheme: {
    primary: 'var(--chakra-colors-green-300)',
    secondary: 'white'
  }
 },
          style: {
            background: '#10151D',
            color: 'white',
            border: 'none',
            borderRadius: '8px'
          }
        }} />
      </IntlProvider>
    </ChakraProvider>
  )
}

export default App
