import {
  type CheckboxProps,
  Checkbox as ChakraCheckbox,
  Icon
} from '@chakra-ui/react'
import { CheckmarkIcon } from 'assets/icons'
function CustomIcon ({ isChecked }: Pick<CheckboxProps, 'isChecked'>) {
  return isChecked ? <Icon as={CheckmarkIcon} boxSize="10px" /> : null
}
export default function Checkbox (props: CheckboxProps) {
  return <ChakraCheckbox icon={<CustomIcon />} {...props} />
}
