import {
  cardAnatomy,
  checkboxAnatomy,
  modalAnatomy,
  selectAnatomy,
  tableAnatomy,
  tabsAnatomy
} from '@chakra-ui/anatomy'

import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme
} from '@chakra-ui/react'
import Menu from 'chakra/menu'
import { Switch } from 'chakra/switch'
import { Tag } from 'chakra/tag'

const colors = {
  black: '#0c1015',
  gray: {
    50: '#828895',
    100: '#f1f1f2',
    200: '#e6e7e9',
    300: '#d2d4d7',
    400: '#8792A2',
    500: '#797f88',
    600: '#202935',
    700: '#2e3744',
    800: '#252E3C',
    900: '#141a23',
    950: '#19222E'
  },
  blue: {
    50: '#f3f6fb',
    100: '#d1dff2',
    200: '#b0c7e8',
    300: '#8caede',
    400: '#6995d4',
    500: '#4a80cb',
    600: '#376ab2',
    700: '#2a5188',
    800: '#23426f',
    900: '#1f2f44'
  },
  purple: {
    50: '#f8f6fc',
    100: '#e5daf4',
    200: '#d2bfed',
    300: '#b698e1',
    400: '#a37cd9',
    500: '#8958ce',
    600: '#763ec6',
    700: '#6133a2',
    800: '#502985',
    900: '#3b1f63'
  },
  pink: {
    50: '#fcf6f8',
    100: '#f4dae5',
    200: '#ecbcd0',
    300: '#e094b3',
    400: '#d7769e',
    500: '#cb4d81',
    600: '#b7396d',
    700: '#952e59',
    800: '#742445',
    900: '#551b33'
  },
  red: {
    50: '#fcf6f5',
    100: '#f4dbd8',
    200: '#eabab6',
    300: '#de928c',
    400: '#d67b73',
    500: '#cc594f',
    600: '#F95151',
    700: '#92362e',
    800: '#7c2d27',
    900: '#5a211c'
  },
  orange: {
    50: '#fdfaf7',
    100: '#f5ecde',
    200: '#ebd6b9',
    300: '#dbb682',
    400: '#cc9950',
    500: '#b58139',
    600: '#996d30',
    700: '#7a5726',
    800: '#60441e',
    900: '#4f3819'
  },
  yellow: {
    50: '#fefefc',
    100: '#faf9ee',
    200: '#f1eecf',
    300: '#e6e1a9',
    400: '#EED86A',
    500: '#b4aa38',
    600: '#90882d',
    700: '#706a23',
    800: '#54501a',
    900: '#464216'
  },
  green: {
    50: '#f7fdfa',
    100: '#d2f2e5',
    200: '#a0e3c7',
    300: '#14B68F',
    400: '#3abb86',
    500: '#32a173',
    600: '#29855f',
    700: '#20684a',
    800: '#1a553c',
    900: '#164632'
  },
  teal: {
    50: '#f4fbfc',
    100: '#ceeef1',
    200: '#a3dfe4',
    300: '#6dccd5',
    400: '#3ab0bb',
    500: '#31969f',
    600: '#287a81',
    700: '#1f5f64',
    800: '#1a4f53',
    900: '#154145'
  },
  cyan: {
    50: '#f6fbfc',
    100: '#d8edf4',
    200: '#c6e5ef',
    300: '#b2dbe9',
    400: '#76bfd7',
    500: '#58b1ce',
    600: '#3ca1c2',
    700: '#3285a0',
    800: '#296d83',
    900: '#205466'
  },
  primary: {
    50: '#f4f7fc',
    100: '#d5e1f3',
    200: '#b1c7e8',
    300: '#85a8dc',
    400: '#4285eb',
    500: '#0066FF',
    600: '#4A92FF',
    700: '#2d5490',
    800: '#264779',
    900: '#1b3358'
  }
}

const { defineMultiStyleConfig: defineCheckboxMultiStyleConfig, definePartsStyle: defineCheckboxPartsStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)
const Checkbox = defineCheckboxMultiStyleConfig({
  baseStyle: defineCheckboxPartsStyleConfig({
    icon: {
      width: '65%!important',
      height: '65%!important'

    },
    label: {
      fontWeight: 'medium',
      fontSize: '15px'
    },
    control: {
      border: '1.5px solid',
      borderColor: '#323B48',
      _disabled: {
        bg: 'transparent',
        opacity: 0.5,
        borderColor: '#323B48'
      },
      p: '2px',
      _hover: {

              },
      _checked: {
 background: 'primary.500',
borderColor: 'primary.500',
_disabled: {
  bg: 'primary.500',
  borderColor: 'primary.500',
  color: 'white'
},
_hover: {
  background: 'primary.500',
  borderColor: 'primary.500'
              }
},
      boxSize: '20px'

    }
  }),
  variants: {
    primary: defineCheckboxPartsStyleConfig({
      control: {
        borderRadius: '4px'
      }
    }),
    rounded: {
      control: {
        borderColor: 'gray.600',
        borderRadius: 'full'
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})

const { defineMultiStyleConfig: defineSelectMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)
const Select = defineSelectMultiStyleConfig({
  variants: {
    primary: {
      field: {
        border: 'default',
        bg: 'var(--root-bg)',
        color: '#fff',
        borderRadius: 'default',
        _disabled: {
          opacity: 0.8,
          color: 'gray.500'
        }
      },
      icon: {
        _disabled: {
          opacity: 0.8
        }
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})
const { defineMultiStyleConfig: defineCardMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)
// const Input = defineInputMultiStyleConfig()
const Heading = defineStyleConfig({
  baseStyle: {
    color: '#fff',
    fontSize: '2.2rem',
    fontWeight: 500
  }
})
const Textarea = defineStyleConfig({
  baseStyle: {
    color: '#fff',
            py: '0.875rem',
            px: '1.25rem',
            bg: 'gray.600',
            '--input-height': 'initial',
            '--input-font-size': '0.95rem',
            fontFamily: 'var(--font-rubik), sans-serif',

            _placeholder: {
              color: '#8792A2'
            }
  },
  variants: {
    primary: {
      border: '1px solid transparent',
      borderRadius: 'default',

      _focus: {
        borderColor: 'primary.400'
      },
      _disabled: {
        opacity: 0.8
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})
const Card = defineCardMultiStyleConfig({
  baseStyle: {
    container: {
      p: '40px',
      bg: 'gray.950',
      color: '#fff',
      shadow: 'unset',
      borderRadius: '20px'
    }
  }
})
const Code = defineStyleConfig({
  baseStyle:{
    bg:'gray.800',
    color:'white',
    padding:'14px',
    borderRadius:'7px',
    cursor:'pointer',
    fontSize:"md"
  }
})
const Button = defineStyleConfig({
  sizes: {
    md: {
      py: '1rem',
      px: '1.5rem'
    }
  },
  baseStyle: {
    borderRadius: 'default',
    textTransform: 'uppercase',
    fontSize: '14px!important',
    fontWeight: 'medium',
    _disabled: { pointerEvents: 'none', opacity: 0.4 },
    transition: '.3s ease-in-out',
    color: '#fff',
    _active: {
      transform: 'scale(0.95)'
    },
    '&[data-widened]': {
      px: '2.5rem'
    }
  },
  variants: {
    primary: {
      bg: 'primary.500',
      _hover: {
        bg: 'primary.400'
      }
    },
    ghost: {
      bg: 'gray.800',
      color: 'white',
      _hover: {
        bg: 'gray.800'
      }
    },
    negative: {
      bg: '#C04444',
      _hover: {
        bg: 'red.500'
      }
    },
    negativeGhost: {
      bg: '#2F2B36',
      color: '#F97D7D',
      _hover: {
        bg: '#46343D'
      }
    },
    positive: {
      bg: 'green.600',
      _hover: {
        bg: 'green.500'
      }
    },
    blue: {
      fontWeight: 'medium',
      py: '11px',
px: '16px',
bg: '#1F2834',
color: '#8792A2',
height: '39px!important'
    },
    outline: {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'primary.500',
      color: 'white',
      py: 'calc(1rem - 2px)',
      _hover: {
        bg: 'transparent'
      },
      _active: {
        bg: 'transparent'
      }

    }
  },
  defaultProps: {
    size: 'md',
    variant: 'primary'
  }
})
const FormLabel = defineStyleConfig({
  baseStyle: {
    color: 'gray.400',
    fontSize: '0.85rem',
    marginBottom: '7px'
  }
})
const { defineMultiStyleConfig: defineModalStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)
const Modal = defineModalStyleConfig({
  baseStyle: {
    dialogContainer: {
    },
    dialog: {
      bg: 'gray.950',
      borderRadius: '20px',
      p: '48px',
      boxShadow: 'none'
    },
    overlay: {
      bg: 'rgba(21, 29, 40, 0.86)'
    },
    body: {
      p: 0,
      mt: '24px'
    },
    header: {
      p: 0,
      borderBottom: 'default',
      fontWeight: 'medium',
      fontSize: '22px',
      color: '#fff'
    },
    footer: {
      gap: 2,
      mt: '34px',
      p: 0
    },
    closeButton: {
      top: '28px', right: '24px'
    }
  }
})
const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
    px: 2,
    py: 1,
    bg: '#23303F'
  }
})
const { defineMultiStyleConfig: defineTableMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const Table = defineTableMultiStyleConfig({
  baseStyle: {
    th: {
      letterSpacing: 'auto',
      color: 'white',
      textTransform: 'unset',
      fontWeight: 500,
      _first: {
        borderBottomLeftRadius: '9px',
        borderTopLeftRadius: '9px'
      },
      _last: {
        borderBottomRightRadius: '9px',
        borderTopRightRadius: '9px'
      }
    },
    td: {
      color: '#96A0AF',
      fontSize: '15px',
      fontWeight: 500,
      _first: {
        borderBottomLeftRadius: '9px',
        borderTopLeftRadius: '9px'
      },
      _last: {
        borderBottomRightRadius: '9px',
        borderTopRightRadius: '9px'
      }
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 11px',
      overflow: 'hidden'
    }
  },
  variants: {
    primary: {
      tr: {
        _even: { bg: '#1E2835' },
        '&:only-child': {
          bg: '#1E2835'
        },
        transition: 'all .2s',
        _active: { bg: '#1E2835', transform: { md: 'scale(0.98)' } },

        '&:has(th)': {
          _active: {
            _even: { bg: '#1E2835' },
            transform: 'none'
          }
        }
      },
      th: {
        fontSize: '14px',
        py: '9px',
        bg: '#1E2835',
        _first: {
          pl: '30px'
        },
        _last: {
          pr: '30px'
        }
      },
      td: {
        py: '9px',
        _first: {
          pl: '30px'
        },
        _last: {
          pr: '30px'
        },
        borderBottom: 'none'
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})

const Link = defineStyleConfig({
  variants: {
    primary: {
      color: 'brand.600'
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})
const Text = defineStyleConfig({
  variants: {
    error: {
      color: 'red.400'
    },
    ghost: {
      color: 'gray.400',
      fontStyle: 'italic'
    }
  }
})
const Divider = defineStyleConfig({
  baseStyle: {
    borderColor: 'var(--border)',
    borderWidth: '1.5px',
    mt: 2,
    opacity: 1
  }
})
const { defineMultiStyleConfig: defineTabsMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)
const Tabs = defineTabsMultiStyleConfig({
  baseStyle: {
    tabpanel: {
      padding: 0
    },
    tablist: {
      borderBottom: 'default',
      borderColor: 'var(--border)!important',
      display: 'flex',
      overflowX: 'auto'
    }
  },
  variants: {
    primary: {
      tablist: {
        flexWrap: 'wrap',
        borderRadius: '20px',
        overflow: 'hidden',
        bg: '#1F2A39'
      },
      tab: {
        fontWeight: 'medium',
        textTransform: 'uppercase',
        p: '20px',
        bg: '#1F2A39',
        color: '#8F949C',
        _first: {
          borderLeftRadius: '20px'
        },
_last: {
          borderRightRadius: '20px'
        },
        _selected: {
          bg: '#243144',
          color: 'white'
        },
        whiteSpace: 'nowrap'
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
})
const theme = extendTheme({
  fonts: {
    heading: "'Rubik', sans-serif",
    body: "'Rubik', sans-serif"
  },
  breakpoints: {
    sm: '0px',
    md: '660px',
    lg: '840px',
    xl: '1030px',
    '2xl': '1336px',
    '3xl': '1740px'
  },
  styles: {
    global: {
      body: {
        bg: 'var(--root-bg)',
        height: 'full',
        fontOpticalSizing: 'auto',
        color: 'gray.400'
      },
      a: {
        textDecoration: 'none!important',
        color: 'primary.400'
      },
      html: {
        height: 'full'
      },
      '#root': {
        height: 'full'
      }
    }
  },
  colors,
  borders: {
    default: '1px solid var(--border)',
    brand: '1px solid #78a5ff',
    highlighted: '1px solid #ccd1d9'
  },
  components: {
    Button,
    Text,
    Tag,
    Tabs,
    Menu,
    Card,Code,
    Select,
    Link,
    FormLabel,
    Checkbox,
    Divider,
    Tooltip,
    Switch,
    Input: {
      sizes: {},
      variants: {
        primary: {
          field: {
            color: '#fff',
            borderRadius: 'default',
            py: '0.875rem',
            px: '1.25rem',
            bg: 'gray.600',
            border: '1px solid transparent',
            _focus: {
              borderColor: 'primary.400'
            },
            '--input-height': 'initial',
            '--input-font-size': '0.95rem',
            fontFamily: 'var(--font-rubik), sans-serif',
            _disabled: {
              opacity: 0.8
            },
            _placeholder: {
              color: '#8792A2'
            }
          },
          addon: {},
          element: {
            boxSize: '50px'
          }
        }
      },
      defaultProps: {
        variant: 'primary'
      }
    },
    Table,
    Modal,
    Heading,
    Textarea
    // Card: {
    //   variants: {
    //     basic: {
    //       border: 'default',
    //       padding: '20px 16px',
    //       background: 'red.200',
    //       _hover: { borderColor: 'gray.300' },
    //       _focus: { borderColor: 'gray.400' },
    //       borderRadius: '12px'
    //     }
    //   },
    //   sizes: {},
    //   defaultProps: {
    //     variant: 'basic'
    //   }
    // }
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
    default: '12px'
  }
})
export default theme
