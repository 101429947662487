import { FormattedPlural } from 'react-intl'
import { Box, type BoxProps, Card, CardProps, Heading, List, ListItem, Tag, Td, Text, Tr, Wrap, VStack, Flex } from '@chakra-ui/react'
import Content from 'components/misc/Content'
import Table, { StyledSortIcon, TableColumnTypes } from 'components/misc/Table'
import useAxios from 'hooks/useAxios'
import { useAppSelector } from 'hooks/redux'
import { selectCommonData } from 'store/common'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import { getArrayRearrangedByKeys } from '../../components/misc/Table'
import ServerContent from 'components/misc/ServerContent'
import { useState } from 'react'
function MiniCard ({ label, children, ...props }: { label: string } & BoxProps) {
  return (
    <Box bg="gray.950" py="23px" px="60px" borderRadius="15px" as={VStack} spacing="4px">
        <Text color="#828895" fontWeight="medium" fontSize="12px" textTransform="uppercase">{label}</Text>
        <Text fontSize="25px" fontWeight="medium" color="#EBEFFD">{children}</Text>
    </Box>
  )
}
export default function CommonData () {
  const request = useAxios<{
    accounts: Record<number, number>
    robuxes: Record<number, number>
  }>('/accounts/each-price')
  const commonData = useAppSelector(selectCommonData)
  const [isAscending, setAscending] = useState(false)
  const [isSorting, setIsSorting] = useState(false)
  return (

        <>
        {/* <Heading mt={7}>Additional Data</Heading> */}
    <ServerContent {...request}>
      {(data) => {
        const preparedData = Object.entries(data.accounts).map(([price, number]) => ({ price: +price, number, amount: data.robuxes[Number(price)] })).toSorted((x, y) => x.price - y.price)

  // if (isSorting) {
  //   preparedData = preparedData.toSorted((x, y) => (x.number - y.number) * (isAscending ? 1 : -1))
  // }

            const totalAccountsLength = Object.values(data.accounts).reduce((prev, cur) => prev + cur, 0)
            return (
        <>
        <Heading mt="40px">Additional Data</Heading>
        <Flex mt="20px" wrap="wrap" gap="20px" sx={{ '@media(max-width:700px)': { flexDir: 'column', alignItems: 'stretch', flexWrap: 'nowrap' } }}><ServerContent {...commonData}>{(data) => <MiniCard label="has been processed"><>{data.processedRobuxesAmount} <Text as="span" color="primary.600">R$</Text></></MiniCard>}</ServerContent>
         <MiniCard label="accounts in use">{totalAccountsLength}</MiniCard>
          </Flex>
          <Heading mt="40px">
            Queue <Text as="span" fontSize="1rem" fontWeight="normal" color="gray.400">(updates every 3 min)</Text>
          </Heading>
          {Object.keys(data.accounts).length > 0
            ? (
              <Card mt="20px"><Table
                columns={[{ type: TableColumnTypes.Tag, isSortable: true, heading: <>Rate <Text as="span" color="primary.600">$</Text></> }, {
 heading: 'Accounts number', isSortable: true
}, { type: TableColumnTypes.Tag, heading: <>Total <Text as="span" color="primary.600">R$</Text> amount</>, isSortable: true }]}
data={getArrayRearrangedByKeys(preparedData, ['price', 'number', 'amount'])}
              />

              </Card>
              )
            : (
              <NotFoundCard mt="20px" sub="There are no active accounts."/>
              )}
        </>
            )
          }}
    </ServerContent></>
  )
}
