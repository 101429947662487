import { Button, Heading, Text, Tr, Td } from '@chakra-ui/react'
import AwaitingButton from 'components/misc/AwaitingButton'
import Confirmation from 'components/misc/Confirmation'
import FormattedDateTime from 'components/misc/FormattedTime'
import { NotFoundCard } from 'components/misc/NotFoundCard'
import Table, { getArrayRearrangedByKeys, TableColumnTypes } from 'components/misc/Table'
import instance from 'instances/axios'
import { createContext, useContext, useMemo, useState } from 'react'
import { type IPendingVerificationRequest } from 'types/admin'

interface VerificationRequestContext {
  onGenerateRequest: (username: string) => Promise<void>
  onBanRequest: (username: string) => Promise<void>
  generatingUsername: IPendingVerificationRequest['username'] | null
  banningUsername: IPendingVerificationRequest['username'] | null
}
const Context = createContext<VerificationRequestContext>({
  onGenerateRequest: async () => {},
  generatingUsername: null,
  onBanRequest: async () => {},
  banningUsername: null
})
function Card ({
  username,
  discordUsername,
  userCreatedAt
}: IPendingVerificationRequest) {
  const {
    onGenerateRequest,
    generatingUsername,
    onBanRequest,
    banningUsername
  } = useContext(Context)
  return (
    <Tr>
      <Td data-label="Username">{username}</Td>
      <Td data-label="Discord">@{discordUsername}</Td>
      <Td data-label="Registration's date and time">
        <FormattedDateTime value={userCreatedAt} />
      </Td>

      <Td data-label="Generate the token">
        <Button
          isDisabled={banningUsername === username}
          isLoading={generatingUsername === username}
          onClick={() => {
            onGenerateRequest(username)
          }}
        >
          Generate
        </Button>
      </Td>
      <Td data-label="Ban the user">
        <Confirmation actionText="ban the user">
          <Button
            variant="negative"
            isDisabled={generatingUsername === username}
            onClick={() => {
              onBanRequest(username)
            }}
          >
            Ban
          </Button>
        </Confirmation>
      </Td>
    </Tr>
  )
}
interface Props {
  requests: IPendingVerificationRequest[]
  moveUserToGenerated: (
    username: IPendingVerificationRequest['username'],
    token: string
  ) => void
  deleteUserFromState: (
    username: IPendingVerificationRequest['username']
  ) => void
}
export default function PendingVerificationRequests ({
  requests,
  deleteUserFromState,
  moveUserToGenerated
}: Props) {
  const [generatingUsername, setGeneratingUsername] = useState<string | null>(
    null
  )
  const [banningUsername, setBanningUsername] = useState<string | null>(null)
  const onGenerateRequest = async (username: string) => {
    setGeneratingUsername(username)
    try {
      const response = await instance.post(
        '/admin/generate-verification-token',
        { username }
      )
      const { token } = response.data
      moveUserToGenerated(username, token)
    } catch {}
    setGeneratingUsername(null)
  }
  const onBanRequest = async (username: string) => {
    setBanningUsername(username)
    try {
      await instance.post('/admin/ban-user-by-username', { username })
      deleteUserFromState(username)
    } catch {}
    setBanningUsername(null)
  }
   const tableData = useMemo(() => getArrayRearrangedByKeys(requests, ['username', 'discordUsername', 'userCreatedAt', {
    addColumnProperties (item) {
                return { element: <AwaitingButton onClick={async () => { await onGenerateRequest(item.username) }} variant="outline">Generate</AwaitingButton> }
              }
    }, {
    addColumnProperties (item) {
      return { element: <AwaitingButton onClick={async () => { await onBanRequest(item.username) }} variant="negativeGhost">Ban</AwaitingButton> }
    }
    }]), [requests])
  return (
    <>
      <Heading fontSize="30px">
        Recently registered users
      </Heading>

      {requests.length > 0
        ? (
        <Table mt="20px"
          columns={[
            'Username',
            'Discord',
            'Registration\'s date and time',
            { heading: 'Generate the token', type: TableColumnTypes.Custom },
            { heading: 'Ban the user', type: TableColumnTypes.Custom }
          ]}
          data={tableData}
        />
          )
        : (
        <NotFoundCard heading="No data found" sub='There are no newly registered users.'/>
          )}
    </>
  )
}
