import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseDisclosureReturn
} from '@chakra-ui/react'
import AwaitingButton from 'components/misc/AwaitingButton'

interface Props {
  isOpen: UseDisclosureReturn['isOpen']
  onClose: UseDisclosureReturn['onClose']
  deletePromocode: () => Promise<void>
  resetSelectedPromocodeValue: () => void
}
export default function PromocodeDeletionModal ({
  isOpen,
  onClose,
  deletePromocode,
  resetSelectedPromocodeValue
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Confirm a promocode deletion</ModalHeader>
        <ModalBody>
          <Text>Are you sure?</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={() => { onClose(); resetSelectedPromocodeValue() }}>Cancel</Button>
          <AwaitingButton onClick={async () => { await deletePromocode(); onClose() }} variant="negative">
            Delete
          </AwaitingButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
