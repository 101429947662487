import { EyeIcon, EyeSlashIcon } from 'assets/icons'
import { useState } from 'react'
import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Icon
} from '@chakra-ui/react'
interface Props {
  [key: string]: any
  icon?: JSX.Element
  name?: string
}
export default function PasswordInput ({ icon, ...props }: Props) {
  const [isShown, setShown] = useState(false)
  return (
    <InputGroup>
      {icon && <InputLeftElement>{icon}</InputLeftElement>}
      <Input
        {...props}
        placeholder={isShown ? '******' : '******'}
        type={isShown ? 'text' : 'password'}
      />
      <InputRightElement
        cursor="pointer"
        onClick={() => {
          setShown(!isShown)
        }}
      >
        <Icon as={isShown ? EyeSlashIcon : EyeIcon} color="#8792A2" boxSize="20px"/>
      </InputRightElement>
    </InputGroup>
  )
}
