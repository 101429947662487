import toast from 'react-hot-toast'
import { Box, Heading, HStack, Icon, Tag, Text, Tooltip } from '@chakra-ui/react'
import Spinner from 'components/misc/Spinner'
import Table, { TableColumnTypes } from 'components/misc/Table'
import instance from 'instances/axios'
import { useEffect, useMemo, useState } from 'react'
import { type IFailedOrPendingOrder, type OrderFailureReasons } from 'types/admin'
import { OrderStatuses } from 'types/economy'
import FailedOrder from './FailedOrder'
import { failureReasonsTexts, robuxesDeliveryMethodsVisualData } from '../../constants'
import { getArrayRearrangedByKeys } from '../../components/misc/Table'
import { BellIcon, CheckmarkIcon, TrashIcon } from 'assets/icons'
import { getDateTimeString } from 'instances/intl'
import { AwaitingTag } from 'components/misc/AwaitingTag'
import { NotFoundCard } from 'components/misc/NotFoundCard'

export default function FailedOrders () {
  const [data, setData] = useState<IFailedOrPendingOrder[]>()
  useEffect(() => {
    instance.get('/admin/failed-and-pending-orders').then((res) => {
      setData(res.data)
    })
  }, [])
  const completeOrder = async (_id: IFailedOrPendingOrder['_id']) => {
    if (!data) return
    const orderIndex = data.findIndex((order) => order._id === _id)
    try {
      const response = await instance.post<{ status: OrderStatuses, failureReason: OrderFailureReasons }>('/admin/try-to-pay', { id: _id })
      const { status, failureReason } = response.data
      if (status === OrderStatuses.Failed) {
        const clonedData = [...data]
        clonedData[orderIndex].status = status
        if (clonedData[orderIndex].failureReason !== failureReason) {
          clonedData[orderIndex].failureReason = failureReason
        }
        setData(clonedData)
        toast.error(failureReasonsTexts[failureReason])
        return
      }

      if (orderIndex === -1) {
        toast.success(`The order #${_id} has been completed.`)
        return
      }
      toast.success(`The order #${data[orderIndex].id} has been completed.`)

      const clonedData = [...data]
      clonedData.splice(orderIndex, 1)
      setData(clonedData)
    } catch {

    }
  }
  const deleteOrder = async (_id: IFailedOrPendingOrder['_id']) => {
    if (!data) return
    try {
      await instance.delete('/admin/order', { params: { id: _id } })
      const orderIndex = data.findIndex((order) => order._id === _id)
      if (orderIndex === -1) {
        toast.success(`The order #${_id} has been deleted.`)
        return
      }
      toast.success(`The order #${data[orderIndex].id} has been deleted.`)

      const clonedData = [...data]
      clonedData.splice(orderIndex, 1)
      setData(clonedData)
    } catch {}
  }
  const tableData = useMemo(() => data
? getArrayRearrangedByKeys(data.map(order => ({ ...order, createdAt: getDateTimeString(order.createdAt), method: robuxesDeliveryMethodsVisualData[order.method].name, failureReason: typeof order.failureReason === 'string' ? order.failureReason : failureReasonsTexts[order.failureReason] })), [{
 key: 'id',
addColumnProperties (item) {
  if (!item.isAskedToRetry) return {}
  return { leftElement: <Tooltip label="The customer asked to retry the order."><Icon boxSize="20px" as={BellIcon} color="yellow.400"/></Tooltip> }
}
}, 'username', 'robuxesToSend', 'paidAmountInDollars', 'method', 'failureReason', 'createdAt', {
    key: '_id',
   addColumnProperties (item) {
               return {
    element: <HStack spacing="10px">
     <AwaitingTag onClick={async () => { await completeOrder(item._id) }} data-clickable variant="positive" p="9px"><Icon boxSize="19px" as={CheckmarkIcon}/></AwaitingTag>
                 <AwaitingTag variant="negative" onClick={async () => { await deleteOrder(item._id) }} data-clickable p="9px"><Icon boxSize="19px" as={TrashIcon}/></AwaitingTag>
               </HStack>
   }
             }
   }])
: [], [data])
  if (!data) return <Spinner />
  return (
    <>
      <Heading fontSize="30px">Failed Orders</Heading>
       <Text mt="15px" color="var(--sub-text-color)" fontSize="13px" fontWeight="medium">R$ <Text as="span" color="white">{data.map(order => order.robuxesToSend).reduce((acc, curr) => acc + curr, 0)}</Text> in total.</Text>

      {data.length > 0
        ? (
          <>

        <Table mt="30px"
          columns={[
            { heading: 'ID', type: TableColumnTypes.WithLeftElement },
            'Username',
            { heading: 'Amount (R$)', type: TableColumnTypes.Tag },
            { heading: 'Amount (US$)', type: TableColumnTypes.Tag },
            'Method',
            'Failure reason',
            'Date & time',
            { heading: 'Actions', type: TableColumnTypes.Custom }
          ]}
          data={tableData}
        />
        </>
          )
        : (
        <NotFoundCard sub='All failed orders have been resolved.'/>
          )}
    </>
  )
}
