import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { tagAnatomy } from '@chakra-ui/anatomy'
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys
)
export const Tag = defineMultiStyleConfig({
  sizes: {
    sm: {
      container: { p: '5px' }
    }
  },
  baseStyle: {
    container: {
      py: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      px: '10px',
      w: 'fit-content',
      transition: 'all .3s ease-in-out',
      borderRadius: '6px',
      '&[data-clickable]': {
        cursor: 'pointer'
      },
      '&[data-disabled="true"]': {
        pointerEvents: 'none',
        opacity: 0.6
      }
    },
    label: {
      fontWeight: 'medium'
    }
  },
  variants: {
    neutral: {
      container: {
        bg: '#23303F',
        color: '#CEDBEF'
      }
    },
    negative: {
      container: {
        bg: '#2F2D3A',
        color: 'red.600'
      }
    },
    positive: {
      container: {
        bg: '#223943',
        color: '#13E78E'
      }
    },
    pending: {
      container: {
        bg: '#2D3842',
        color: 'yellow.400'
      }
    }
  },
  defaultProps: {
    variant: 'neutral'
  }
})
