import { Heading, HStack, Icon, Tag, Text } from '@chakra-ui/react'
import Content from 'components/misc/Content'
import Table, { TableColumnTypes } from 'components/misc/Table'
import useAxios from 'hooks/useAxios'
import instance from 'instances/axios'
import Withdrawal from './Withdrawal'
import { type IWithdrawal } from 'types/economy'
import { toast } from 'react-hot-toast'
import { type AdminViewWithdrawal } from 'types/admin'
import WithdrawalsHistory from './WithdrawalsHistory'
import { getArrayRearrangedByKeys } from '../../components/misc/Table'
import ServerContent from 'components/misc/ServerContent'
import { CheckmarkIcon, CloseIcon, CopyIcon } from 'assets/icons'
import { getDateTimeString } from 'instances/intl'
import AwaitingButton from 'components/misc/AwaitingButton'
import { AwaitingTag } from 'components/misc/AwaitingTag'

export default function Withdrawals () {
  const { setData, ...withdrawals } = useAxios<AdminViewWithdrawal[]>(
    '/admin/withdrawals'
  )
  const deleteWithdrawalFromState = (_id: IWithdrawal['_id']) => {
    if (!withdrawals.data) return
    const index = withdrawals.data.findIndex((w) => w._id === _id)

    if (index > -1) {
      const clonedData = [...withdrawals.data]
      clonedData.splice(index, 1)
      setData(clonedData)
    }
  }
  const approveWithdrawal = async (_id: IWithdrawal['_id']) => {
    try {
      await instance.post('/admin/approve-withdrawal', { id: _id })
      toast.success('The withdrawal has been approved.')
      deleteWithdrawalFromState(_id)
    } catch {}
  }
  const rejectWithdrawal = async (_id: IWithdrawal['_id']) => {
    try {
      await instance.post('/admin/reject-withdrawal', { id: _id })
      toast.success('The withdrawal has been declined.')
      deleteWithdrawalFromState(_id)
    } catch {}
  }
  const copyAddress = (address: string) => {
    window.navigator.clipboard.writeText(address)
    toast.success('Скопировано.')
  }
  return (
    <>
      <Heading fontSize="30px">Withdrawal requests</Heading>
      <ServerContent {...withdrawals}>
        {(data) => (data.length) > 0
          ? (
          <Table mt="20px"
            columns={[
              'ID',
              'Username',
              { heading: 'Amount (US$)', type: TableColumnTypes.Tag },
              'Method',
              { heading: 'Actions', type: TableColumnTypes.Custom },
              'Requested at'
            ]}
            data={getArrayRearrangedByKeys(data.map(withdrawal => ({ ...withdrawal, createdAt: getDateTimeString(withdrawal.createdAt) })), ['id', 'username', 'amount', 'method', {
 key: '_id',
addColumnProperties (item) {
              return {
 element: <HStack spacing="7px">
                <Tag onClick={() => { copyAddress(item.address) }} data-clickable size="sm"><Icon boxSize="14px" as={CopyIcon}/></Tag>
                <AwaitingTag onClick={async () => { await approveWithdrawal(item._id) }} data-clickable variant="positive" p="6px"><Icon boxSize="12px" as={CheckmarkIcon}/></AwaitingTag>
                <AwaitingTag onClick={async () => { await rejectWithdrawal(item._id) }} data-clickable variant="negative" p="5px"><Icon boxSize="10px" as={CloseIcon}/></AwaitingTag>
              </HStack>
}
            }
}, 'createdAt'])}
          />

            )
          : (
          <Text>No withdrawal requests yet.</Text>
            )}
      </ServerContent>
      <Heading mt="30px" fontSize="30px">History</Heading>
      <WithdrawalsHistory/>
    </>
  )
}
