import { Field, type FieldProps } from 'formik'
import { type ComponentProps } from 'react'
import { Select } from './Select'

type SelectProps = ComponentProps<typeof Select>
export default function FormikSelect ({
  name,
  children,
  ...props
}: Omit<SelectProps, 'onChange'> &
  Partial<Pick<SelectProps, 'onChange'>> & {
    onCustomChange?: (value: string) => void
  }) {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps<string>) => (
        <Select
          value={field.value}
          onChange={(newValue) => {
            if (!name) return
            form.setFieldValue(name, newValue)
            if (props.onCustomChange) props.onCustomChange(newValue)
          }}
          {...props}
        >
          {children}
        </Select>
      )}
    </Field>
  )
}
