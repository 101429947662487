import { Card, Center, Heading, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { deleteAccount, deleteAccounts, selectAccounts, toggleAccounts } from 'store/accounts'
import AccountsTable from 'components/misc/AccountsTable'
import { type IRobloxAccount, type RobloxAccountWithApiData } from 'types/roblox'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import instance from 'instances/axios'
import EditingMode from './EditingMode'
import Confirmation from 'components/misc/Confirmation'
import AddAccounts from './AddAccounts'
import { selectUser } from 'store/user'
import { UserRoles } from 'types/users'
import ClickableText from './ClickableText'
import IsDisabledContext from './IsDisabledContext'
import { LoadingStatuses } from 'types/store'
import Spinner from 'components/misc/Spinner'

import { selectCommonData } from 'store/common'
import { NotFoundCard } from 'components/misc/NotFoundCard'

enum SelectionTypes {
  LowFunds,
  Disabled,
  All
}

export default function MyAccounts () {
  const user = useAppSelector(selectUser)
  const accounts = useAppSelector(selectAccounts)
  const common = useAppSelector(selectCommonData)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const minimumRobuxAmountForAccountActivity = common.data?.minimumRobuxAmountForAccountActivity || 200
  // const createSelectionOnClick = (type: SelectionTypes) => () => {
  //   const { data: accountsData } = accounts
  //   if (!accountsData) return

  //   let accountsToSelect
  //   switch (type) {
  //     case SelectionTypes.LowFunds:
  //       accountsToSelect = accountsData.filter(account => account.balance ? account.balance < minimumRobuxAmountForAccountActivity : false)
  //       break
  //     case SelectionTypes.Disabled:
  //       accountsToSelect = accountsData.filter(account => !account.isActive && (account.balance ? account.balance >= minimumRobuxAmountForAccountActivity : true))
  //       break
  //     case SelectionTypes.All:
  //       accountsToSelect = accountsData
  //       break
  //   }
  //   setSelectedAccountsIds(accountsToSelect.map(account => account._id))
  // }

  return (
    <>
      <Heading>
        {user.data?.role === UserRoles.Admin ? 'Accounts' : 'My Accounts'}
      </Heading>
      <AddAccounts/>

            <Card mt="30px">{accounts.data.length > 0
              ? (
              <>

                {isEditing
                  ? (
                  <EditingMode

                    accounts={accounts.data}
                    setIsEditing={ setIsEditing}
                  />
                    )
                  : (
                    <>
                    <Tag data-clickable onClick={() => { setIsEditing(true) }}>Edit</Tag>
                  <AccountsTable

mt="19px"
                  role={user.data?.role}
                    data={accounts.data }
                  />
                  </>
                    )}
              </>
                )
              : accounts.status === LoadingStatuses.Loading
                ? <Center><Spinner/></Center>
                : (
             <NotFoundCard sub="You don't have any accounts" mt="20px"/>
                  )} </Card>

    </>
  )
}
