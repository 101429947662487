import { useLocation } from 'react-router-dom'
import { VStack, HStack, Box, Text, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import {
  LogOutIcon,
  MenuIcon,
  AddIcon,
  DiscordIcon
} from 'assets/icons'
import HeaderLink from './HeaderLink'
import { useCallback } from 'react'
import Logo from '../Logo'
import logOut from 'helpers/logOut'
import { useAppSelector } from 'hooks/redux'
import { selectUser } from 'store/user'
import { UserRoles, UserVerificationStatuses } from 'types/users'
import { useMediaQuery } from 'usehooks-ts'
import Link from 'components/misc/Link'
import { AccountsOutlineIcon, AccountsSolidIcon, BalanceOutlineIcon, BalanceSolidIcon, HomeOutlineIcon, HomeSolidIcon, ProxiesOutlineIcon, ProxiesSolidIcon, ShieldOutlineIcon, ShieldSolidIcon } from 'assets/icons/navbar'
import { selectCommonData } from 'store/common'
import { HeaderStatus } from './HeaderStatus'
import { LoadingStatuses } from 'types/store'
export type ImportedIconType = typeof HomeOutlineIcon

const links: Array<{
  icon: [ImportedIconType, ImportedIconType]
  text: string | ((role: UserRoles) => string)
  href: string
}> = [
  {
    icon: [HomeOutlineIcon, HomeSolidIcon],
    text: 'Home',
    href: '/'
  },
  {
    icon: [AccountsOutlineIcon, AccountsSolidIcon],
    text: (role) => role === UserRoles.Admin ? 'Accounts' : 'My Accounts',
    href: '/accounts'
  }, {
    icon: [ProxiesOutlineIcon, ProxiesSolidIcon],
    text: (role: UserRoles) => role === UserRoles.Admin ? 'Proxies' : 'My Proxies',
    href: '/proxies'
  },

  { icon: [BalanceOutlineIcon, BalanceSolidIcon], text: 'Funding', href: '/funding' }

]

function HeaderSource ({ onClose }: { onClose?: () => void }) {
  const { pathname } = useLocation()
  const { data } = useAppSelector(selectUser)
  const isCurrentLink = useCallback(
    (link: string) => {
      if (pathname === '/') return link === '/'
      if (link === '/') return false
      return pathname.includes(link)
    },
    [pathname]
  )
  const common = useAppSelector(selectCommonData)
  const linksRegardingUserRole = [...links, ...(data?.role === UserRoles.Admin ? [{ icon: [ShieldOutlineIcon, ShieldSolidIcon] as [ImportedIconType, ImportedIconType], text: 'Admin Panel', href: '/admin' }] : [])]
  return (<VStack gap={0} zIndex={1} bg="#18212E" pos={{ sm: 'fixed', md: 'initial' }} h={{ sm: 'calc(100% - 66px)', md: 'initial' }} w={{ sm: '100%', md: 'fit-content', '2xl': '300px' }} as="header" borderRight="default" p={4}>
  <Box display={{ sm: 'none', md: 'initial' }}><Logo /></Box>
{common.data && <HeaderStatus areSalesEnabled={common.data.areSalesEnabled}/>}
  <VStack w="full" h="full" justify="space-between">
    <VStack mt={1} w="full">
      {linksRegardingUserRole.map(({ href, text, icon, ...link }) => (
        <HeaderLink
          key={href}
        onClose={onClose}
          href={href}
          isCurrentLink={isCurrentLink(href)}
          icon={icon}
          text={typeof text === 'function' ? text(data?.role || UserRoles.Merchant) : text}
          {...link}
        />
      ))}
      {data?.verificationStatus === UserVerificationStatuses.Verified && <HeaderLink text="Discord" href="https://discord.gg/gF58hY3NrV" icon={[DiscordIcon, DiscordIcon]} target="_blank"/>}
    </VStack>
    <HStack
      _active={{ transform: 'scale(0.95)' }}
      transition=".2s ease-in-out"
      justify="flex-start"
      w="full"
      borderRadius="default"
      gap="15px"
      onClick={logOut}
      cursor="pointer"
      p={3.5}
      color="gray.400"
      _hover={{ bg: 'blue.900', color: '#a7d3ff' }}
    >
      <Box boxSize="24px">
        <Icon as={LogOutIcon} boxSize="full" />
      </Box>
      <Text display={{ sm: 'block', md: 'none', '2xl': 'block' }} fontSize="1rem" fontWeight="medium">Log out</Text>
    </HStack>
  </VStack>
</VStack>)
}
function MobileHeader () {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
   <> <HStack px={5} py={3} justify="space-between" borderBottom="1px solid" borderColor="gray.800">
      <Link href="/" onClick={onClose}><Logo/></Link>
    <IconButton bg="transparent" _hover={{ bg: 'transparent' }} _active={{ transform: 'scale(0.95)' }} aria-label={`${isOpen ? 'Close' : 'Open'} menu`} onClick={onToggle} icon={<Icon boxSize={isOpen ? '32px' : '30px'} transform={isOpen ? 'rotate(45deg)' : undefined} as={isOpen ? AddIcon : MenuIcon}/>}/>
    </HStack>
    <Box display={{ sm: isOpen ? 'block' : 'none', md: 'block' }}><HeaderSource onClose={onClose}/></Box>
    </>
  )
}
export default function Header () {
  const isMobile = useMediaQuery('(max-width:659px)')

  if (isMobile) {
    return (
      <MobileHeader/>
    )
  }

  return <HeaderSource/>
}
