
import { LoadingStatuses } from 'types/store'
import { Box, Center } from '@chakra-ui/react'
import Spinner from './Spinner'

interface Props<T> {
  data: T | null
  children?: (data: T) => any
  status: LoadingStatuses
  error: string | null
  skeleton?: any
  centerSkeleton?: boolean
}
function isLoading<T> (status: LoadingStatuses, data: T | null): data is null {
  return [LoadingStatuses.Idle, LoadingStatuses.Loading].includes(status)
}
export default function ServerContent<T> ({ children, status, error, skeleton, data, centerSkeleton }: Props<T>) {
  const loadingStub = skeleton || <Spinner/>
  if (isLoading(status, data)) return <Box>{centerSkeleton ? <Center>{loadingStub}</Center> : loadingStub}</Box>
  if (status === LoadingStatuses.Error) return error || 'An unexpected error occured.'

  return children?.(data)
}
