
import { type ICommonData } from 'types/config'
import { AccountStatuses, type RobloxAccountWithApiData } from 'types/roblox'

export default function getAccountStatusRegardingAdditionalData ({ isImproperlyConfigured, isActive, balance, isBroken, isUsing2FA, isInvalidProxy, minimumRobuxAmountForAccountActivity }: Pick<RobloxAccountWithApiData, 'isActive' | 'isBroken' | 'isInvalidProxy' | 'isUsing2FA' | 'balance' | 'isImproperlyConfigured'> & Pick<ICommonData, 'minimumRobuxAmountForAccountActivity'>) {
  if (isBroken) {
    if (isInvalidProxy) return AccountStatuses.InvalidProxy
    return AccountStatuses.Broken
  }
  if (isUsing2FA) return AccountStatuses.Using2FA
  if (isImproperlyConfigured) return AccountStatuses.ImproperlyConfigured
  if ((balance as number) < minimumRobuxAmountForAccountActivity) return AccountStatuses.LowFunds
  if (!isActive) return AccountStatuses.Disabled
  return AccountStatuses.Active
}
