import { CloseIcon } from 'assets/icons'
import { Button, Heading, Icon, Text, Tooltip, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react'
import ServerContent from 'components/misc/ServerContent'
import useAxios from 'hooks/useAxios'
import { type ICustomPromocode } from 'types/admin'
import NewPromocodeModal from './NewPromocodeModal'
import { useCallback, useState } from 'react'
import PromocodeDeletionModal from './PromocodeDeletionModal'
import { toast } from 'react-hot-toast'
import instance from 'instances/axios'
import { getTimesPluralWord } from 'instances/intl'

export default function CustomPromocodes () {
  const { setData: setPromocodesData, ...promocodes } = useAxios<ICustomPromocode[]>('/admin/promocodes')
  const { onOpen: onNewPromocodeModalOpen, isOpen: isNewPromocodeModalOpen, onClose: onNewPromocodeModalClose } = useDisclosure()
  const { onOpen: onPromocodeDeletionModalOpen, isOpen: isPromocodeDeletionModalOpen, onClose: onPromocodeDeletionModalClose } = useDisclosure()
  const addPromocodeToData = useCallback((promocode: ICustomPromocode) => {
    if (!promocodes.data) return
    const clonedPromocodes = [...promocodes.data]
    clonedPromocodes.push(promocode)
    setPromocodesData(clonedPromocodes)
  }, [promocodes.data])
  const [selectedPromocodeValue, setSelectedPromocodeValue] = useState<string | null>(null)
  const deletePromocode = async () => {
    if (!promocodes.data) return
    if (!selectedPromocodeValue) {
      toast.error('A promocode was not specified.')
      return
    }
    await instance.delete('/admin/promocode', { params: { value: selectedPromocodeValue } })
    const clonedPromocodes = [...promocodes.data]
    const index = clonedPromocodes.findIndex(promocode => promocode.value === selectedPromocodeValue)
    if (index > -1) {
      clonedPromocodes.splice(index, 1)
      setPromocodesData(clonedPromocodes)
    }
    setSelectedPromocodeValue(null)
  }
  return (
    <>
      <Heading mt={8} fontSize="xl">
        Promocodes
      </Heading>
      <NewPromocodeModal onClose={onNewPromocodeModalClose} isOpen={isNewPromocodeModalOpen} addPromocode={addPromocodeToData}/>
      <PromocodeDeletionModal resetSelectedPromocodeValue={() => { setSelectedPromocodeValue(null) }} onClose={onPromocodeDeletionModalClose} isOpen={isPromocodeDeletionModalOpen} deletePromocode={deletePromocode}/>
      <ServerContent {...promocodes}>
        {(data) => (
          <>

          <Wrap
            align="stretch"
            borderRadius="default"
            height="calc(2.5rem + 7px * 2)"

            mt={2}
            w="fit-content"
            spacing={2}
            border="default"
            transition="border-color .2s ease-in-out"
            _focusWithin={{
              borderColor: 'primary.500'
            }}
          >
            {data.map(({ value, bonusAmount, timesUsed }) => (
              <WrapItem
                height="calc(2.5rem - 1.5px * 2)"
                display="flex"
                alignItems="center"
                key={value}
                mt="7px"
                fontSize="md"
                py="6px"
                px="8px"
                gap="3px"
            onClick={() => {
              setSelectedPromocodeValue(value)
              onPromocodeDeletionModalOpen()
            }}
                role="group"
                border="1px solid"
                borderColor="gray.800"
                cursor="pointer"
                borderRadius="10px"
              >
                <Tooltip label={`${bonusAmount} R$, used ${timesUsed} ${String(getTimesPluralWord(timesUsed))}`}><Text>
                  {value}
                </Text></Tooltip>
                <Icon as={CloseIcon} color="#fff" boxSize="13px" display="none" _groupHover={{ display: 'block' }}/>
              </WrapItem>
            ))}
            <Button
              mt="7px"
              borderRadius="10px"
              fontSize="md"

              py="6px"
              px="14px"
              _hover={{}}
              _focus={{}}
              _active={{ transform: 'scale(0.95)' }}
              bg="gray.800"
              onClick={onNewPromocodeModalOpen}
              height="calc(2.5rem - 1.5px * 2)"
            >
              Add
            </Button>
          </Wrap></>
        )}
      </ServerContent>
    </>
  )
}
